import React from 'react'
import {useState} from 'react'
import TechAppDialog from '../TechAppDialog'
import { FormGroup } from '@material-ui/core'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'
import FormControl from '@material-ui/core/FormControl'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'


type CompleteWODialogProps = {
    workorder_id: number;
    open: boolean;
    onClose(): void;
    onOK(asset_state: string): void;
}

export default function CompleteWODialog(props: CompleteWODialogProps){
    const [asset_state, setAssetState] = useState('')

    const valid = asset_state !== ''

    const onOK = () => {
        if (valid) {
            props.onOK(asset_state);
        }
    }

    return (
        <>
            <TechAppDialog open={props.open} onClose={props.onClose} title="Complete WO"
                buttons={[{text: 'Complete WO', onClick:onOK, primary: true},
                        {text: 'Close', onClick: props.onClose}
                        ]}
            >
                <form className="dialog-form">
                <FormGroup row className="boxed_group">
                    <FormControl>
                        <FormLabel component="legend">Asset State</FormLabel>
                        <RadioGroup row aria-label="Asset State" name="asset_state" value={asset_state} onChange={(event:any) => {setAssetState(event.target.value)}}>
                            <FormControlLabel
                                value="red"
                                control={<Radio color="primary" />}
                                label="Red"
                            />
                            <FormControlLabel
                                value="yellow"
                                control={<Radio color="primary" />}
                                label="Yellow"
                            />
                            <FormControlLabel
                                value="green"
                                control={<Radio color="primary" />}
                                label="Green"
                            />
                        </RadioGroup>
                        {(!valid)?(<FormHelperText>Select Asset State</FormHelperText>):(<></>)}
                    </FormControl>
                </FormGroup>
                </form>
            </TechAppDialog>
        </>
    );
}