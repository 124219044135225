import WOFilter from './types'
import OdooDatabase from './odoo'
import {formatStandardDate} from '../data/utils'
import {adjustDateTime, day_start, day_end} from '../data/utils'

function getDates(filter: any){
    const date_filter = filter.date_filter;
    let dates = {
        'date_start': filter.date_start,
        'date_end': filter.date_end
    }
    if(date_filter === 'today'){
        const today = new Date();
        dates ={
            date_start: formatStandardDate(today),
            date_end: formatStandardDate(today),
        };
    }else if(date_filter === 'tomorrow'){
        let date = new Date();
        date.setDate(date.getDate() + 1);
        dates = {
            date_start: formatStandardDate(date),
            date_end: formatStandardDate(date),
        };
    }else if(date_filter === 'this_week'){
        let date = new Date();
        let dayOfWeek = date.getDay();
        date.setDate(date.getDate() - dayOfWeek);
        let date2 = new Date();
        dayOfWeek = date2.getDay();
        date2.setDate(date2.getDate() + (6 - dayOfWeek));
        dates = {
            date_start: formatStandardDate(date),
            date_end: formatStandardDate(date2),
        };
    }else if(date_filter === 'next_week'){
        let date = new Date();
        let dayOfWeek = date.getDay();
        date.setDate(date.getDate() + 7 - dayOfWeek);
        let date2 = new Date();
        dayOfWeek = date2.getDay();
        date2.setDate(date2.getDate() + (13 - dayOfWeek));
        dates = {
            date_start: formatStandardDate(date),
            date_end: formatStandardDate(date2),
        };
    }else if(date_filter === 'this_month'){
        let date = new Date();
        date.setDate(1);
        let date2 = new Date();
        date2.setMonth(date2.getMonth() + 1);
        date2.setDate(1);
        date2.setDate(date.getDate() - 1);
        dates = {
            date_start: formatStandardDate(date),
            date_end: formatStandardDate(date2),
        };
    }else if(date_filter === 'none'){
        dates = {
            date_start: '',
            date_end: '',
        };
    }
    return dates;
}

export async function loadWOs(filter: WOFilter, start: number, count: number, avoid_calc_count?: boolean){
    let dates = getDates(filter)
    const args = {
        start_date: dates.date_start ? adjustDateTime(day_start(dates.date_start)) : '',
        end_date: dates.date_end ? adjustDateTime(day_end(dates.date_end)) : '',
        search_text: filter.search_name,
        search_object: filter.search_by,
        current_assignments: filter.myAssignments ? 1 : 0,
        wo_type: parseInt(filter.wo_type),
        wo_state: filter.wo_state,
        my_open_labor: filter.my_open_labor ? 1 : 0,
        my_open_travel: filter.my_open_travel ? 1 : 0,
        all_parts_ready: filter.all_parts_ready ? 1 : 0,
        wo_id: 0,
        only_wos: filter.only_wos,
    }
    let odoo = OdooDatabase.getInstance();
    let timeBefore = (new Date()).getTime();
    const wos_count = avoid_calc_count ? 0 : await odoo.execute_kw('fieldservice.workorder', 'get_wos_count_by_app_filter', [[args]])
    const wos = await odoo.execute_kw(
        'fieldservice.workorder',
        'get_wos_by_app_filter',
        [[args, start, count]]);

    let timeAfter = (new Date()).getTime();
    console.log('Time', timeAfter - timeBefore);
    return {
        wos: wos,
        wos_count: wos_count,
    }
}

export async function getCollocatedDistance() {
    let odoo = OdooDatabase.getInstance();
    const collocated_distance = await odoo.execute_kw('fieldservice.workorder', 'get_site_collocated_distance', [[]]);
    console.log('Read', collocated_distance);
    return {
        collocated_distance: collocated_distance
    }
}