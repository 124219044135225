import React from 'react';
import { Component } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FormGroup } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {updateDict} from '../data/utils';
import {initWOFilter} from '../data/types';
import TechAppDialog from './TechAppDialog';

type TechAppProperties = {
    open: false;
    onClose(): void;
    onOK(filter: any): void;
    woTypes: [];
    woTypesLoaded: false;
    WOFilter?: any;
}

export default class SearchWODialog extends Component<TechAppProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            WOFilter: props.WOFilter
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.resetDefaultValues = this.resetDefaultValues.bind(this);
    }

    componentDidMount(){
        this.setState({
            WOFilter: this.props.WOFilter
        })
    }

    resetDefaultValues(){
        this.setState({
            WOFilter: initWOFilter()
        });
    }

    handleInputChange(event: any){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        updateDict(this.state.WOFilter, {
            [name]: value
          });
        this.setState({WOFilter: this.state.WOFilter});
    }

    applyFilter(){
        this.props.onOK(this.state.WOFilter);
    }

    render(){
        let wo_states = [
            ['new', 'Requested'],
			['parts_allocated', 'Parts'],
			['schedule', 'Scheduling'],
			['assigned', 'Assigned'],
			['need_to_return', 'Need To Return'],
			['on_hold', 'On Hold'],
			['cancel', 'Canceled'],
			['complete', 'Complete'],
			['finalized', 'Finalized'],
			['to_be_invoiced', 'To Be Invoiced'],
			['invoiced', 'Invoiced'],
        ]

        const wo_types_items = this.props.woTypesLoaded ?
                this.props.woTypes.map((wo_type: any) => {
                    return (<MenuItem key={wo_type['id']} value={wo_type['id']}>{wo_type['name']}</MenuItem>);
                })
            : (<></>);

        const wo_state_items =
            wo_states.map((state: any) => {
                    return (<MenuItem key={state[0]} value={state[0]}>{state[1]}</MenuItem>);
                });
        return (<TechAppDialog open={this.props.open}
            title="Search WOs"
            icon={'search'}
            onClose={this.props.onClose}
            buttons={[{text: 'Search', onClick: this.applyFilter, primary: true}, {text: 'Reset', onClick: this.resetDefaultValues}]}
        >
                <DialogContentText className="scroll-content">
                <form className="dialog-form">
                <FormGroup row className="boxed_group">
                    <FormControlLabel
                        control={<Checkbox checked={this.state.WOFilter.myAssignments} onChange={this.handleInputChange} name="myAssignments"
                        color="primary"
                        />}
                        label="My Assignments"
                        disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                    />
                <FormControl>
                    <RadioGroup row aria-label="Date filter" name="date_filter" value={this.state.WOFilter.date_filter} onChange={this.handleInputChange}
                    >
                        <FormControlLabel
                            value="none"
                            control={<Radio color="primary" />}
                            label="No date filter"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                        <FormControlLabel
                            value="today"
                            control={<Radio color="primary" />}
                            label="Today"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                        <FormControlLabel
                            value="tomorrow"
                            control={<Radio color="primary" />}
                            label="Tomorrow"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                        <FormControlLabel
                            value="this_week"
                            control={<Radio color="primary" />}
                            label="This Week"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                        <FormControlLabel
                            value="next_week"
                            control={<Radio color="primary" />}
                            label="Next Week"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                        <FormControlLabel
                            value="this_month"
                            control={<Radio color="primary" />}
                            label="This Month"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                        <FormControlLabel
                            value="custom"
                            control={<Radio color="primary" />}
                            label="Custom"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                    </RadioGroup>

                </FormControl>
                {
                this.state.WOFilter.date_filter === 'custom'?
                (<div>
                    <TextField className="app_control_space input_date"
                            name="date_start"
                            label="Start Date"
                            type="date"
                            value={this.state.WOFilter.date_start}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleInputChange}
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                    <TextField className="app_control_space  input_date"
                            name="date_end"
                            label="End Date"
                            type="date"
                            value={this.state.WOFilter.date_end}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleInputChange}
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                 </div>) : (<></>)
                }
                </FormGroup>
                <FormGroup row className="boxed_group">
                    <FormControl className="app_control_space" disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}>
                        <InputLabel id="wo-type-label">WO Type</InputLabel>
                        <Select
                        id="wo_type"
                        name="wo_type"
                        value={this.state.WOFilter.wo_type}
                        onChange={this.handleInputChange}
                        >
                            <MenuItem value={'0'}>All Types</MenuItem>
                            {wo_types_items}
                        </Select>
                    </FormControl>
                    <FormControl className="app_control_space" disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}>
                        <InputLabel id="wo-state-label">WO State</InputLabel>
                        <Select
                        id="wo_state"
                        name="wo_state"
                        value={this.state.WOFilter.wo_state}
                        onChange={this.handleInputChange}
                        >
                            <MenuItem value={'0'}>All States</MenuItem>
                            {wo_state_items}
                        </Select>
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={this.state.WOFilter.all_parts_ready} onChange={this.handleInputChange} name="all_parts_ready"
                        color="primary"
                        />}
                        label="All Parts Ready"
                        disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                    />
                </FormGroup>
                <FormGroup className="boxed_group">
                    <div>
                    <RadioGroup row aria-label="Search By" name="search_by" value={this.state.WOFilter.search_by} onChange={this.handleInputChange}

                    >
                        <FormControlLabel
                            value="asset"
                            control={<Radio color="primary" />}
                            label="By Asset"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                        <FormControlLabel
                            value="wo"
                            control={<Radio color="primary" />}
                            label="By WO"
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                    </RadioGroup>
                    </div>
                    <div>
                    <TextField
                            name="search_name"
                            label="Search"
                            fullWidth
                            value={this.state.WOFilter.search_name}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleInputChange}
                            disabled={this.state.WOFilter.my_open_labor || this.state.WOFilter.my_open_travel}
                        />
                    </div>
                </FormGroup>
                <FormGroup row className="boxed_group">
                    <FormControlLabel
                        control={<Checkbox checked={this.state.WOFilter.my_open_labor} onChange={this.handleInputChange} name="my_open_labor"
                        color="primary"
                        />}
                        label="My Open Labor"
                    />
                    <FormControlLabel
                        control={<Checkbox checked={this.state.WOFilter.my_open_travel} onChange={this.handleInputChange} name="my_open_travel"
                        color="primary"
                        />}
                        label="My Open Travel"
                    />
                </FormGroup>
                </form>
                </DialogContentText>
        </TechAppDialog>
        );
    }
}