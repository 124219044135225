import React from 'react'
import TableList from '../TableListViewComponent'
import {TableElement} from '../TableListViewComponent'
import Button from '@material-ui/core/Button'
import SelectItemsDialog from './SelectItemsDialog'
import {useState} from 'react'

type SelectItemsProperties = {
    model: string;
    fields: string[];
    elements: TableElement[];
    domain?: any[];
    className?: string;
    itemName?: string;
    pluralItemName?: string;
    selectedItems: number[];
    onChange?(items: number[]): void;
    hideTableHead?: boolean;
}

export default function SelectItems(props: SelectItemsProperties){
    const [data, setData] = useState<any[]>([])
    const [tableListKey, setTableListKey] = useState(0)
    const [addItemDialogOpen, setAddItemDialogOpen] = useState(false)

    const onDataLoaded = (data: any) => {
        setData(data)
    }

    const removeElements = () => {
        let newIds = []
        for(let item of data){
            if(!item._checked)
                newIds.push(item.id)
        }
        if(props.selectedItems.length != newIds.length){
            if(props.onChange)
                props.onChange(newIds)
            setTableListKey(1 - tableListKey)
        }
    }

    const onAdd = (items: number[]) => {
        if(items.length > 0){
            if(props.onChange)
                props.onChange(props.selectedItems.concat(items))
            setTableListKey(1 - tableListKey)
        }
    }

    let addText = 'Add '
    if(props.itemName)
        addText += props.itemName
    let removeText = 'Remove '
    if(props.pluralItemName)
        removeText += props.pluralItemName
    return (<>
        <SelectItemsDialog key={`dlg_${tableListKey}`}
            open={addItemDialogOpen}
            model={props.model}
            itemName={props.itemName ? props.itemName : ''}
            onOK={onAdd}
            onClose={() => {setAddItemDialogOpen(false)}}
            fields={props.fields}
            elements={props.elements}
            selectedIds={props.selectedItems}
            domain={props.domain}
        />
        <TableList key={`table_${tableListKey}`}
           model={props.model}
           domain={[['id', 'in', props.selectedItems]]}
           fields={props.fields}
           elements={props.elements}
           checkBoxes={true}
           onDataLoaded={onDataLoaded}
           className={props.className}
           hideHead={props.hideTableHead}
        />
        <div className="wo_app_buttons wo_app_centered_buttons">
            <Button
                variant="contained"
                color="primary"
                onClick={()=>{setAddItemDialogOpen(true)}}
            >{addText}</Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={removeElements}
            >{removeText}</Button>
        </div>
    </>)
}