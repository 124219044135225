import * as MdIcons from "react-icons/md";
import IconButton from '@material-ui/core/IconButton';

type PaginationProperties = {
    pageIndex: number;
    itemsCount: number;
    pageSize?: number;
    onPageChanged(pageIndex: number): void;
}

export default function Pagination(props: PaginationProperties){

    const pageSize = props.pageSize ? props.pageSize : 25;
    let pageCount = Math.floor(props.itemsCount / pageSize);
    if(props.itemsCount % pageSize > 0)
        pageCount += 1;
    if(pageCount <= 1)
        return (<></>);
    return (<div>
        <IconButton
        disabled={props.pageIndex === 0}
        onClick={() => {props.onPageChanged(0)}}
        aria-label="first page"
      ><MdIcons.MdFirstPage/> </IconButton>
        <IconButton
        disabled={props.pageIndex === 0}
        onClick={() => {if(props.pageIndex > 0) props.onPageChanged(props.pageIndex - 1)}}
        aria-label="previous page"
      ><MdIcons.MdNavigateBefore/> </IconButton>
      <span>Page {props.pageIndex + 1} of {pageCount}</span>
      <IconButton
        disabled={props.pageIndex === pageCount - 1}
        onClick={() => {if(props.pageIndex < pageCount - 1) props.onPageChanged(props.pageIndex + 1)}}
        aria-label="next page"
      ><MdIcons.MdNavigateNext/> </IconButton>
      <IconButton
        disabled={props.pageIndex === pageCount - 1}
        onClick={() => {props.onPageChanged(pageCount - 1)}}
        aria-label="last page"
      ><MdIcons.MdLastPage/> </IconButton>

        </div>
        );
}