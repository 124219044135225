
import { useState, useEffect, useRef } from 'react'
import Loading from './LoadingComponent'
import Map from './MapComponent'
import OdooDatabase from '../data/odoo'
import { loadWOs, getCollocatedDistance } from '../data/wos'
import ReactDOM from 'react-dom'
import {formatSplitDateTime, formatDateTime, haversine} from '../data/utils'
import * as FcIcons from 'react-icons/fc'
import * as FaIcons from 'react-icons/fa';
import IconButton from '@material-ui/core/IconButton'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import { Link } from 'react-router-dom'
import './css/map.css'



type MarkersGroup = {
    latitude: number;
    longitude: number;
    items: any[];
    has_wo_close_to_start: boolean;
    googleMarker?: google.maps.Marker;
    infoWindows?: google.maps.InfoWindow;
    earliestDate: string;
}

type WOInfoWindowProperties = {
    data: any;
    onWOLinkClick(wo_id: number): void;
    onUpdateDate(item: any, newDate: string): Promise<boolean>;
}

function WOInfoWindow(props: WOInfoWindowProperties){
    const item = props.data
    const category_name = (item.assets_info.length > 0 && item.assets_info[item.assets_info.length - 1].acategory_id) ?
         item.assets_info[item.assets_info.length - 1].acategory_id[1] : '';
    const [editMode, setEditMode] = useState(false);
    const [date, setDate] = useState(item.assigned_date ? item.assigned_date.split(' ')[0] : '')
    const [formattedDateTime, setFormattedDateTime] = useState<string[]>(item.assigned_date ? formatSplitDateTime(new Date(item.assigned_date)): ['', ''])

    const onDateChange = (newDate: string) => {
        setDate(newDate)
        const dateTime = item.assigned_date ? item.assigned_date.split(' ')[1] : '00:00:00'
        const new_assigned_date = `${newDate} ${dateTime}`
        setFormattedDateTime(formatSplitDateTime(new Date(new_assigned_date)))
    }

    const updateDate= async () => {
        await props.onUpdateDate(item, date)
        setEditMode(false)
        setFormattedDateTime(item.assigned_date ? formatSplitDateTime(new Date(item.assigned_date)) : ['', ''])
    }

    return (<div className="map_info_window">
        <div><span onClick={(event: any) => {event.preventDefault(); props.onWOLinkClick(item.wo_id)}} className="map_wo_link">{item.wo_name}</span></div>
        <div className="map_info_box">
            <div className="map_info_line">
                <span className="map_info_label">WO Type: </span>
                <span className="map_info_data">{item.wo_type}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Asset: </span>
                <span className="map_info_data">{item.asset_name}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Coordinates: </span>
                <span className="map_info_data">{item.asset_alat}, {item.asset_along}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Customer: </span>
                <span className="map_info_data">{item.customer_name}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Assignment: </span>
                {
                item.is_wo?
                (<span className="map_info_data">{formattedDateTime[0]} </span>):
                editMode?
                (<input type="date" className="map_edit_date" value={date} onBlur={updateDate} onChange={(event: any) => {onDateChange(event.target.value)}}/>):
                (<span className="map_info_data map_info_data_edit" onClick={() => {setEditMode(true)}}> {formattedDateTime[0]}
                    <IconButton className="small_icon_button" onClick={() => {setEditMode(true)}}>
                        <FcIcons.FcCalendar/>
                    </IconButton>
                </span>)
                }
                <span className="map_info_data">{formattedDateTime[1]}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Category: </span>
                <span className="map_info_data">{category_name}</span>
            </div>
            {
            item.asset_last_wo?
            (<>
            <div className="map_info_line">
                <span className="map_info_label">Last WO: </span>
                <span className="map_info_data">{item.asset_last_wo[1]}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Last WO Date: </span>
                <span className="map_info_data">{item.asset_last_wo_date}</span>
            </div>
            </>
            ):(<></>)
            }
        </div>
        <NavigationButton lat={item.asset_alat} long={item.asset_along}/>
    </div>)
}

type GroupInfoItemProperties = {
    item: any;
    onWOLinkClick(item: any): void;
    onUpdateDate(item: any, newDate: string): Promise<boolean>;
}

function GroupInfoItem(props: GroupInfoItemProperties){
    const [editMode, setEditMode] = useState(false)
    const item = props.item
    const [date, setDate] = useState(item.assigned_date ? item.assigned_date.split(' ')[0] : '')
    const [formattedDateTime, setFormattedDateTime] = useState<string[]>(item.assigned_date ? formatSplitDateTime(new Date(item.assigned_date)): ['', ''])

    const onDateChange = (newDate: string) => {
        setDate(newDate)
        const dateTime = item.assigned_date ? item.assigned_date.split(' ')[1] : '00:00:00'
        const new_assigned_date = `${newDate} ${dateTime}`
        setFormattedDateTime(formatSplitDateTime(new Date(new_assigned_date)))
    }

    const updateDate= async () => {
        await props.onUpdateDate(item, date)
        setEditMode(false)
        setFormattedDateTime(item.assigned_date ? formatSplitDateTime(new Date(item.assigned_date)) : ['', ''])
    }

    return (<div className="map_info_window">
        <span className="map_wo_link" onClick={(event: any) => {event.preventDefault(); props.onWOLinkClick(item)}}>{item.wo_name} / {item.customer_name}</span>
        <div className="map_info_box">
            <div className="map_info_line">
                <span className="map_info_label">WO Type: </span>
                <span className="map_info_data">{item.wo_type}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Asset: </span>
                <span className="map_info_data">{item.asset_name}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Coordinates: </span>
                <span className="map_info_data">{item.asset_alat}, {item.asset_along}</span>
            </div>
            <div className="map_info_line">
                <span className="map_info_label">Assignment: </span>
                {
                item.is_wo?
                (<span className="map_info_data">{formattedDateTime[0]} </span>):
                editMode?
                (<input type="date" className="map_edit_date" value={date} onBlur={updateDate} onChange={(event: any) => {onDateChange(event.target.value)}}/>):
                (<span className="map_info_data map_info_data_edit" onClick={() => {setEditMode(true)}}> {formattedDateTime[0]}
                    <IconButton className="small_icon_button" onClick={() => {setEditMode(true)}}>
                        <FcIcons.FcCalendar/>
                    </IconButton>
                </span>)
                }
                <span className="map_info_data">{formattedDateTime[1]}</span>
            </div>
        </div>
    </div>)
}

type NavigationProps = {
    lat: number;
    long: number;
}

function navigateToCoords(lat: number, long: number) {
    window.open('http://maps.google.com/maps?z=7&t=m&saddr=My+Location' +
		'&daddr=' + encodeURIComponent(lat) + ',' +
				encodeURIComponent(long));
}

function NavigationButton(props: NavigationProps) {
    return (<div className="map_directions"><IconButton onClick={()=>{ navigateToCoords(props.lat, props.long) }}><FaIcons.FaDirections size={24}/></IconButton></div>)
}

type GroupInfoWindowProperties = {
    data: MarkersGroup;
    onWOLinkClick(item: any): void;
    onUpdateDate(item: any, newDate: string): Promise<boolean>;
}

function GroupInfoWindow(props: GroupInfoWindowProperties){
    const items = props.data.items.map((item: any) => {
        return (<GroupInfoItem item={item} onWOLinkClick={props.onWOLinkClick} onUpdateDate={props.onUpdateDate} key={`map_item_${item.id}`}/>)
    })
    let dirButton = (<></>)
    let asset_lat = 0;
    let asset_long = 0;
    if (props.data.items.length > 0) {
        asset_lat = props.data.items[0].asset_alat;
        asset_long = props.data.items[0].asset_along;
        dirButton = (
            <NavigationButton lat={asset_lat} long={asset_long}/>
        )
    }
    return (<>{items}{dirButton}</>)
}


type TechMapProperties = {
    WOFilter: any;
    onWOLinkClick(wo_id: number): void;
    onError(errorMsg: string): void;
}

export default function TechMap(props: TechMapProperties){
    const [loading, setLoading] = useState(false)
    const [map, setMap] = useState<any>()
    const [data, setData] = useState<any[]>([])
    const [centerX, setCenterX] = useState(0);
    const [centerY, setCenterY] = useState(0);
    const [dataLoaded, setDataLoaded] = useState(false)
    const [collocatedDistance, setCollocatedDistance] = useState(0);
    const mountedRef = useRef(true)

    const groupByLocation = (assignments: any[]): any[] => {
        let groups: MarkersGroup[] = []
        for(let assignment of assignments){
            let latitude = assignment.asset_alat
		    let longitude = assignment.asset_along
            let g : MarkersGroup | null = null
            for(let group of groups){
                let glat = group.latitude
			    let glong = group.longitude
                if(haversine(glat, glong, latitude, longitude) <= collocatedDistance) {
                    g = group
                    break
                }
            }
            if(!g){
                g = {
                    latitude : latitude,
                    longitude : longitude,
                    items: [],
                    has_wo_close_to_start : false,
                    earliestDate: ''
                }
                groups.push(g)
            }
            g.items.push(assignment)
            assignment.group = g
            if(assignment.close_to_start)
				g.has_wo_close_to_start = true
        }
        return groups
    }

    const orderGroups = (groups: MarkersGroup[]) => {
        groups.forEach((group: MarkersGroup) => {
            let earliestDate = '';
            group.items.forEach((item: any) => {
                if(earliestDate === '' || item.date < earliestDate)
				    earliestDate = item.date;
            });
            group.earliestDate = earliestDate;
        });
        groups.sort((x, y: MarkersGroup): number => {
            if (x.earliestDate < y.earliestDate)
                return -1;
            else if(x.earliestDate > y.earliestDate)
                return 1;
            else
                return 0;
        });
    }

    const loadData = async() => {
        try{
            setLoading(true)
            props.WOFilter.only_wos = true;
            const filter = {only_wos: true, ...props.WOFilter}
            const result = await loadWOs(filter, 0, 200, true)
            const collocatedDistance = await getCollocatedDistance();
            setCollocatedDistance(collocatedDistance.collocated_distance);
            if (!mountedRef.current) return
            const groups = groupByLocation(result.wos);
            orderGroups(groups);
            setData(groups)
            setDataLoaded(true)
            setLoading(false)
            const [newCenterX, newCenterY] = calculateCenter();
            setCenterX(newCenterX);
            setCenterY(newCenterY);
        }
        catch(err: any){
            console.log('Error', err)
            setLoading(false)
        }
    }

    const updateDate = async (item: any, newDate:string): Promise<boolean> => {
        if(item.is_wo)
            return true
        setLoading(true)
        try{
            setLoading(true)
            const dateTime = item.assigned_date ? item.assigned_date.split(' ')[1] : '00:00:00'
            const newDateTime = `${newDate} ${dateTime}`
            const odoo = OdooDatabase.getInstance()
            await odoo.update('fieldservice.wo.tech.assignment', [item.id], {'assigned_date': newDateTime})
            item.assigned_date = newDateTime
            setLoading(false)
        }
        catch(err: any){
            setLoading(false)
            console.log('Error', err);
            props.onError(err.faultString);
            return false
        }
        return true
    }

    useEffect(() => {
        loadData()
        return () => {
            mountedRef.current = false
        }
    }, [])

    const closeAllInfoWindows = () => {
        for(let d of data){
            if(d.infoWindow){
                d.infoWindow.close()
                d.infoWindow = null
            }
            for(let item of d.items){
                if(item.infoWindow){
                    item.infoWindow.close()
                    item.infoWindow = null
                }
            }
        }
    }

    const showWOInfo = (item: any) => {
        closeAllInfoWindows()
        let infoContent = (<WOInfoWindow data={item} onWOLinkClick={props.onWOLinkClick} onUpdateDate={updateDate}/>)
        let domElement = document.createElement('div')
        ReactDOM.render(infoContent, domElement)
        let infowindow = new google.maps.InfoWindow({content: domElement})
        item.infoWindow = infowindow
        infowindow.open(map, item.group.googleMarker)
        if(item.group.items.length > 1)
            google.maps.event.addListener(infowindow, 'closeclick', () => {
                showGroupInfo(item.group)
            })
    }

    const showGroupInfo = (datum: any) => {
        if(datum.items.length === 1){
            showWOInfo(datum.items[0])
            return
        }
        closeAllInfoWindows()
        let infoContent = (<GroupInfoWindow data={datum} onWOLinkClick={showWOInfo} onUpdateDate={updateDate}/>)
        let domElement = document.createElement('div')
        ReactDOM.render(infoContent, domElement)
        let infowindow = new google.maps.InfoWindow({content: domElement})
        datum.infoWindow = infowindow;
        infowindow.open(map, datum.googleMarker)
    }

    const calculateCenter = () => {
        let mapLat = 0.0
		let mapLong = 0.0
        if(data.length > 0){
            for(let datum of data){
                mapLat += datum.latitude
                mapLong += datum.longitude
            }
            mapLat /= data.length
            mapLong /= data.length
        }
        return [mapLat, mapLong]
    }

    const showMarkers = (map:any) => {
        if(map){
            let index = 0;
            for(let datum of data){
                let image = ''
                if (process.env.REACT_APP_BASE_URL)
                    image += process.env.REACT_APP_BASE_URL
                if(datum.has_wo_close_to_start)
                    image += 'marker_asset_due.png'
                else{
                    image += 'marker_asset'
                    if(datum.items.length > 1)
                        image += 'n.png'
                    else
                        image += '1.png'
                }
                let location = new google.maps.LatLng(datum.latitude, datum.longitude)
                let marker = new google.maps.Marker({
                    position: location,
                    map: map,
                    icon: {
                        url: image,
                        labelOrigin: new google.maps.Point(17, -10),
                    },
                    draggable:false, animation: google.maps.Animation.DROP,
                    label: { fontWeight: 'bold', fontSize: '14px', text: `${++index}` }
                });
                datum.googleMarker = marker
                google.maps.event.addListener(marker, 'click', function(){
                    showGroupInfo(datum);
                });
            }
        }
    }

    const showCurrentLocation = (map: any) => {
        var myLocationMarker: google.maps.Marker;

        const updateMyLocation = () => {
            navigator.geolocation.getCurrentPosition(function(position) {
                const myLocationPoint = new google.maps.LatLng(position.coords.latitude,
                    position.coords.longitude);
                if(myLocationMarker) {
                    myLocationMarker.setPosition(myLocationPoint);
                } else {
                    let image = process.env.REACT_APP_BASE_URL + 'mylocation.png';
                    myLocationMarker = new google.maps.Marker({
                        position: myLocationPoint,
                        map: map,
                        icon: image,
                        draggable:false, animation: google.maps.Animation.DROP,
                        title: ''
                    });
                }
            });
        }
        updateMyLocation();
        const interval = setInterval(updateMyLocation, 15000);
        return interval;
    }

    const mapInit = (map: any) => {
        setMap(map);
        showMarkers(map);
        const interval = showCurrentLocation(map);
        return () => { clearInterval(interval) };
    }

    return (<>
        <Loading loading={loading}/>
        {
            dataLoaded ?
            (
                <>
                    <Breadcrumbs aria-label="breadcrumb" className="wo_breadcrumb">
                        <Link to="/wos" color="inherit" href="/">
                            Work Orders
                        </Link>
                        <Typography color="textPrimary">Map</Typography>
                    </Breadcrumbs>
                    <Map onMapInit={mapInit} centerLatitude={centerX} centerLongitude={centerY} showCurrentLocation={true}/>
                </>
            ):
            (<span>Loading...</span>)
        }
        </>
    )
}