class OdooDatabase{
    
    private static connector : any = false;

    private constructor() {
    }

    public static logout(){
        OdooDatabase.connector = false;
    }

    public static getInstance(): any {
        return OdooDatabase.connector;
    }

    public static getToken(): string {
        if (OdooDatabase.connector) {
            return OdooDatabase.connector.password;
        }
        return '';
    }

    public static setToken(token: string): void {
        if (OdooDatabase.connector) {
            OdooDatabase.connector.password = token;
        }
    }

    public static create(username: string, password: string) {
        const Odoo = require('odoo-await');
        const port = ('' + process.env.REACT_APP_ODOO_PORT).trim();
        const settings = {
            baseUrl: '' + process.env.REACT_APP_ODOO_SERVER,
            port: undefined,
            db: process.env.REACT_APP_DB,
            username: username,
            password: password,
        }
        const settingsWithPort = {
            baseUrl: '' + process.env.REACT_APP_ODOO_SERVER,
            port: port,
            db: process.env.REACT_APP_DB,
            username: username,
            password: password,
        }
        
        if (port !== '80' && port !== '443')
            OdooDatabase.connector = new Odoo(settingsWithPort);
        else
            OdooDatabase.connector = new Odoo(settings);
    }

    public static async connect(username: string, password: string): Promise<any> {
        this.create(username, password);
        try{
            await OdooDatabase.connector.connect();
        }
        catch(err: any){
            OdooDatabase.connector = false;
            if(typeof err != 'string'){
                throw (err);
            }
        }
        return OdooDatabase.connector;
    }
}

export default OdooDatabase;