import { useState, useEffect, useRef } from "react";
import TableList from '../TableListViewComponent'
import Select from '@material-ui/core/Select'
import { MenuItem } from "@material-ui/core";
import Loading from '../LoadingComponent'
import OdooDatabase from '../../data/odoo'
import '../css/taskhistory.css'

type TasksHistoryProperties = {
    asset_id: number;
}

type FilterItem = {
    id: number;
    label: string;
}


export default function TasksHistory(props: TasksHistoryProperties){
    const mainDomain = [['procedure_id.workorder_id.asset_id', '=', props.asset_id],
                        ['wotask_id.trackable', '=', true], ['procedure_id.workorder_id.id','!=',false],
                        ['procedure_id.workorder_id.state','in',['complete','invoiced', 'finalized']]]
    const [filterItems, setFilterItems] = useState<FilterItem[]>([])
    const [loading, setLoading] = useState(false)
    const [domain, setDomain] = useState<any[]>([['id', '=', 0]])
    const [selectedTaskId, setSelectedTaskId] = useState(0)
    const [taskKey, setTaskKey] = useState(0)
    const mounted = useRef(true)
    

    const loadData = async() => {
        setLoading(true)
        try{
            let tasks:FilterItem[] = []
            let tasks_set = new Set()
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.searchRead('fieldservice.workorder.group.task', mainDomain, ['wotask_id'])
            if(!mounted.current) return
            for(let datum of data){
                if(datum['wotask_id'] && !tasks_set.has(datum['wotask_id'][0])){
                    tasks_set.add(datum['wotask_id'][0])
                    tasks.push({id: datum['wotask_id'][0], label: datum['wotask_id'][1]})
                }
            }
            setLoading(false)
            setFilterItems(tasks)
        }
        catch(err: any){
            console.log('Error', err)
            setLoading(false)
        }    
    }

    useEffect(() => {
        loadData()
        return () => {
            mounted.current = false
        }
    }, [])


    const renderReading = (field: string, datum: any) => {
        let text = datum[field]
        if(datum['reading_type'] == 'd' && text){
            const dateRegexp = /^(\d{4})-(\d{1,2})-(\d{1,2})(\s+(\d{1,2})\:(\d{1,2})\:(\d{1,2}))?$/
            if(text.match(dateRegexp)){
                let date = new Date(text)
                let offset = date.getTimezoneOffset() * 60 * 1000
                date.setTime(date.getTime() - offset)
                text = date.toLocaleString('en-US', { hour12: true })
            }
        }
        return (<span>{text}</span>)
    }

    const onTaskChanged = (event: any) => {
        const task_id = event.target.value
        setSelectedTaskId(event.target.value)
        setDomain(mainDomain.concat([['wotask_id', '=', parseInt(task_id)]]))
        setTaskKey(1 - taskKey)
    }

    if(loading){
        return (<Loading loading={true} fullWidth={false}/>)
    }

    const filterOptions = filterItems.map((item: FilterItem) => {
        return (<MenuItem value={item.id} key={`filter_item_${item.id}`}>{item.label}</MenuItem>)
    })

    return (<>
    <Select fullWidth value={selectedTaskId} onChange={onTaskChanged} className="task_filter">
        {filterOptions}
    </Select>
    <TableList key={`tasks_history_list${taskKey}`}
        model="fieldservice.workorder.group.task"
        fields={['wo_id', 'wo_completed_date', 'reading', 'reading_type']}
        elements={
            [
                {field: 'wo_id', header:'WO', type:'many2one'},
                {field: 'wo_completed_date', header:'Done', type:'date'},
                {field: 'reading', header:'Value', type:'string', onRender: renderReading}
            ]
        }
        domain={domain}
        pagination={true}
        pageSize={5}
        order="wo_completed_date desc"
    /></>)
}