import TechAppDialog from '../TechAppDialog'
import { formatDateToLocale } from '../../data/utils';
import { TextField } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { Dayjs } from 'dayjs';
import {useState} from 'react';
import Loading from '../LoadingComponent';
import OdooDatabase from '../../data/odoo';

type RequestTimeFixDialogProperties = {
    workorder_id: number;
    workorder_name?: string;
    start_date: string;
    end_date: string;
    time_id: number;
    onSuccess(): void;
    onClose(): void;
    onError(errorMsg: string, notModal?: boolean): void;
    title: string;
}

export default function RequestTimeFixDialog(props: RequestTimeFixDialogProperties){
    const [newStartDatetime, setNewStartDatetime] = useState<Dayjs | null>(null);
    const [newEndDatetime, setNewEndDatetime] = useState<Dayjs | null>(null);
    const [loading, setLoading] = useState(false);

    const onOK = async () => {
        // %Y-%m-%d %H:%M:%S 
        const startDate = newStartDatetime?.format("YYYY-MM-DD HH:mm:ss") || '';
        const endDate = newEndDatetime?.format("YYYY-MM-DD HH:mm:ss") || '';
        try{
            setLoading(true);
            let odoo = OdooDatabase.getInstance();
            await odoo.execute_kw('fieldservice.wo.technician.time', 'request_time_fix',
                                  [[[props.time_id], startDate, endDate]]);
            setLoading(false);
            props.onSuccess();
        }
        catch(err: any){
            setLoading(false);
            props.onError(err.faultString);
            console.log('Error', err);
        }
    }

    return (
        <>
            {loading ? (<Loading loading={true}/>) : (<></>)}
            <TechAppDialog open={true} onClose={props.onClose} title={props.title}
                buttons={[
                    {
                        text: 'Request Fix',
                        primary: true,
                        onClick: onOK,
                    },
                    {
                        text: 'Close',
                        primary: false,
                        onClick: props.onClose,
                    },
                ]}
            >
                <form className="dialog-form">
                    <fieldset>
                        <legend>Current Time</legend>
                        <div>
                            <span className="form_label">Current Start Date Time: </span><span>{formatDateToLocale(props.start_date)}</span>
                        </div>
                        <div>
                            <span className="form_label">Current End Date Time: </span><span>{formatDateToLocale(props.end_date)}</span>
                        </div>
                    </fieldset>
                    <fieldset className='form_fieldset'>
                        <legend>Time Fix</legend>
                        <div className='form_row'>
                            <div className='form_field'>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="New Start Date Time"
                                    value={newStartDatetime}
                                    onChange={(newValue) => {
                                        setNewStartDatetime(newValue);
                                    }}
                                />
                            </div>
                            <div className='form_field'>
                                <DateTimePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label="New End Date Time"
                                    value={newEndDatetime}
                                    onChange={(newValue) => {
                                        setNewEndDatetime(newValue);
                                    }}
                                />
                            </div>
                        </div>
                    </fieldset>
                </form>
            </TechAppDialog>
        </>
    )
}