import React, { Component } from 'react';
import TableList from '../TableListViewComponent';
import SelectEditFromModelComponent from '../editors/SelectFromModelComponent'
import TextEdit from '../editors/TextEditComponent'
import '../css/workorders.css';

type TechListProperties = {
    workorder_id: number;
    labor_open?: boolean;
    wo_state: string;
    current_user_id: number;
    onError(errorMsg: string): void;
}

export default class TechList extends Component<TechListProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            techs: [],
            loading: false,
            error: false,
        }
        this.renderTech = this.renderTech.bind(this)
        this.renderAssignedDate = this.renderAssignedDate.bind(this)
    }

    renderTech(field: string, datum: any){
        return (
            <SelectEditFromModelComponent
                updateId={datum.id}
                updateField="user_id"
                value={datum[field] ? datum[field][0] : ''}
                model="res.users"
                updateModel="fieldservice.wo.tech.assignment"
                editable={this.props.wo_state!='complete'}
                onError={this.props.onError}
                disallowNull={true}
            />
        )
    }

    renderAssignedDate(field: string, datum: any){
        return (
            <TextEdit value={datum[field]} type="date" updateId={datum.id}
                updateField="assigned_date" updateModel="fieldservice.wo.tech.assignment"
                editable={datum.user_id && this.props.current_user_id == datum.user_id[0]}
                onError={this.props.onError}
            />
        )
    }

    render(){
        return (<TableList className="techs_table"
            model="fieldservice.wo.tech.assignment"
            domain={[['wo_id', '=', this.props.workorder_id]]}
            fields={['user_id', 'assigned_date']}
            elements={[
                {field: 'user_id', type: 'many2one', 'header': 'Tech', onRender: this.renderTech},
                {field: 'assigned_date', type: 'date', 'header': 'Assigned Date', onRender: this.renderAssignedDate}
            ]}
        ></TableList>)
    }
}