import { Button } from '@material-ui/core';
import {useState} from 'react'
import TableList from '../TableListViewComponent';
import RequestTimeFixDialog from './RequestTimeFixDialog';
import { formatDateToLocale } from '../../data/utils';
import Loading from '../LoadingComponent';
import OdooDatabase from '../../data/odoo';
import '../css/workorders.css'

type TechTimeProps = {
    workorder_id: number;
    workorder_name?: string;
    onError(errorMsg: string, notModal?: boolean): void;
}

export default function TechTimeList(props: TechTimeProps){
    const [fixTimeId, setFixTimeId] = useState(0);
    const [startDatetime, setStartDatetime] = useState('');
    const [endDatetime, setEndDatetime] = useState('');
    const [tableKey, setTableKey] = useState(0);
    const [loading, setLoading] = useState(false);

    const time_types = [
        {
            key: 'wo_labor',
            label: 'Labor'
        },
        {
            key: 'travel',
            label: 'Travel'
        },
        {
            key: 'proc_labor',
            label: 'Labor'
        },
        {
            key: 'diagnostic',
            label: 'Diagnostic'
        }
    ];

    const openRequestFixDialog = (timeId: number, startDatetime: string, endDatetime: string) => {
        setFixTimeId(timeId);
        setStartDatetime(startDatetime);
        setEndDatetime(endDatetime);
    }

    const closeRequestFixDialog = () => {
        setFixTimeId(0);
    }

    const onSuccess = () => {
        setFixTimeId(0);
        setTableKey(1 - tableKey);
    }

    const discardFixRequest = async (time_id: number) => {
        try{
            setLoading(true);
            let odoo = OdooDatabase.getInstance();
            await odoo.execute_kw('fieldservice.wo.technician.time', 'discard_time_fix',
                                  [[[time_id], {from_app: true}]]);
            setLoading(false);
            setTableKey(1 - tableKey);
        }
        catch(err: any){
            setLoading(false);
            props.onError(err.faultString);
            console.log('Error', err);
        }
    }

    const renderRequestFix = (field: string, data: any): JSX.Element => {
        if (data[field])
        return (<Button variant="contained" color={"primary"} onClick={() => {
            discardFixRequest(data['id'])
            }}>Discard Fix</Button>);
        else
            return (<Button variant="contained" color={"primary"} onClick={() => {
                openRequestFixDialog(data['id'], data['start_time'], data['end_time'])
            }}>Request Fix</Button>);
    };

    const renderDateTime = (field: string, data: any): JSX.Element => {
        if (!data[field]){
            return (<></>)
        }
        const fixField = field === 'start_time' ? 'start_time_fix' : 'end_time_fix';
        let result = formatDateToLocale(data[field]);
        if (data[fixField] && data['time_fix_requested']) {
            const fix = formatDateToLocale(data[fixField]);
            result = `${result} => (${fix})`
        }
        return (<span>{result}</span>);
    }

    return (<>
        {
            fixTimeId > 0 ? (
                <RequestTimeFixDialog
                    workorder_id={props.workorder_id}
                    time_id={fixTimeId}
                    start_date={startDatetime}
                    end_date={endDatetime}
                    onClose={closeRequestFixDialog}
                    onError={props.onError}
                    onSuccess={onSuccess}
                    title={"Request Time Fix"}
                ></RequestTimeFixDialog>
            ) : (<></>)
        }
        {loading ? (<Loading loading={true}/>) : (<></>)}
        <TableList
            key={tableKey}
            model="fieldservice.wo.technician.time"
            fields={['start_time', 'end_time', 'type', 'time_fix_requested', 'start_time_fix', 'end_time_fix']}
            domain={[['wo_id', '=', props.workorder_id]]}
            elements={[
                {field: 'start_time', header: 'Start Time', onRender:renderDateTime},
                {field: 'end_time', header: 'End Time', onRender:renderDateTime},
                {field: 'type', header: 'Type', type: 'select', select_items: time_types},
                {field: 'time_fix_requested', header: 'Fix', onRender: renderRequestFix}
            ]}
            className={"times_table"}
            order={'type'}
        />
    </>)
}