import { Component } from 'react';
import Loading from './LoadingComponent';
import PaginationComponent from './PaginationComponent';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import * as FiIcons from "react-icons/fi";
import IconButton from '@material-ui/core/IconButton';
import { IconContext } from 'react-icons';
import { Link } from 'react-router-dom';
import DialogContentText from '@material-ui/core/DialogContentText';
import {formatHTML, findById, findInArray, formatDate} from '../data/utils';
import {formatDateTime} from '../data/utils'
import TechAppDialog from './TechAppDialog';
import Chip from '@material-ui/core/Chip';
import * as MdIcons from "react-icons/md";
import { loadWOs } from '../data/wos';
import WOFilter from '../data/types';
import {copyDict} from '../data/utils';
import './css/workorders.css';
import SpecialInstructionsDialog from './SpecialInstructionsDialogComponent';

type ChipsFilterProperties = {
    WOFilter: WOFilter,
    woTypes: [],
    onFilterRemoved(key: string): void;
}

function ChipsFilter(props: ChipsFilterProperties){
    let wo_states = [
        ['new', 'Requested'],
        ['parts_allocated', 'Parts'],
        ['schedule', 'Scheduling'],
        ['assigned', 'Assigned'],
        ['need_to_return', 'Need To Return'],
        ['on_hold', 'On Hold'],
        ['cancel', 'Canceled'],
        ['complete', 'Complete'],
        ['finalized', 'Finalized'],
        ['to_be_invoiced', 'To Be Invoiced'],
        ['invoiced', 'Invoiced'],
    ]
    let chips = [];
    if(props.WOFilter.my_open_labor || props.WOFilter.my_open_travel){
        if(props.WOFilter.my_open_labor)
            chips.push({key: 'my_open_labor', text: 'My Open Labor'});
        if(props.WOFilter.my_open_travel)
            chips.push({key: 'my_open_travel', text: 'My Open Travel'});
    }
    else{
        if(props.WOFilter.myAssignments)
            chips.push({key: 'myassignments', text: 'My Assignments'});
        if(props.WOFilter.date_filter === 'today')
            chips.push({key: 'date', text: 'Today'});
        else if(props.WOFilter.date_filter === 'tomorrow')
            chips.push({key: 'date', text: 'Tomorrow'});
        else if(props.WOFilter.date_filter === 'this_week')
            chips.push({key: 'date', text: 'This Week'});
        else if(props.WOFilter.date_filter === 'next_week')
            chips.push({key: 'date', text: 'Next Week'});
        else if(props.WOFilter.date_filter === 'this_month')
            chips.push({key: 'date', text: 'This Month'});
        else if(props.WOFilter.date_filter === 'custom')
            chips.push({key: 'date', text: `Date Range: ${formatDate(props.WOFilter.date_start)} - ${formatDate(props.WOFilter.date_end)}`});
        if(parseInt(props.WOFilter.wo_type) > 0){
            const typeName = findById(parseInt(props.WOFilter.wo_type), props.woTypes);
            chips.push({key: 'wo_type', text: `WO Type: ${typeName}`});
        }
        if(props.WOFilter.wo_state !== '' && props.WOFilter.wo_state !== '0'){
            const stateName = findInArray(props.WOFilter.wo_state, wo_states);
            chips.push({key: 'wo_state', text: `WO State: ${stateName}`});
        }
        if(props.WOFilter.all_parts_ready){
            chips.push({key: 'all_parts_ready', text: 'All Parts Ready'});
        }
        if(props.WOFilter.search_name)
            if(props.WOFilter.search_by === 'wo')
                chips.push({key: 'by_name', text: `WO: ${props.WOFilter.search_name}`});
            else
                chips.push({key: 'by_name', text: `Asset: ${props.WOFilter.search_name}`});
    }
    const chipsTags = chips.map((chip) => {
        return (<Chip key={chip.key} label={chip.text} onDelete={() => {props.onFilterRemoved(chip.key)}} className="chips_filter"></Chip>)
    });
    return (<div>{chipsTags}</div>);
}

type WorkOrderProperty = {
    workorder: any;
    onDescIconClick(workorder: any): void;
    onEventLinkClick(workorder: any): void;
}

function WorkOrder(props: WorkOrderProperty){
    const workorder = props.workorder;
    let job_progress_class = workorder.wo_job_progress === 'labor' ? 'wo_job_progress_labor' :
            (workorder.wo_job_progress === 'travel' ? 'wo_job_progress_travel' : 'wo_job_progress_nowork');
    job_progress_class += ' wo_job_progress';
    return (<Paper className="woitem" elevation={3} key={workorder.id}>
            <div className="woitem_header">
                <div className={job_progress_class}></div>
                {
                workorder.wo_id ?
                (<Link to={`/wo/${workorder.wo_id}`}>{workorder.name}</Link>):
                (<Link to={"#"} onClick={() => props.onEventLinkClick(workorder)}>{workorder.name}</Link>)
                }
                <Typography variant="subtitle2">{workorder.state}</Typography>
                {workorder.assigned_date? (<Typography variant="subtitle2">{formatDateTime(new Date(workorder.assigned_date))}</Typography>) : (<></>)}
                {workorder.has_description ?
                <IconButton onClick={() => {props.onDescIconClick(workorder)}}>
                    <IconContext.Provider value={{ color: 'green' }}>
                        <FiIcons.FiFileText/>
                    </IconContext.Provider>
                </IconButton> : <></>
              }
            </div>
                <hr/>
            <div className="woitem_body">
                {workorder.wo_id ?
                (<>
                    <div>
                    <Typography variant="subtitle2">Type:</Typography><Typography variant="body2">{workorder.wo_type}</Typography>
                    </div>
                    <div>
                    <Typography variant="subtitle2">Asset:</Typography><Typography variant="body2">{workorder.asset_name}</Typography>
                    </div>
                    {
                    workorder.site_gate_code?
                    (<div>
                    <Typography variant="subtitle2">Gate Code:</Typography><Typography variant="body2">{workorder.site_gate_code}</Typography>
                    </div>):(<></>)
                    }
                    <div>
                    <Typography variant="subtitle2">Customer:</Typography><Typography variant="body2">{workorder.customer_name}</Typography>
                    </div>
                    <div>
                    <Typography variant="subtitle2">Parts Ready:</Typography><Typography variant="body2">
                        {workorder.has_all_parts_ready_to_pick} ({workorder.parts_ready_count} / {workorder.parts_count})</Typography>
                    </div>
                    <div>
                    <Typography variant="subtitle2">Parts Picked:</Typography><Typography variant="body2">({workorder.parts_picked_count} / {workorder.parts_count})</Typography>
                    </div>
                    <div>
                    <Typography variant="subtitle2"># Techs:</Typography><Typography variant="body2">{workorder.techs_count}</Typography>
                    </div>
                </>):(<>
                    <div dangerouslySetInnerHTML={{ __html: formatHTML(workorder.description) }} className="event_description"/>
                </>)
                }
            </div>
            </Paper>);
}

type EventDataProperties = {
    open: boolean;
    workorder: any;
    onClose(): void;
}

function EventDataDialog(props: EventDataProperties){
    return (<TechAppDialog open={props.open} title="Event" onClose={props.onClose} closeOnClickOutside={true}>
        <DialogContentText>
                <Typography variant="subtitle2">{props.workorder.name}</Typography>
                <Typography>{formatDateTime(new Date(props.workorder.assigned_date))}</Typography>
                <div dangerouslySetInnerHTML={{ __html: formatHTML(props.workorder.description) }} className="event_description"/>
            </DialogContentText>
    </TechAppDialog>);
}


type WOsProperties = {
    loading: boolean,
    WOFilter: WOFilter,
    woTypes: [],
    onFilterUpdate(WOFilter: WOFilter): void;
    onMount(mounted: boolean): void;
    onError(error: any): void;
}

export default class Workorders extends Component<WOsProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            descModalOpen: false,
            eventModalOpen: false,
            selectedWorkOrder: false,
            workorders: [],
            workordersCount: 0,
            loading: false,
            pageIndex: 0,
            pageSize: 25,
            error: false,
            WOFilter: copyDict(props.WOFilter),
        }
        this.closeModal = this.closeModal.bind(this);
        this.onPageChanged = this.onPageChanged.bind(this);
        this.removeFilter = this.removeFilter.bind(this);
    }

    async loadWOs(pageIndex: number, filter: WOFilter){
        try{
            this.setState({loading: true, error: false});
            const result = await loadWOs(filter, pageIndex * this.state.pageSize, this.state.pageSize)
            this.setState({loading: false, workorders: result.wos, workordersCount: result.wos_count});
        }
        catch(err: any){
            console.log('Error', err);
            this.setState({loadingWOs: false, error: true});
            this.props.onError(err);
        }
    }

    onPageChanged(pageIndex: number){
        this.setState({pageIndex: pageIndex})
        this.loadWOs(pageIndex, this.state.WOFilter)
    }

    componentDidMount(){
        this.props.onMount(true);
        this.loadWOs(0, this.state.WOFilter)
    }

    componentDidUpdate(){
        if(JSON.stringify(this.props.WOFilter) != JSON.stringify(this.state.WOFilter)){
            this.setState({WOFilter: copyDict(this.props.WOFilter)})
            this.setState({pageIndex: 0})
            this.loadWOs(0, this.props.WOFilter)
        }
    }

    componentWillUnmount(){
        this.props.onMount(false);
    }

    removeFilter(filter: string){
        let WOFilter = copyDict(this.state.WOFilter);
        if(filter === 'myassignments')
            WOFilter.myAssignments = false;
        else if(filter === 'date'){
            WOFilter.date_filter = 'none';
        }
        else if(filter === 'wo_type')
            WOFilter.wo_type = '0';
        else if(filter === 'wo_state')
            WOFilter.wo_state = '0';
        else if(filter === 'by_name')
            WOFilter.search_name = '';
        else if(filter === 'my_open_labor')
            WOFilter.my_open_labor = false;
        else if(filter === 'my_open_travel')
            WOFilter.my_open_travel = false;
        else if(filter === 'all_parts_ready')
            WOFilter.all_parts_ready = false;
        this.setState({WOFilter: WOFilter});
        this.loadWOs(this.state.pageIndex, WOFilter)
        this.props.onFilterUpdate(copyDict(WOFilter))
    }

    onDescIconClick(workorder: any){
        this.setState({
           descModalOpen: true,
           selectedWorkOrder: workorder,
        });
    }

    onEventLinkClick(workorder: any){
        this.setState({
            eventModalOpen: true,
            selectedWorkOrder: workorder,
         });
    }

    closeModal(){
        this.setState({descModalOpen: false, eventModalOpen: false});
    }

    render(){
        if(this.state.loading || this.props.loading){
            return (<Loading loading={this.state.loading} fullWidth={false}/>)
        }
        const items = this.state.workorders.map((workorder: any) => {
            return (<WorkOrder key={workorder.id} workorder={workorder}
                onDescIconClick={(workorder) => {this.onDescIconClick(workorder)}}
                onEventLinkClick={(workorder) => {this.onEventLinkClick(workorder)}}
                />);
        });
        return (
            <div className="main_component_container">
            {
                this.state.descModalOpen ?
                (<SpecialInstructionsDialog open={true} workorder={this.state.selectedWorkOrder}
                    onClose={this.closeModal}
                />) : (<></>)
            }
            <EventDataDialog open={this.state.eventModalOpen} workorder={this.state.selectedWorkOrder}
                onClose={this.closeModal}></EventDataDialog>
            <div className="componentheader workorders_header">
            <ChipsFilter WOFilter={this.state.WOFilter} woTypes={this.props.woTypes} onFilterRemoved={this.removeFilter}/>
            <IconButton onClick={() => {this.loadWOs(this.state.pageIndex, this.state.WOFilter)}}><MdIcons.MdRefresh className="refresh_icon"/></IconButton>
            </div>
            {items}
            <PaginationComponent
                pageIndex={this.state.pageIndex}
                itemsCount={this.state.workordersCount}
                onPageChanged={this.onPageChanged}
            />
            </div>
        )
    }
}