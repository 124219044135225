import React from 'react';
import './App.css';
import Main from './components/MainComponent';
import generatedGitInfo from './generatedGitInfo.json';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';


function App() {
  console.log("Branch", generatedGitInfo.gitBranch)
  console.log("Commit", generatedGitInfo.gitCommitHash)

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <div className="App">
          <Main/>
      </div>
    </LocalizationProvider>
  );
}

export default App;
