import React from 'react'
import { useState, useEffect, useRef } from 'react'
import TechAppDialog from './TechAppDialog'
import Loading from './LoadingComponent'
import OdooDatabase from '../data/odoo'

type TimeZoneDlgProperties = {
    open: boolean;
    currentTimeZone: string;
    onClose(): void;
    onOK(timeZoneOffset: number, timeZone: string): void;
}

type TimeZone = {
    id: number;
    name: string;
}

export default function TimeZoneDlg(props: TimeZoneDlgProperties){
    const [loading, setLoading] = useState(false)
    const [timeZones, setTimeZones] = useState<TimeZone[]>([])
    const [selectedTimeZone, setSelectedTimeZone] = useState(0)
    const [updating, setUpdating] = useState(false)
    const mountedRef = useRef(true)

    const numberTimeZones = (timeZones: any[]) => {
        let tzs: TimeZone[] = []
        let i = 0
        for(let tz of timeZones){
            i++
            tzs.push({id: i, name: tz[1]})
            if(tz[1] == props.currentTimeZone){
                setSelectedTimeZone(i)
            }
        }
        return tzs
    }

    const getTimeZones = async() =>{
        setLoading(true)
        try{
            const odoo = OdooDatabase.getInstance()
            const tzs = await odoo.execute_kw('res.users', 'get_all_time_zones', [[]])
            if (!mountedRef.current) return
            setTimeZones(numberTimeZones(tzs))
            setLoading(false)
        }
        catch(err: any){
            setLoading(false)
            console.log('Error', err)
        }
    }

    const updateTimeZone = async () => {
        if(selectedTimeZone < 1 || selectedTimeZone > timeZones.length){
            alert('Invalid Time Zone')
            return
        }
        const tz = timeZones[selectedTimeZone - 1].name
        setUpdating(true)
        try{
            const odoo = OdooDatabase.getInstance()
            const new_tz = await odoo.execute_kw('res.users', 'set_user_time_zone', [[tz]])
            setUpdating(false)
            props.onOK(new_tz[0], new_tz[1])
        }
        catch(err: any){
            setUpdating(false)
            console.log('Error', err)
        }
    }

    useEffect(() => {
        getTimeZones()
        return () => {
            mountedRef.current = false
        }
    }, [])
    const items = !loading ? timeZones.map((tz: TimeZone) => {
        return (<option key={`menutz_${tz.id}`} value={tz.id}>{tz.name}</option>)
    }) : []
    return (
        <>
        <Loading loading={updating}/>
        <TechAppDialog open={props.open}
            onClose={props.onClose}
            title="Update Time Zone"
            buttons={[{text: 'OK', onClick: updateTimeZone, primary: true}, {text: 'Cancel', onClick: props.onClose}]}
        >
            {
                
                loading ?
                (<Loading loading={true} fullWidth={false}/>):
                (<>
                    <select className="tech_app"
                        value={selectedTimeZone}
                        onChange={(event: any) => {setSelectedTimeZone(event.target.value)}}
                    >
                        <option value={0}></option>
                        {items}
                    </select>
                </>)
            }
        </TechAppDialog>
        </>
    )
}