import React from 'react'
import * as SiIcons from 'react-icons/si'
import { IconContext } from 'react-icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import './css/loading.css'


function Loading({loading=false, fullWidth = true, useCircularProgress = false}){
    const className = fullWidth ? "odoo_loading" : "component_loading";
    const color = fullWidth ? '#ffffff' : '#000000';
    if(loading)
        return(
            <div className={className}>
                {
                useCircularProgress?
                (<CircularProgress className="spinner"/>):
                (<IconContext.Provider value={{ color: color }}>
                    <SiIcons.SiSpinrilla className="spinner animated"/>
                </IconContext.Provider>)
                }
            </div>
        );
    else
        return (<></>);
};

export default Loading;