import IconButton from '@material-ui/core/IconButton'
import { IconContext } from 'react-icons'
import * as FaIcons from 'react-icons/fa'
import TechAppDialog from './TechAppDialog'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { useState, useEffect, useRef } from 'react'
import Loading from './LoadingComponent'
import OdooDatabase from '../data/odoo'
import FormHelperText from '@material-ui/core/FormHelperText'
import './css/footer.css'

type StartTravelDialogProperties = {
    workorder_id?: number;
    onClose(): void;
    onStartTravel(destination: string, workorder_id: number, description: number): void;
}

function StartTravelDialog(props: StartTravelDialogProperties){
    const [travelType, setTravelType] = useState('')
    const [description, setDescription] = useState(0)
    const [loading, setLoading] = useState(false)
    const [descriptions, setDescriptions] = useState<any>([])
    const [invalidTravelType, setInvalidTravelType] = useState('')
    const [invalidDesc, setInvalidDesc] = useState(false)
    const mountedRef = useRef(true)

    const validateTravelType = (travel_type: string) => {
        if(travel_type == ''){
            setInvalidTravelType('Select Destination')
            return false
        }
        else if(travel_type == 'tojob' && !props.workorder_id){
            setInvalidTravelType('Must be in WO page to start travel to WO')
            return false
        }
        else{
            setInvalidTravelType('')
            return true
        }
    }

    const validateDescription = (desc: number) => {
        setInvalidDesc(desc == 0)
        return desc > 0
    }

    const onTravelTypeChange = (event: any) => {
        const travel_type = event.target.value
        setTravelType(travel_type)
        validateTravelType(travel_type)
    }
    const onDescriptionChange = (event: any) => {
        const desc_id = parseInt(event.target.value)
        setDescription(desc_id)
        validateDescription(desc_id)
    }

    const loadData = async () => {
        const odoo = OdooDatabase.getInstance()
        try{
            setLoading(true)
            const descriptions = await odoo.searchRead('fieldservice.time.description', [], ['name'])
            if (!mountedRef.current) return
            setDescriptions(descriptions)
            setLoading(false)
        }
        catch(err: any){
            setLoading(false)
            console.log('Error', err)
        }
    }

    useEffect(() => {
        loadData()
        return () => {
            mountedRef.current = false
        }
    }, [])
    let descItems = []
    if(travelType == 'other')
        descItems = descriptions.map((item: any) => {
            return (<MenuItem key={`desc_${item.id}`} value={item.id}>{item.name}</MenuItem>)
        })

    const startTravel = () => {
        const validTravelType = validateTravelType(travelType)
        if(travelType == 'other'){
            const validDesc = validateDescription(description)
            if(!validDesc) return
        }
        if(!validTravelType) return
        props.onStartTravel(travelType, props.workorder_id ? props.workorder_id : 0, description ? description : 0)
        props.onClose()
    }

    return (
        <TechAppDialog
            open={true}
            title="Start Travel"
            onClose={props.onClose}
            buttons={[{text:'Start Travel', onClick:startTravel, primary: true},
                      {text:'Cancel', onClick:props.onClose}]}
        >
            {
            loading ? (<Loading loading={true} fullWidth={false}/>):
            (<form className="dialog-form">
                <FormControl className="full_width dialog_form_control">
                    <InputLabel>Destination</InputLabel>
                    <Select
                        fullWidth
                        value={travelType}
                        onChange={onTravelTypeChange}
                    >
                        <MenuItem value={''}></MenuItem>
                        <MenuItem value={'tojob'}>To Job</MenuItem>
                        <MenuItem value={'toshop'}>To Shop</MenuItem>
                        <MenuItem value={'other'}>Other</MenuItem>
                    </Select>
                    {invalidTravelType != '' ? (<FormHelperText>{invalidTravelType}</FormHelperText>):(<></>)}
                </FormControl>
                {props.workorder_id ? (<span>{'WO-'+('' + props.workorder_id).padStart(5, '0')}</span>) : (<></>)}
                    {
                        (travelType == 'other')?
                        (
                        <FormControl className="full_width dialog_form_control">
                        <InputLabel>Description</InputLabel>
                        <Select
                            fullWidth
                            value={description}
                            onChange={onDescriptionChange}
                        >
                            <MenuItem value={'0'}></MenuItem>
                            {descItems}
                        </Select>
                        {invalidDesc ? (<FormHelperText>Select Description</FormHelperText>):(<></>)}
                        </FormControl>
                        ):
                        (<></>)
                    }
            </form>)
            }
        </TechAppDialog>
    )
}

type FooterProperties = {
    tech_state: any;
    can_start_labor: boolean;
    can_stop_labor: boolean;
    can_start_travel: boolean;
    can_start_travel_to_wo: boolean;
    can_stop_travel: boolean;
    can_start_diagnostic?: boolean;
    can_stop_diagnostic?: boolean;
    active_workorder_id?: number;
    onTechStateChanged(workorder_id: number, workorder: any): void;
    onStartLabor(workorder_id: number, diagnostic?: boolean): void;
    onStopLabor(workorder_id: number): void;
    onStartTravelToJob(workorder_id: number): void;
    onStopTravel(workorder_id: number): void;
    onStartTravel(destination: string, workorder_id: number, description: number): void;
    loading: boolean;
}

export default function Footer(props: FooterProperties){
    const [travelDlgOpen, setTravelDlgOpen] = useState(false)
    const onCloseDlg = () => {
        setTravelDlgOpen(false)
    }

    return (<div className="tech_app_footer">
        {travelDlgOpen ? (<StartTravelDialog workorder_id={props.active_workorder_id} onClose={onCloseDlg} onStartTravel={props.onStartTravel}/>) : (<></>)}
        {
        !props.loading && props.active_workorder_id && !props.can_start_travel && !props.can_start_labor && !props.can_stop_travel && !props.can_stop_labor
            && !props.can_start_diagnostic && !props.can_stop_diagnostic ?
        (<span>YOU ARE NOT ASSIGNED TO THIS WO TODAY</span>) : (<></>)
        }
        {
        props.can_start_travel ?
        (<IconContext.Provider value={{ color: '#ffffff'}}>
            <IconButton onClick={() => {setTravelDlgOpen(true)}}><FaIcons.FaCarAlt/></IconButton>
        </IconContext.Provider>):(<></>)
        }
        {
        props.can_stop_travel ?
        (
        <><IconContext.Provider value={{ color: '#008000'}}>
            <IconButton disabled><FaIcons.FaCarAlt/></IconButton>
        </IconContext.Provider>
        <span>{props.tech_state.state == 'in_travel_to_wo' ? 'To Job' : props.tech_state.state == 'in_travel_to_shop' ? 'To Shop' : 'Other'}</span>
        <IconContext.Provider value={{ color: '#ff0000'}}>
            <IconButton onClick={()=>{props.onStopTravel(props.active_workorder_id ? props.active_workorder_id : 0)}}><FaIcons.FaStopCircle/></IconButton>
        </IconContext.Provider></>):(<></>)
        }
        {props.can_start_labor ? (
            <IconContext.Provider value={{ color: '#ffffff'}}>
                <IconButton onClick={()=>{if(props.active_workorder_id){props.onStartLabor(props.active_workorder_id)}}}><FaIcons.FaWrench/></IconButton>
            </IconContext.Provider>) : (<></>)}
        {props.can_stop_labor ? (
            <>
            <IconContext.Provider value={{ color: '#008000'}}>
            <IconButton disabled><FaIcons.FaWrench/></IconButton>
            <span>{'WO-'+('' + props.active_workorder_id).padStart(5, '0')}</span>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: '#ff0000'}}>
            <IconButton onClick={()=>{if(props.active_workorder_id){props.onStopLabor(props.active_workorder_id)}}}><FaIcons.FaStopCircle/></IconButton>
            </IconContext.Provider>
            </>
            ) : (<></>)}
        {props.can_start_diagnostic ? (
            <IconContext.Provider value={{ color: '#ffffff'}}>
                <IconButton onClick={()=>{if(props.active_workorder_id){props.onStartLabor(props.active_workorder_id, true)}}}><FaIcons.FaStethoscope/></IconButton>
            </IconContext.Provider>) : (<></>)}
        {props.can_stop_diagnostic ? (
            <>
            <IconContext.Provider value={{ color: '#008000'}}>
            <IconButton disabled><FaIcons.FaStethoscope/></IconButton>
            <span>{'WO-'+('' + props.active_workorder_id).padStart(5, '0')}</span>
            </IconContext.Provider>
            <IconContext.Provider value={{ color: '#ff0000'}}>
            <IconButton onClick={()=>{if(props.active_workorder_id){props.onStopLabor(props.active_workorder_id)}}}><FaIcons.FaStopCircle/></IconButton>
            </IconContext.Provider>
            </>
            ) : (<></>)}

    </div>)
}