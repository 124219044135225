import React from 'react';
import TableList from '../TableListViewComponent';
import Button from '@material-ui/core/Button';
import Loading from '../LoadingComponent';
import OdooDatabase from '../../data/odoo';
import {useState} from 'react';
import '../css/assets.css';

type AssetAssignmentsProperties = {
    workorder_id: number;
    onError(errorMsg: string): void;
}

export default function AssetAssignments(props: AssetAssignmentsProperties){
    const [markedPickedButtonVisible, setMarkedPickedButtonVisible] = useState(false)
    const [data, setData] = useState<any[]>([])
    const [updating, setUpdating] = useState(false)
    const onDataLoaded = (data: any) => {
        setData(data)
        let buttonVisible = false
        for(let item of data){
            if(item.state == 'scheduled')
                buttonVisible = true
        }
        setMarkedPickedButtonVisible(buttonVisible) 
    }

    const onMarkPicked = async () => {
        let items_checked = []
        for(let item of data){
            if(item._checked)
                items_checked.push(item.id)
        }
        if(items_checked.length == 0){
            props.onError("Select assets to pick")
            return
        }
        let odoo = OdooDatabase.getInstance();
        setUpdating(true)
        try{
            await odoo.execute_kw('fieldservice.workorder', 'mark_asset_assignments_picked', [[items_checked]]);
            setUpdating(false)
        }catch(err: any){
            console.log('Error', err);
            setUpdating(false)
            props.onError(err.faultString);
            return
        }
    }

    if(updating){
        return (<Loading loading={true}/>)
    }
    return (<><TableList className="asset_assignments_table"
            model="fieldservice.wo.asset.assignment"
            domain={[['wo_id', '=', props.workorder_id]]}
            fields={['asset_id', 'start_date', 'end_date', 'state', 'description']}
            checkBoxes={true}
            onDataLoaded={onDataLoaded}
            elements={[
                {field: 'asset_id', type: 'many2one', 'header': 'Asset'},
                {field: 'start_date', type: 'date', 'header': 'Start Date'},
                {field: 'end_date', type: 'date', 'header': 'End Date'},
                {field: 'state', type: 'select', 'header': 'Status', 'select_items': [{
                    key: 'scheduled',
                    label: 'Scheduled'
                },
                {
                    key: 'in_transit',
                    label: 'In Transit'
                },
                {
                    key: 'done',
                    label: 'Done'
                }, {
                    key: 'returned',
                    label: 'Returned'
                },
            ]},
            {field: 'description', type: 'text', 'header': 'Description'},
            ]}
        ></TableList>
        <div className="wo_app_buttons">
            {
            markedPickedButtonVisible ? (<Button
                variant="contained"
                color="primary"
                onClick={onMarkPicked}
            >Mark Picked</Button>
            ) : (<></>)
            }
        </div>
        </>)
}