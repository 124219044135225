import React, { Component } from 'react';
import TechAppDialog from './../TechAppDialog';
import TextField from '@material-ui/core/TextField';

type TextEditDialogProperties = {
    open: boolean;
    title: string;
    okText?: string;
    initialValue: string;
    singleLine?: boolean;
    onClose(): void;
    onOK(value: string): void;
    clearOnOK?: boolean;
}

export default class TextEditDialog extends Component<TextEditDialogProperties, any>{
    constructor(props: any){
        super(props);
        this.onOK = this.onOK.bind(this);
        this.onChange = this.onChange.bind(this);
        this.state = {
            value: this.props.initialValue
        }
    }

    onOK(){
        this.props.onOK(this.state.value);
        if(this.props.clearOnOK)
            this.setState({value: ''});
    }

    onChange(event: any){
        this.setState({value: event.target.value});
    }

    render(){
        return (
            <>
            <TechAppDialog
                title={this.props.title}
                open={this.props.open}
                onClose={this.props.onClose}
                fullWidth={!this.props.singleLine}
                buttons={[
                    {text: this.props.okText ? this.props.okText : 'OK', onClick: this.onOK, primary: true},
                    {text: 'Cancel', onClick: this.props.onClose}]}
            >
                {
                    this.props.singleLine ?
                    (<TextField
                        id="text_edit_singleline"
                        fullWidth
                        value={this.state.value}
                        onChange={this.onChange}
                        />
                    ):
                    (
                        <TextField
                            id="text_edit_multiline"
                            multiline
                            fullWidth
                            rows={20}
                            value={this.state.value}
                            onChange={this.onChange}
                            />
                    )
                }

            </TechAppDialog>
            </>
        );
    }
}