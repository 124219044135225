import React from 'react'
import {useState, useEffect, useRef} from 'react'
import TechAppDialog from '../TechAppDialog'
import { FormGroup } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Loading from '../LoadingComponent'
import OdooDatabase from '../../data/odoo'
import NativeSelect from '@material-ui/core/NativeSelect'
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'


type ReassignDialogProperties = {
    workorder_id: number;
    open: boolean;
    onError?(errorMsg: string): void;
    onOK(): void;
    onClose(): void;
}

export default function ReassignDialog(props: ReassignDialogProperties){
    const [loading, setLoading] = useState(false)
    const [substatuses, setSubstatuses] = useState<any[]>([])
    const [substatus, setSubstatus] = useState('')
    const [asset_state, setAssetState] = useState('')
    const [updating, setUpdating] = useState(false)
    const mountedRef = useRef(true)

    const onReassignClick = async () => {
        if(substatus == '' || substatus == '0' || asset_state == '')
            return
        let odoo = OdooDatabase.getInstance();
        setUpdating(true)
        try{
            await odoo.execute_kw("fieldservice.workorder", "reassign_wo", [[[props.workorder_id], parseInt(substatus), asset_state]])
            setUpdating(false)
            alert('WorkOrder Reassigned')
            props.onOK()
        }catch(err: any){
            setUpdating(false)
            console.log('Error', err)
            if(props.onError)
                props.onError(err.faultString)
        }
    }
    const loadData = async () => {
        setLoading(true)
        let odoo = OdooDatabase.getInstance();
        try{
            const substatuses = await odoo.searchRead(
                'fieldservice.subcategory',
                [['allowed_for_techs', '=', true]], ['name'])
            if (!mountedRef.current) return
            setSubstatuses(substatuses)
            setLoading(false)
        }
        catch(err: any){
            console.log('Error', err)
            if (!mountedRef.current) return
            setLoading(false)
            if(props.onError)
                props.onError(err.faultString)
        }
    }

    useEffect(() => {
        loadData()
        return () => { 
            mountedRef.current = false
          }
    }, [])
    const items = substatuses.map((item: any) => {
      return (<option key={`substatus_${item.id}`} value={item.id}>{item.name}</option>)      
    })
    return (
        <><Loading loading={updating}/>
        <TechAppDialog open={props.open} onClose={props.onClose} title="Reassign"
            buttons={[{text: 'Reassign', onClick:onReassignClick, primary: true},
                     {text: 'Close', onClick: props.onClose}
                     ]}
        >
            <form className="dialog-form">
                <FormGroup row className="boxed_group">
                    {
                    loading ? (<Loading loading={true} fullWidth={false}/>):
                    (<FormControl className="app_control_space">
                        <InputLabel id="wo-state-label">Substatus</InputLabel>
                        <NativeSelect value={substatus}
                        fullWidth
                        id="wo_substatus"
                        name="wo_substatus"
                        onChange={(event: any)=>{setSubstatus(event.target.value)}}
                        >
                            <option value={'0'}></option>
                            {items}
                        </NativeSelect>
                        {(substatus=='' || substatus == '0')?(<FormHelperText>Select substatus</FormHelperText>):(<></>)}
                    </FormControl>)
                   }
                </FormGroup>
                <FormGroup row className="boxed_group">
                    <FormControl>
                        <FormLabel component="legend">Asset State</FormLabel>
                        <RadioGroup row aria-label="Asset State" name="asset_state" value={asset_state} onChange={(event:any) => {setAssetState(event.target.value)}}>
                            <FormControlLabel
                                value="red"
                                control={<Radio color="primary" />}
                                label="Red"
                            />  
                            <FormControlLabel
                                value="yellow"
                                control={<Radio color="primary" />}
                                label="Yellow"
                            />
                            <FormControlLabel
                                value="green"
                                control={<Radio color="primary" />}
                                label="Green"
                            />
                        </RadioGroup>
                        {(asset_state === '')?(<FormHelperText>Select Asset State</FormHelperText>):(<></>)}
                    </FormControl>
                </FormGroup>
            </form>
        </TechAppDialog>
        </>
    )
}