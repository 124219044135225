import { useState, useEffect, useRef } from 'react'


type MapProperties = {
    centerLatitude?: number;
    centerLongitude?: number;
    showCurrentLocation?: boolean;
    onMapInit(map: any): void;
    containerClass?: string;
}

export default function Map(props: MapProperties) {
    const [map, setMap] = useState<any>()
    const ref = useRef<HTMLDivElement>(null);

    const showCurrentLocation = (map: any) => {
        var myLocationMarker: google.maps.Marker;

        const updateMyLocation = () => {
            navigator.geolocation.getCurrentPosition(function(position) {
                const myLocationPoint = new google.maps.LatLng(position.coords.latitude,
                    position.coords.longitude);
                if(myLocationMarker) {
                    myLocationMarker.setPosition(myLocationPoint);
                } else {
                    let image = process.env.REACT_APP_BASE_URL + 'mylocation.png';
                    myLocationMarker = new google.maps.Marker({
                        position: myLocationPoint,
                        map: map,
                        icon: image,
                        draggable:false, animation: google.maps.Animation.DROP,
                        title: ''
                    });
                }
            });
        }
        updateMyLocation();
        const interval = setInterval(updateMyLocation, 15000);
        return interval;
    }

    const initMap = () => {
        if (ref.current) {
            let mapLat = props.centerLatitude || 29.444518;
			let mapLong = props.centerLongitude || -82.319677;
            const map = new google.maps.Map(ref.current, {
                    zoom: 7,
                    center: new google.maps.LatLng(mapLat, mapLong),
                    streetViewControl: false,
                    rotateControl: false,
                    scaleControl: true,
                    fullscreenControl: true,
                    panControl: false,
                    zoomControl: true,
                    gestureHandling: 'greedy',
                    draggableCursor: 'pointer',
                })
            props.onMapInit(map);
            return map;
        }
        return null;
    }


    useEffect(() => {
        const map = initMap();
        setMap(map);
        if (map && props.showCurrentLocation) {
            const interval = showCurrentLocation(map);
            return () => { clearInterval(interval) };
        }
    }, []);

    const mapClass = props.containerClass || 'map_container_map';

    return (
        <div ref={ref} className={mapClass}></div>
    )
}