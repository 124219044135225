import React, { Component } from 'react';
import Loading from '../LoadingComponent';
import OdooDatabase from '../../data/odoo';
import {Link} from 'react-router-dom';
import SelectEditComponent from '../editors/SelectEditComponent';

type ProcListProperties = {
    workorder_id: number;
    labor_open?: boolean;
    onUpdateError(errorMsg: string): void;
}

export default class ProcedureList extends Component<ProcListProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            procedures: [],
            tech_names: [],
            loading: false,
            error: false,
        }
        this.updateProcState = this.updateProcState.bind(this);
    }

    componentDidMount(){
        this.loadProcedures();
    }

    async loadProcedures(){
        this.setState({loading: true});
        let odoo = OdooDatabase.getInstance();
        try{
            let procs = await odoo.searchRead('fieldservice.workorder.proc.rel', {workorder_id: this.props.workorder_id}, ['name', 'state', 'techs_ids']);
            let techs = [];
            for(let i = 0; i < procs.length; i++){
                for(let t = 0; t < procs[i].techs_ids.length; t++){
                    const tech = procs[i].techs_ids[t];
                    if(techs.indexOf(tech) < 0)
                        techs.push(tech);
                }
            }
            const tech_names = await odoo.read('res.users', techs, ['name']);
            for(let i = 0; i < procs.length; i++){
                procs[i].techs_names = [];
                for(let t = 0; t < procs[i].techs_ids.length; t++){
                    const tech = procs[i].techs_ids[t];
                    for(let t2 = 0; t2 < tech_names.length; t2++){
                        if(tech_names[t2].id == tech)
                            procs[i].techs_names.push(tech_names[t2].name)
                    }
                }
            }
            console.log('Procedures', procs);
            this.setState({loading: false, error: false, procedures: procs});
        }catch(err: any){
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    async updateProcState(recordId: number, value: any): Promise<boolean>{
        let result = false;
        try{
            let odoo = OdooDatabase.getInstance();
            result = await odoo.update('fieldservice.workorder.proc.rel', recordId, {
                state: value
            });
        }
        catch(err: any){
            console.log('Error', err);
            this.props.onUpdateError(err.faultString);
        }
        if(!result){
        }
        return result;
    }

    render(){
        if(this.state.loading)
            return (<Loading loading={true} fullWidth={false}/>);
        else if(this.state.error)
            return (<span>Error loading Procedure list</span>);
        else{
            const proc_states = [{value: 'uncompleted', label: 'No'}, {value: 'completed', label: 'Yes'}, {value: 'pending', label: 'Pend'}]
            const rows = this.state.procedures.map((proc:any) => {
                const techs = proc.techs_names.join(", ");
                return (
                    <tr key={`proc_${proc.id}`}>
                        <td><Link to={`/proc/${proc.id}`}>{proc.name}</Link></td>
                        <td><SelectEditComponent
                            recordId={proc.id}
                            editable={this.props.labor_open}
                            value={proc.state}
                            selectItems={proc_states}
                            recordUpdate={this.updateProcState}
                            workingFullScreen={false}
                            disallowNull={true}
                            alwaysEditMode={true}
                        /></td>
                        <td>{techs}</td>
                    </tr>
                );
            });
            return (<table className="data_table full_width">
                <thead>
                    <tr>
                        <td>Procedure</td>
                        <td>Done</td>
                        <td>Techs</td>
                    </tr>   
                </thead> 
                <tbody>
                    {rows}
                </tbody>
            </table>);
        }
    }
}