import { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Help from './HelpComponent';
import TechMap from './TechMapComponent';
import Navbar from './Navbar';
import Workorders from './WorkOrdersComponent';
import WorkOrder from './WorkOrderComponent';
import Login from './LoginComponent';
import OdooDatabase from '../data/odoo';
import SearchWODialog from './SearchWOComponent';
import {copyDict, formatStandardDate, setCookie, getCookie, deleteCookie} from '../data/utils'
import WOFilter, {initWOFilter} from '../data/types';
import { Redirect } from 'react-router-dom';
import Footer from './FooterComponent';
import Tasks from './TasksComponent';
import Loading from './LoadingComponent'
import Notification from './NotificationComponent'
import { addUrlApi } from '../data/utils'
import Snackbar from '@material-ui/core/Snackbar'
import Button from '@material-ui/core/Button'
import TimeZoneDlg from './ChangeTimeZoneDlg'
import Inventory from './InventoryComponent';
import PartsToReturn from './ReturnPartsComponent';
import WOImage from './WOImageComponent';
import LoginFromCookies from './LoginFromCookies';

export default class Main extends Component<any, any>{
    WOsPageActive : boolean;
    constructor(props: any){
        super(props);
        this.WOsPageActive = false;
        this.state = {
          loggedIn: false,
          userId: 0,
          userName: '',
          userFullName: '',
          token: '',
          showModal: false,
          searchModalOpen: false,
          woTypes:[],
          woTypesLoaded: false,
          loading: false,
          WOFilter: initWOFilter(),
          searchDialogId: 0,
          error: false,
          redirect: false,
          redirect_to_wo: 0,
          tech_state: false,
          current_wo: 0,
          loadingTechState: false,
          configuration: {},
          can_start_labor: false,
          can_stop_labor: false,
          can_start_travel: false,
          can_start_travel_to_wo: false,
          can_stop_travel: false,
          active_workorder_id: 0,
          updating: false,
          showMessage: false,
          message: '',
          messageAsDialog: false,
          messageIsError: false,
          googleApiKey: '',
          googleScriptLoaded: false,
          workordersKey: 0,
          showTimeZoneMismatch: false,
          timeZoneOffset: 0,
          timeZone: '',
          updateTimeZoneDialogOpen: false,
          active_wo_id: 0,
          wo_component_key: 0,  // Used to refresh WO component
          showSearchIcon: true,
          partsReturnsCount: 0,
        }
        this.onTechStateChanged = this.onTechStateChanged.bind(this)
        this.onWorkorderPageActive = this.onWorkorderPageActive.bind(this)
        this.handleCloseMessage = this.handleCloseMessage.bind(this)
        this.showErrorMessage = this.showErrorMessage.bind(this)
        this.startLabor = this.startLabor.bind(this)
        this.stopLabor = this.stopLabor.bind(this)
        this.startTravelToWO = this.startTravelToWO.bind(this)
        this.onStartTravel = this.onStartTravel.bind(this)
        this.stopTravel = this.stopTravel.bind(this)
        this.onFilterUpdate = this.onFilterUpdate.bind(this)
        this.onTimeZoneChanged = this.onTimeZoneChanged.bind(this)
        this.onRedirectToWO = this.onRedirectToWO.bind(this)
        this.onError = this.onError.bind(this)
        this.onWOsClick = this.onWOsClick.bind(this)
        this.onPartsReturnsCountChanged = this.onPartsReturnsCountChanged.bind(this)
    }

    componentDidMount() {
        document.title = 'Tech App'
    }

    onWOsClick() {
        this.setState({workordersKey: 1 - this.state.workordersKey});
    }

    onPartsReturnsCountChanged(partsCount: number){
        this.setState({
            partsReturnsCount: partsCount
        });
    }

    async loadWOTypes(){
        try{
            let odoo = OdooDatabase.getInstance();
            const woTypes = await odoo.searchRead('fieldservice.procedure.type', {}, ['name'], {'order': 'name'});
            this.setState({'woTypes': woTypes, woTypesLoaded: true});
        }
        catch(err: any){
            console.log('Error', err);
        }
    }

    async loadConfiguration(){
        try{
            this.setState({loading: true});
            let odoo = OdooDatabase.getInstance();
            const conf = await odoo.searchRead('fieldservice.configuration', {active: true}, ['minimum_distance_to_asset'], {'order': 'name'});
            this.setState({loading: false});
            if(conf.length > 0)
                this.setState({'configuration': conf[0]});
            else
                this.setState({'configuration': {minimum_distance_to_asset: 5}});
            if(!this.state.googleApiKey){
                const apiKey = await odoo.execute_kw('fieldservice.configuration', 'get_api_key', [[]])
                this.setState({googleApiKey: apiKey})
                const self = this
                const googleMapScript = addUrlApi(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&v=3.exp&signed_in=true&libraries=visualization`)
                if(googleMapScript){
                    googleMapScript.addEventListener('load', function () {
                        self.setState({googleScriptLoaded: true})
                        console.log('Google Map Script loaded')
                    })
                }else
                    self.setState({googleScriptLoaded: true})
            }
        }
        catch(err: any){
            console.log('Error', err);
            this.setState({loading: false});
        }
    }

    async loadTechState(workorder_id?: number, workorder?: any){
        if(!this.state.loggedIn){
            console.log('Trying to load tech state but loggedIn state var is false');
        }
        let odoo = OdooDatabase.getInstance()
        let can_start_labor = false
        let can_stop_labor = false
        let can_start_travel = false
        let can_start_travel_to_wo = false
        let can_stop_travel = false
        let can_start_diagnostic = false
        let can_stop_diagnostic = false
        this.setState({
            can_start_labor: can_start_labor,
            can_stop_labor: can_stop_labor,
            can_start_travel: can_start_travel,
            can_start_travel_to_wo: can_start_travel_to_wo,
            can_stop_travel: can_stop_travel,
            can_start_diagnostic: can_start_diagnostic,
            can_stop_diagnostic: can_stop_diagnostic,
            loadingTechState: true,
        })
        if(workorder_id){
            const data = await odoo.read('fieldservice.workorder', [workorder_id], ['state', 'am_i_assigned_today_to_wo'])
            workorder = data[0]
        }
        const tech_state = await odoo.execute_kw('fieldservice.tech.clock', 'get_tech_state_advanced', [[]])
        console.log("Tech state", tech_state, 'Workorder', workorder);
        if(workorder && workorder.state === 'assigned'){
            if(tech_state.state === 'free' && workorder.am_i_assigned_today_to_wo){
                can_start_labor = true;
                can_start_travel_to_wo = true;
                can_start_travel = true;
                can_start_diagnostic = true;
            }
            else if(tech_state.state === 'working_in_wo'){
                if(workorder.am_i_assigned_today_to_wo) {
                    can_start_labor = true;
                    can_start_diagnostic = true;
                }
                for(let i = 0; i < tech_state.wos.length; i++){
                    if(parseInt(tech_state.wos[i].wo_id) === workorder_id){
                        can_start_labor = false;
                        can_start_diagnostic = false;
                        if (tech_state.diagnostic)
                            can_stop_diagnostic = true;
                        else
                            can_stop_labor = true;
                        break;
                    }
                }
            }
            else if(tech_state.state === 'in_travel_to_wo'){
                can_stop_travel = true;
            }
            else if(tech_state.state === 'in_travel_to_wo' || tech_state.state === 'in_travel_to_shop' || tech_state.state === 'in_travel_to_other'){
                can_stop_travel = true
            }
        }
        else if(tech_state.state === 'free')
        {
            can_start_travel = true
        }
        else if(tech_state.state === 'in_travel_to_wo' || tech_state.state === 'in_travel_to_shop' || tech_state.state === 'in_travel_to_other'){
            can_stop_travel = true
        }
        this.setState({
            can_start_labor: can_start_labor,
            can_stop_labor: can_stop_labor,
            can_start_travel: can_start_travel,
            can_start_travel_to_wo: can_start_travel_to_wo,
            can_stop_travel: can_stop_travel,
            can_start_diagnostic: can_start_diagnostic,
            can_stop_diagnostic: can_stop_diagnostic,
            active_workorder_id: workorder_id,
            tech_state: tech_state,
            loadingTechState: false,
        })
    }

    logout(){
        OdooDatabase.logout();
        this.setState({
            loggedIn: false,
            userName: 'xxxxxxxxxxxxxxxxx',
            token: '',
        });
        deleteCookie('userInfo');

    }

    async checkUserTimeZone(){
        let odoo = OdooDatabase.getInstance()
        try{
            this.setState({loading: true});
            let today = new Date();
	        let tz_offset = today.getTimezoneOffset();
            let tz = await odoo.execute_kw("res.users", "get_user_time_zone_offset", [[]])
            console.log('TimeZone', tz_offset, tz)
            this.setState({
                timeZoneOffset: tz[0],
                timeZone: tz[1],
                showTimeZoneMismatch: tz_offset + tz[0] != 0
            })
            this.setState({loading: false});
        }
        catch(err: any){
            console.log('Error', err)
            this.setState({loading: false});
        }
    }

    async onLogin(userId: number, userName: string, userFullName: string, token: string){
        const userInfo = {
            userId: userId,
            userName: userName,
            userFullName: userFullName,
            token: token,
        };
        this.setState(userInfo);
        this.setState({
            loggedIn: true,
        });
        await this.loadData();
        setCookie('userInfo', JSON.stringify(userInfo));
    }

    async loadData() {
        await this.checkUserTimeZone();
        await this.loadConfiguration();
        await this.loadWOTypes();
        await this.loadTechState(this.state.active_wo_id);
        await this.loadReturnPartsCount();
    }

    async loadReturnPartsCount(){
        let odoo = OdooDatabase.getInstance();
        try{
            this.setState({loading: true});
            let data = await odoo.execute_kw('fieldservice.workorder', 'get_parts_waiting_to_return', [[]])
            this.setState({
                partsReturnsCount: data.length
            })
            this.setState({loading: false});
        }
        catch(err: any){
            console.log('Error', err)
            this.setState({loading: false});
        }
    }

    closeSearchDialog(){
        this.setState({searchModalOpen: false})
    }

    getDates(filter: any){
        const date_filter = filter.date_filter;
        let dates = {
            'date_start': this.state.WOFilter.date_start,
            'date_end': this.state.WOFilter.date_end
        }
        if(date_filter === 'today'){
            const today = new Date();
            dates ={
                date_start: formatStandardDate(today),
                date_end: formatStandardDate(today),
            };
        }else if(date_filter === 'tomorrow'){
            let date = new Date();
            date.setDate(date.getDate() + 1);
            dates = {
                date_start: formatStandardDate(date),
                date_end: formatStandardDate(date),
            };
        }else if(date_filter === 'this_week'){
            let date = new Date();
            let dayOfWeek = date.getDay();
            date.setDate(date.getDate() - dayOfWeek);
            let date2 = new Date();
            dayOfWeek = date2.getDay();
            date2.setDate(date2.getDate() + (6 - dayOfWeek));
            dates = {
                date_start: formatStandardDate(date),
                date_end: formatStandardDate(date2),
            };
        }else if(date_filter === 'next_week'){
            let date = new Date();
            let dayOfWeek = date.getDay();
            date.setDate(date.getDate() + 7 - dayOfWeek);
            let date2 = new Date();
            dayOfWeek = date2.getDay();
            date2.setDate(date2.getDate() + (13 - dayOfWeek));
            dates = {
                date_start: formatStandardDate(date),
                date_end: formatStandardDate(date2),
            };
        }else if(date_filter === 'this_month'){
            let date = new Date();
            date.setDate(1);
            let date2 = new Date();
            date2.setMonth(date2.getMonth() + 1);
            date2.setDate(1);
            date2.setDate(date.getDate() - 1);
            dates = {
                date_start: formatStandardDate(date),
                date_end: formatStandardDate(date2),
            };
        }else if(date_filter === 'none'){
            dates = {
                date_start: '',
                date_end: '',
            };
        }
        return dates;
    }

    doSearch(filter: any){
        this.setState({searchModalOpen: false, WOFilter: copyDict(filter)});
        if(!this.WOsPageActive){
            this.setState({redirect: true});
        }
    }

    onFilterUpdate(WOFilter: WOFilter){
        this.setState({WOFilter: WOFilter})
        this.setState({searchDialogId: 1 - this.state.searchDialogId})
    }

    onWOListMount(mounted: boolean){
        this.WOsPageActive = mounted;
    }

    onError(error: any): void {
    }

    componentDidUpdate() {
        if(this.state.redirect)  // Avoid recursive redirect
            this.setState({redirect: false})
        if(this.state.redirect_to_wo)
            this.setState({redirect_to_wo: 0})
    }

    async onTechStateChanged(workorder_id: number, workorder?: any){
        await this.loadTechState(workorder_id, workorder)
    }

    onWorkorderPageActive(workorder_id?: number){
        this.setState({active_wo_id: workorder_id})
        this.loadTechState(workorder_id)
    }

    handleCloseMessage(){
        this.setState({showMessage: false, message: '', messageAsDialog: false, messageIsError: false, showTimeZoneMismatch: false});
    }

    showErrorMessage(errorMsg: string, notModal?: boolean){
        this.setState({showMessage: true, messageIsError: true, message: errorMsg, messageAsDialog: !notModal});
    }

    async getDistanceToTruck(asset_id: number): Promise<number> {
        let odoo = OdooDatabase.getInstance();
        try{
            const distance = await odoo.execute_kw('fieldservice.asset', 'get_distance_to_user_truck', [[asset_id]]);
            return parseFloat(distance.distance);
        }
        catch(err: any){
        }
        return -1;
    }

    async startLabor(workorder_id: number, diagnostic?: boolean){
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            const context = {diagnosis: diagnostic}
            const data = await odoo.read('fieldservice.workorder', [workorder_id], ['asset_id'])
            const asset_id = data[0].asset_id[0]
            const distance = await this.getDistanceToTruck(asset_id);
            await odoo.execute_kw('fieldservice.workorder', 'start_labor', [[workorder_id, context]]);
            await this.onTechStateChanged(workorder_id)
            this.setState({updating: false});
            const minimum_distance_to_asset = parseFloat(this.state.configuration.minimum_distance_to_asset);
            if(distance > 0 && minimum_distance_to_asset > 0){
                if(distance > minimum_distance_to_asset){
                    this.showErrorMessage(`You are too far away from WO location. Distance: ${distance} miles.`)
                }
            }
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.showErrorMessage(err.faultString)
        }
    }

    async stopLabor(workorder_id: number){
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            await odoo.execute_kw('fieldservice.workorder', 'stop_labor', [[workorder_id]]);
            await this.onTechStateChanged(workorder_id)
            this.setState({updating: false, messageIsError: false});
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.showErrorMessage(err.faultString)
        }
    }

    async startTravelToWO(workorder_id: number){
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            console.log('startTravelToWO');
            await odoo.execute_kw('fieldservice.tech.clock', 'start_travel', [['tojob', workorder_id, false]]);
            await this.onTechStateChanged(workorder_id)
            this.setState({wo_component_key: 1 - this.state.wo_component_key});
            this.setState({updating: false, messageIsError: false});
            await this.verifyTravelAndPartsPicked(workorder_id);
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.showErrorMessage(err.faultString)
        }
    }

    async verifyTravelAndPartsPicked(workorder_id: number) {
        let odoo = OdooDatabase.getInstance();
        try{
            const data = await odoo.read('fieldservice.workorder', [workorder_id], ['my_tech_state', 'parts_already_picked'])
            if (data.length > 0) {
                if (data[0].my_tech_state === 'in_travel_to_wo' && !data[0].parts_already_picked) {
                    alert('Some parts have not been picked and you already started travel');
                }
            }
        }catch(err: any){
            console.log('Error', err);
        }
    }

    async stopTravel(workorder_id: number){
        console.log('Stop Travel');
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            await odoo.execute_kw('fieldservice.tech.clock', 'stop_travel', [[]]);
            await this.onTechStateChanged(workorder_id)
            this.setState({wo_component_key: 1 - this.state.wo_component_key});
            this.setState({updating: false, messageIsError: false});
            await this.verifyTravelAndPartsPicked(workorder_id);
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.showErrorMessage(err.faultString)
        }
    }

    async onStartTravel(destination: string, workorder_id: number, description: number){
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            await odoo.execute_kw('fieldservice.tech.clock', 'start_travel', [[destination, workorder_id, description]]);
            await this.onTechStateChanged(workorder_id)
            this.setState({wo_component_key: 1 - this.state.wo_component_key});
            this.setState({updating: false, messageIsError: false});
            if (destination === 'tojob')
                await this.verifyTravelAndPartsPicked(workorder_id);
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.showErrorMessage(err.faultString)
        }
    }

    onTimeZoneChanged(timeZoneOffset: number, timeZone: string){
        let today = new Date();
	    let tz_offset = today.getTimezoneOffset();
        this.setState({
            timeZoneOffset: timeZoneOffset,
            timeZone: timeZone,
            updateTimeZoneDialogOpen: false,
            showTimeZoneMismatch: tz_offset + timeZoneOffset != 0
        })
    }

    onRedirectToWO(workorder_id: number){
        this.setState({redirect_to_wo: workorder_id})
    }

    render(){
        const has_labor_open = this.state.can_stop_labor || this.state.can_stop_diagnostic;

        if(!this.state.loggedIn){
            const strUserInfo = getCookie('userInfo');
            if(strUserInfo){
                return (<LoginFromCookies
                    onLoginSucessFull={(userId, username, fullname, token) => {this.onLogin(userId, username, fullname, token);}}
                    onLoginFail={() => this.logout()}
                />)
            }else{
                return (<Login onLoginSucessFull={(userId, username, fullname, token) => {this.onLogin(userId, username, fullname, token);}}/>);
            }
        }
        else return (
            <>
            <Loading loading={this.state.updating}/>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'left'}} open={this.state.showTimeZoneMismatch}
                message="Time Zone Mismatch" className="snackbar_error"
                action={
                    <Button color="inherit" size="small" onClick={() => {this.setState({updateTimeZoneDialogOpen: true})}}>
                      Update Time Zone
                    </Button>
                  }
            />
            <Notification msg={this.state.message} onClose={this.handleCloseMessage} error={this.state.messageIsError} useDialog={this.state.messageAsDialog}/>
            <Navbar currentUser={this.state.userFullName} onLogout={() => this.logout()}
                    onSearch={() => {this.setState({searchModalOpen: true})}}
                    onWOsClick={this.onWOsClick}
                    currentUserId={this.state.userId}
                    token={this.state.token}
                    showSearchIcon={this.state.showSearchIcon}
                    partsReturnsCount={this.state.partsReturnsCount}
            >
                <SearchWODialog open={this.state.searchModalOpen}
                    onClose={() => {this.closeSearchDialog()}}
                    onOK={(filter) => this.doSearch(filter)}
                    woTypes={this.state.woTypes}
                    woTypesLoaded={this.state.woTypesLoaded}
                    WOFilter={this.state.WOFilter}
                    key={'searchDialog' + this.state.searchDialogId}
                />

                {(this.state.updateTimeZoneDialogOpen && this.state.timeZone) ? (<TimeZoneDlg open={this.state.updateTimeZoneDialogOpen}
                             onClose={() => {this.setState({updateTimeZoneDialogOpen: false})}}
                             currentTimeZone={this.state.timeZone}
                             onOK={this.onTimeZoneChanged}
                />) : (<></>)
                }

                {
                this.state.redirect_to_wo ?
                (<Redirect to={`/wo/${this.state.redirect_to_wo}`}/>):
                this.state.redirect ?
                (<Redirect to="/wos"/>):
                (
                this.state.error ?
                (<div>Error loading WOs</div>):
                (
                <>
                <Switch>
                    <Route exact path="/">
                        <Workorders
                            key={this.state.workordersKey}
                            loading={this.state.loading || !this.state.woTypesLoaded}
                            WOFilter={this.state.WOFilter}
                            woTypes={this.state.woTypes}
                            onFilterUpdate={this.onFilterUpdate}
                            onMount={(mounted: boolean) => {
                                this.onWOListMount(mounted);
                                this.setState({searchDialogId: 1 - this.state.searchDialogId})
                            }}
                            onError={this.onError}
                        />
                    </Route>
                    <Route exact path="/wos_list">
                        <Workorders
                            key={this.state.workordersKey}
                            loading={this.state.loading || !this.state.woTypesLoaded}
                            WOFilter={this.state.WOFilter}
                            woTypes={this.state.woTypes}
                            onFilterUpdate={this.onFilterUpdate}
                            onMount={(mounted: boolean) => {
                                this.onWOListMount(mounted);
                                if(mounted) {
                                    this.setState({WOFilter: initWOFilter()});
                                }
                                this.setState({searchDialogId: 1 - this.state.searchDialogId})
                            }}
                            onError={this.onError}
                        />
                    </Route>
                    <Route exact path="/wos">
                        <Workorders
                            loading={this.state.loading || !this.state.woTypesLoaded}
                            WOFilter={this.state.WOFilter}
                            woTypes={this.state.woTypes}
                            onFilterUpdate={this.onFilterUpdate}
                            onMount={(mounted: boolean) => this.onWOListMount(mounted)}
                            onError={this.onError}
                        />
                    </Route>
                    <Route exact path="/wo/:wo_id">
                        <WorkOrder
                            key={this.state.wo_component_key}
                            conf={this.state.configuration}
                            loading={!this.state.woTypesLoaded}
                            current_user_id={this.state.userId}
                            can_start_labor={this.state.can_start_labor}
                            can_stop_labor={this.state.can_stop_labor}
                            can_start_travel={this.state.can_start_travel}
                            can_start_travel_to_wo={this.state.can_start_travel_to_wo}
                            can_stop_travel={this.state.can_stop_travel}
                            can_start_diagnostic={this.state.can_start_diagnostic}
                            can_stop_diagnostic={this.state.can_stop_diagnostic}
                            onTechStateChanged={this.onTechStateChanged}
                            onMount={this.onWorkorderPageActive}
                            onError={this.showErrorMessage}
                            onStartLabor={this.startLabor}
                            onStopLabor={this.stopLabor}
                            onStartTravel={this.startTravelToWO}
                            onStopTravel={this.stopTravel}
                            />
                    </Route>
                    <Route exact path="/proc/:procedure_id">
                        <Tasks
                            onMount={this.onWorkorderPageActive}
                            onTechStateChanged={this.onTechStateChanged}
                            labor_open={has_labor_open}
                            onError={this.showErrorMessage}
                            onRedirectToWO={this.onRedirectToWO}
                        ></Tasks>
                    </Route>
                    <Route exact path="/map">
                        {this.state.googleScriptLoaded ? (<TechMap WOFilter={this.state.WOFilter}
                            onWOLinkClick={(wo_id: number) => {this.setState({redirect_to_wo: wo_id})}}
                            onError={this.showErrorMessage}
                        />):(<span>Loading Google Maps</span>)
                        }
                    </Route>
                    <Route exact path="/truck">
                        <Inventory onMount={() => {this.setState({showSearchIcon: false})}} onUnmount={() => {this.setState({showSearchIcon: true})}}/>
                    </Route>
                    <Route exact path="/return_parts">
                        <PartsToReturn onPartsReturnsCountChanged={this.onPartsReturnsCountChanged}/>
                    </Route>
                    <Route exact path="/help">
                        <Help/>
                    </Route>
                    <Route exact path="/wo_image/:image_id">
                        <WOImage/>
                    </Route>
                </Switch>
                </>
                )
                )
            }
            </Navbar>
            <Footer tech_state={this.state.tech_state} onTechStateChanged={this.onTechStateChanged}
                can_start_labor={this.state.can_start_labor}
                can_stop_labor={this.state.can_stop_labor}
                can_start_travel={this.state.can_start_travel}
                can_stop_travel={this.state.can_stop_travel}
                can_start_travel_to_wo={this.state.can_start_travel_to_wo}
                can_start_diagnostic={this.state.can_start_diagnostic}
                can_stop_diagnostic={this.state.can_stop_diagnostic}
                active_workorder_id={this.state.active_workorder_id}
                onStartLabor={this.startLabor}
                onStopLabor={this.stopLabor}
                onStartTravelToJob={this.startTravelToWO}
                onStopTravel={this.stopTravel}
                onStartTravel={this.onStartTravel}
                loading={this.state.loadingTechState}
            />
            </>
        )
    }
}