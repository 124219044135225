import {useState, useEffect, useRef} from 'react'
import Loading from '../LoadingComponent'
import OdooDatabase from '../../data/odoo'
import { formatDateTime } from '../../data/utils'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import Typography from '@material-ui/core/Typography'
import SignatureCanvas from "react-signature-canvas"
import Button from '@material-ui/core/Button'
import * as MdIcons from 'react-icons/md'
import '../css/signatures.css'

type SignatureWidgetProperties = {
    signature_id: number;
    signature_data: string;
    editable: boolean;
    onGetRef(signature_id: number, ref: any): void;
}

function SignatureWidget(props: SignatureWidgetProperties){
    const [editMode, setEditMode] = useState(!props.signature_data)
    const resetButton = props.editable ? (<div><Button color="primary" variant="contained" onClick={() => {setEditMode(true); if(sigRef) sigRef.clear(); }}>Reset</Button></div>) : (<></>)
    let sigRef: any;
    if(props.editable && editMode)
        return (<><SignatureCanvas canvasProps={{width: '420vw', height: 250, className: 'sigCanvas'}} penColor='#2C649F'
                    ref={(ref: any) => {sigRef = ref; props.onGetRef(props.signature_id, ref)}}
                />{resetButton}</>)
    else
        return (<>{props.signature_data ? (<img alt="" src={`data:image/png;base64,${props.signature_data}`}/>):(<></>)}{resetButton}</>)
}

type SignaturesSetProperties = {
    setNumber: number;
    startDate: string;
    endDate: string;
    timeType?: string;
    workorder_id: number;
    labor_open: boolean;
    diagnostic_open: boolean;
    onError(msg: string): void;
    onGetRef(signature_id: number, signature: any, ref: any): void;
    onDelete(signatureSet: number): void;
}

function SignaturesSet(props: SignaturesSetProperties){
    const [loading, setLoading] = useState(false)
    const [signatures, setSignatures] = useState<any[]>([])
    const mounted = useRef(true)

    const loadData = async () => {
        if (!mounted.current) return
        setLoading(true)
        try{
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.searchRead(
                'fieldservice.wo.signature',
                [['signature_set', '=', props.setNumber], ['wo_id', '=', props.workorder_id]],
                ['type', 'signature', 'tech_time_type'])
            if (!mounted.current) return
            setSignatures(data)
            setLoading(false)
        }catch(err: any){
            console.log('Error', err)
            setLoading(false)
        }
    }


    useEffect(() => {
        loadData()
        return () => {
            mounted.current = false
        }
    }, [])

    const signaturesImages = signatures.map((signature: any) => {
        const editable = (props.labor_open && (!signature.tech_time_type || signature.tech_time_type === 'wo_labor')) ||
                (props.diagnostic_open && (!signature.tech_time_type || signature.tech_time_type === 'diagnostic'));
        return (<div key={`sigimage_${signature.id}`} className="signature_item">
                <div className="sigtype">{signature.type[1]}</div>
                <SignatureWidget signature_data={signature.signature} editable={editable} signature_id={signature.id}
                    onGetRef={(signature_id: number, ref: any) => {
                        props.onGetRef(signature_id, signature, ref);
                    }}/>
                </div>)
    })

    let number = props.setNumber;
    if (number === 0) number++;
    const tech_time_type = props.timeType === 'wo_labor' ? "Labor" : props.timeType === 'diagnostic' ? "Diagnostic" : "";
    return (
        <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary
                expandIcon={<MdIcons.MdExpandMore/>}
                aria-controls="panel2a-content"
                id="attachments_header"
                >
                <Typography>{`SIGNATURES SET ${number}`}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                    <div className="data_block">
                        <div className="data_item">
                            <span className="data_label">Time In:</span>
                            <span className="data_data">{props.startDate ? formatDateTime(new Date(props.startDate)) : ''}</span>
                        </div>
                        <div className="data_item">
                            <span className="data_label">Time Out:</span>
                            <span className="data_data">{props.endDate ? formatDateTime(new Date(props.endDate)) : ''}</span>
                        </div>
                        <div className="data_item">
                            <span className="data_label">Type:</span>
                            <span className="data_data">{tech_time_type}</span>
                        </div>
                    </div>
                    <div className="signatures_list">
                    { loading ? (<Loading loading={true} fullWidth={false}/>) :
                        (<>{signaturesImages}<div className="wo_app_buttons"><Button variant="contained" onClick={()=>{props.onDelete(props.setNumber)}}>Delete Signatures Set</Button></div></>)
                    }
                    </div>
            </AccordionDetails>
        </Accordion>
    )
}

type SignaturesProperties = {
    workorder_id: number;
    onError(msg: string): void;
    labor_open: boolean;
    diagnostic_open: boolean;
}

export default function Signatures(props: SignaturesProperties){
    const [loading, setLoading] = useState(false)
    const [updating,setUpdating] = useState(false)
    const [signaturesSet, setSignaturesSet] = useState<any[]>([])
    const [saveAllowed, setSaveAllowed] = useState(false)
    const [setsKey, setSetsKey] = useState(0)
    const mounted = useRef(true)
    let sigRefs: any[] = []

    const loadData = async () => {
        if (!mounted.current) return
        setLoading(true)
        try{
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.execute_kw('fieldservice.workorder', 'get_wo_signatures_sets', [[props.workorder_id]])
            if (!mounted.current) return
            setSignaturesSet(data)
            setLoading(false)
        }
        catch(err: any){
            console.log('Error', err)
            setLoading(false)
        }
    }

    useEffect(() => {
        loadData()
        return () => {
            mounted.current = false
        }
    }, [])

    if(loading){
        return (<Loading loading={true} fullWidth={false}/>)
    }

    const deleteSignaturesSet = async (signatureSet: number) => {
        const odoo = OdooDatabase.getInstance()
        setUpdating(true)
        try{
            const ids = await odoo.search('fieldservice.wo.signature', [['wo_id', '=', props.workorder_id], ['signature_set', '=', signatureSet]])
            await odoo.execute_kw('fieldservice.wo.signature', 'unlink', [[ids]])
            setUpdating(false)
            loadData()
        }
        catch(err: any){
            setUpdating(false)
            console.log('Error', err)
            props.onError(err.faultString)
        }
    }

    const onGetRef = (signature_id: number, signature: any, ref: any):void => {
        if(ref){
            sigRefs.push({signature_id: signature_id, signature: signature, ref: ref})
            setSaveAllowed(true)
        }
    }

    const onCreateNewSet = async () => {
        for(let sigRef of sigRefs){
            const signature = sigRef.signature;
            const editable = (props.labor_open && (!signature.tech_time_type || signature.tech_time_type === 'wo_labor')) ||
                (props.diagnostic_open && (!signature.tech_time_type || signature.tech_time_type === 'diagnostic'));
            if(editable && sigRef.ref.isEmpty()){
                alert('Can\'t create new signatures set if there are empty signatures')
                return
            }
        }
        const odoo = OdooDatabase.getInstance()
        try{
            setUpdating(true)
            const context = {
                tech_time_type: props.labor_open ? 'wo_labor' : props.diagnostic_open ? 'diagnostic' : false,
            }
            await odoo.execute_kw('fieldservice.workorder', 'create_new_signature_set', [[[props.workorder_id], context]])
            setSetsKey(1 - setsKey)
            setSaveAllowed(false)
            setUpdating(false)
            loadData()
        }
        catch(err: any){
            console.log('Error', err)
            setUpdating(false)
            props.onError(err.faultString)
        }
    }

    const onSave = async () => {
        for(let sigRef of sigRefs){
            const signature = sigRef.signature;
            const editable = (props.labor_open && (!signature.tech_time_type || signature.tech_time_type === 'wo_labor')) ||
                (props.diagnostic_open && (!signature.tech_time_type || signature.tech_time_type === 'diagnostic'));
            if(editable && sigRef.ref.isEmpty()){
                alert('There are empty signatures')
                return
            }
        }
        const odoo = OdooDatabase.getInstance()
        try{
            setUpdating(true)
            for(let sigRef of sigRefs){
                if(!sigRef.ref.isEmpty()){
                    const data = sigRef.ref.toDataURL().split(',')[1]
                    await odoo.update('fieldservice.wo.signature', sigRef.signature_id, {signature: data})
                }
            }
            setSetsKey(1 - setsKey)
            setSaveAllowed(false)
            setUpdating(false)
        }
        catch(err: any){
            console.log('Error', err)
            setUpdating(false)
            props.onError(err.faultString)
        }
    }

    const signaturesSetItems = signaturesSet.map((signatureSet: any) => {
        return (<SignaturesSet key={`signature_set_${signatureSet.signature_set}_${props.labor_open}_${setsKey}`}
            setNumber={signatureSet.signature_set} startDate={signatureSet.start_time}
            endDate={signatureSet.end_time} workorder_id={props.workorder_id} onError={props.onError}
            timeType={signatureSet.tech_time_type}
            labor_open={props.labor_open}
            diagnostic_open={props.diagnostic_open}
            onGetRef={onGetRef}
            onDelete={deleteSignaturesSet}
            />)
    })

    return (<>
    <div className="flexrows">{signaturesSetItems}
    </div>
    <Loading loading={updating}/>
    <div className="wo_app_buttons">
        <Button
            variant="contained"
            color="primary"
            onClick={onCreateNewSet}
            disabled={!props.labor_open && !props.diagnostic_open}
        >
            Create New Signature Set
        </Button>
        <Button
            variant="contained"
            color="primary"
            onClick={onSave}
            disabled={!saveAllowed}
        >
            Save
        </Button>
    </div>
    </>)
}