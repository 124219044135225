import React, { Component } from 'react';
import Loading from '../LoadingComponent';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextEditDialog from '../editors/TextEditDialog';
import AddPartDialog from './AddPartDlgComponent';
import OdooDatabase from '../../data/odoo';
import * as AiIcons from "react-icons/ai";
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import '../css/parts.css';


type PartListProperties = {
    workorder_id: number;
    labor_open?: boolean;
    onError(errorMsg: string): void;
    wo_state: string;
}

export default class PartList extends Component<PartListProperties, any>{
    searchTimeout: any = null;

    constructor(props: any){
        super(props);
        this.state = {
            parts: [],
            filteredParts: [],
            loading: false,
            updating: false,
            error: false,
            all_parts_picked: false,
            has_unused_parts: false,
            reasonDialogOpen: false,
            addPartDialogOpen: false,
            searchText: ''
        }
        this.markPartsPicked = this.markPartsPicked.bind(this);
        this.markPartsUsed = this.markPartsUsed.bind(this);
        this.delUnusued = this.delUnusued.bind(this);
        this.delUnusedConfirm = this.delUnusedConfirm.bind(this);
        this.onCloseDialog = this.onCloseDialog.bind(this);
        this.onAddPart = this.onAddPart.bind(this);
        this.searchTextChange = this.searchTextChange.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }

    componentDidMount(){
        this.loadParts();
    }

    filterParts(parts: any[], search: string) {
        const searchLowered = search.toLocaleLowerCase();
        const filteredParts = parts.filter((part: any) => {
            return part.description.toLocaleLowerCase().indexOf(searchLowered) >= 0 ||
                (part.product_id && part.product_id[1].toLocaleLowerCase().indexOf(searchLowered) >= 0) ||
                (part.uom && part.uom[1].toLocaleLowerCase().indexOf(searchLowered) >= 0)
        });
        this.setState({filteredParts: filteredParts});
    }

    clearSearch() {
        if(this.searchTimeout){
            clearTimeout(this.searchTimeout);
        }
        this.setState({searchText: ''});
        this.filterParts(this.state.parts, '');
    }

    searchTextChange(event: any){
        const searchText = event.target.value;
        this.setState({searchText: searchText})
        if(this.searchTimeout){
            clearTimeout(this.searchTimeout);
        }
        let self = this;
        this.searchTimeout = setTimeout(() => {
            console.log('Searching');
            self.filterParts(this.state.parts, searchText);
        }, 500)
    }

    async loadParts(){
        this.setState({loading: true});
        let odoo = OdooDatabase.getInstance();
        try{
            let parts = await odoo.execute_kw('fieldservice.wo.parts', 'get_wo_parts', [[this.props.workorder_id, 0, 10000]]);
            parts.items.sort((a: any, b: any) => {return a.part_picked - b.part_picked})
            let all_parts_picked = true;
            let unusuedParts = 0;
            parts.items.forEach((part: any) => {
                part.checked = false;
                if(!part.part_picked)
                    all_parts_picked = false;
                if(!part.part_used)
                    unusuedParts++;
            })
            this.setState({loading: false, error: false, parts: parts.items});
            this.setState({
                all_parts_picked: all_parts_picked,
                has_unused_parts: unusuedParts > 0
            })
            this.filterParts(parts.items, this.state.searchText);
        }catch(err: any){
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    async markPartsPicked(){
        let parts_ids: number[] = [];
        this.state.parts.forEach((part: any) => {
            if(part.checked)
                parts_ids.push(part.id)
        });
        if(parts_ids.length == 0){
            this.props.onError("Select parts to pick");
            return
        }
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            await odoo.execute_kw('fieldservice.workorder', 'mark_parts_already_picked', [[[this.props.workorder_id], parts_ids]]);
            this.setState({updating: false});
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.props.onError(err.faultString);
            return
        }
        this.loadParts();
    }

    async markPartsUsed(){
        let parts_ids: number[] = [];
        this.state.parts.forEach((part: any) => {
            if(part.checked)
                parts_ids.push(part.id)
        });
        if(parts_ids.length == 0){
            this.props.onError("Select parts to mark as used");
            return
        }
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            await odoo.execute_kw('fieldservice.workorder', 'mark_parts_used', [[parts_ids]]);
            this.setState({updating: false});
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.props.onError(err.faultString);
            return
        }
        this.loadParts();
    }

    delUnusued(){
        let parts_ids: number[] = [];
        let parts_unpicked = false;
        this.state.parts.forEach((part: any) => {
            if(!parts_unpicked && part.checked){
                if(!part.part_picked){
                    parts_unpicked = true;
                }else{
                    parts_ids.push(part.id);
                }
            }
        });
        if(parts_unpicked){
            this.props.onError("Unpicked parts can't be removed.");
            return;
        }
        if(parts_ids.length == 0){
            this.props.onError("Select unused parts to delete");
            return
        }
        this.setState({reasonDialogOpen: true});
    }

    async delUnusedConfirm(reason: string){
        if(!reason){
            this.props.onError("You must specify a reason to remove unused parts");
            return;
        }
        this.setState({reasonDialogOpen: false});
        let parts_ids: number[] = [];
        this.state.parts.forEach((part: any) => {
            if(part.checked)
                parts_ids.push(part.id)
        });
        if(parts_ids.length == 0){
            this.props.onError("Select unused parts to delete");
            return
        }
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            await odoo.execute_kw('fieldservice.workorder', 'remove_selected_unused_parts', [[[this.props.workorder_id], parts_ids, reason]]);
            this.setState({updating: false});
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.props.onError(err.faultString);
            return
        }
        this.loadParts();
    }

    onCloseDialog(){
        this.setState({reasonDialogOpen: false, addPartDialogOpen: false});
    }

    async onAddPart(product: number, quantity: number): Promise<boolean>{
        this.setState({updating: true});
        let odoo = OdooDatabase.getInstance();
        try{
            await odoo.execute_kw(
                'fieldservice.workorder', 'add_new_part_from_truck',
                [[this.props.workorder_id, product, quantity, {from_tech_app: true}]]
            );
            this.setState({updating: false});
            this.setState({reasonDialogOpen: false, addPartDialogOpen: false});
        }catch(err: any){
            console.log('Error', err);
            this.setState({updating: false});
            this.props.onError(err.faultString);
            return false
        }
        this.loadParts()
        return true
    }


    onCheckedChanged(partId: number){
        this.state.parts.forEach((part: any) => {
            if(part.id == partId)
                part.checked = !part.checked;
        })
        this.setState({parts: this.state.parts});
    }

    render(){
        if(this.state.loading)
            return (<Loading loading={true} fullWidth={false}/>);
        else if(this.state.error)
            return (<span>Error loading Parts list</span>);
        else{
            const rows = this.state.filteredParts.map((part: any) => {
                const state = part.part_used ? "Used" : (part.part_picked ? "Picked" : "");
                let part_name = '';
                if(part.reference)
                    part_name = `[${part.reference}]`;
                if(part.product_id)
                    part_name = `${part_name} ${part.product_id[1]}`;
                return (
                    <tr key={`part_row_${part.id}`}>
                        <td className="data_table_checkbox"><Checkbox size="small" checked={part.checked} onChange={() => {this.onCheckedChanged(part.id)}}/></td>
                        <td>{part_name}</td>
                        <td>{part.description}</td>
                        <td>{part.actual_quantity}</td>
                        <td>{part.uom ? part.uom[1] : ''}</td>
                        <td>{state}</td>
                        <td>{part.location ? part.location[1] : ''}</td>
                    </tr>
                )
            });
            return (
                <>
                    {this.state.updating ? (<Loading loading={true}/>):(<></>)}
                    <TextEditDialog
                        open={this.state.reasonDialogOpen}
                        title={'Reason to remove parts'}
                        initialValue={''}
                        onOK={this.delUnusedConfirm}
                        onClose={this.onCloseDialog}
                        okText={'Remove unused'}
                        clearOnOK={true}
                    />
                    {
                    this.state.addPartDialogOpen ?
                    (<AddPartDialog
                        open={this.state.addPartDialogOpen}
                        onClose={this.onCloseDialog}
                        onOK={this.onAddPart}
                        workorder_id={this.props.workorder_id}
                    />) : (<></>)
                    }
                    <div className="parts_search_box">
                        <TextField type="text" autoComplete="off" label="Search"
                            value={this.state.searchText}
                            onChange={this.searchTextChange}
                            InputProps={{
                                endAdornment: this.state.searchText !== '' ? (<InputAdornment position="end">
                                    <IconButton onClick={this.clearSearch}><AiIcons.AiFillCloseCircle/></IconButton></InputAdornment>) : (<></>)
                            }}
                        >
                        </TextField>
                    </div>
                    <table className="data_table full_width parts_table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>Part Id</th>
                                <th><span>Description</span></th>
                                <th>Qty</th>
                                <th>Uom</th>
                                <th>State</th>
                                <th>Location</th>
                            </tr>
                        </thead>
                        <tbody>{rows}</tbody>
                    </table>
                    <div className="wo_app_buttons">
                        {
                        this.state.all_parts_picked ? (<></>):
                        (<Button
                            variant="contained"
                            color="primary"
                            onClick={this.markPartsPicked}
                        >Mark Picked</Button>)
                        }
                        {
                        this.props.labor_open && this.state.has_unused_parts ?
                        (<Button
                            variant="contained"
                            color="primary"
                            onClick={this.markPartsUsed}
                        >Mark Used</Button>) : (<></>)
                        }
                        {this.props.wo_state === 'assigned' ? (<Button
                            variant="contained"
                            color="primary"
                            onClick={() => {this.setState({addPartDialogOpen: true})}}
                        >Add Part</Button>) : (<></>)}
                        {
                        this.props.labor_open && this.state.has_unused_parts ?
                        (<Button
                            variant="contained"
                            onClick={this.delUnusued}
                        >Delete Unused</Button>) : (<></>)
                        }
                    </div>
                </>
            );
        }
    }
}