import React, { Component } from 'react';
import * as AiIcons from "react-icons/ai";
import NativeSelect from '@material-ui/core/NativeSelect';
import Loading from '../LoadingComponent';

import './editors.css';

type SelectEditProperties = {
    recordId: number;
    value: any;
    editable?: boolean;
    disallowNull?: boolean;
    workingFullScreen?: boolean;
    alwaysEditMode?: boolean;
    selectItems: {
        value: any;
        label: string;
        className?: string;
    }[];
    recordUpdate(id: number, value: any): Promise<boolean>,
}

export default class SelectEdit extends Component<SelectEditProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            value: this.props.value,
            editMode: this.props.alwaysEditMode,
            updating: false,
        }
        this.setEditMode = this.setEditMode.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    setEditMode(){
        this.setState({editMode: true});
    }

    async onChange(event: any){
        this.setState({updating: true});
        const new_value = event.target.value;
        const result = await this.props.recordUpdate(this.props.recordId, new_value);
        if(result){
            this.setState({
                editMode: this.props.alwaysEditMode,
                value: new_value,
                updating: false
            });
        }
        else
            this.setState({updating: false});
    }

    render(){
        if(this.state.updating){
            return (<Loading loading={this.state.updating} fullWidth={this.props.workingFullScreen}/>);
        }
        let text = '[None]';
        let className = 'editor_empty';
        let selectClassName = '';
        for(let i = 0; i < this.props.selectItems.length; i++){
            if(this.props.selectItems[i].value == this.state.value){
                text = this.props.selectItems[i].label;
                className = '';
                if(this.props.selectItems[i].className){
                    className = String(this.props.selectItems[i].className);
                    selectClassName = className;
                }
                break;
            }
        }
        if(this.props.editable){
            if(this.state.editMode){
                const options = this.props.selectItems.map((item: any) => {
                    return (<option key={item.value} value={item.value} className={item.className ? item.className : ''}>{item.label}</option>);
                });
                return (<NativeSelect
                    value={this.state.value}
                    onChange={this.onChange}
                    className={selectClassName}
                  >
                  {this.props.disallowNull ? (<></>) : (<option value="">[None]</option>)}
                  {options}
                  </NativeSelect>);
            }else{
                return (<span className={className} onClick={this.setEditMode}>{text}  <AiIcons.AiFillCaretDown/></span>)
            }
        }else{
            return (<span className={className}>{text}</span>)
        }
    }
}