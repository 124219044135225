import React from 'react';
import { Component } from 'react';
import TechAppDialog from '../TechAppDialog';
import OdooDatabase from '../../data/odoo';
import Loading from '../LoadingComponent';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormGroup } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@mui/material/Autocomplete';

type AddPartDlgProperties = {
    open: boolean;
    workorder_id: number;
    onOK(product: number, quantity: number): Promise<boolean>;
    onClose(): void;
}

export default class AddPartDialog extends Component<AddPartDlgProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            products: [],
            loading: false,
            error: false,
            selected_product: null,
            quantity: 0,
            product_error: false,
            quantity_error: false,
            quantity_error_msg: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.onOK = this.onOK.bind(this);
        this.onCancel = this.onCancel.bind(this);
        
    }

    componentDidMount(){
        this.loadProducts();
    }


    async loadProducts(){
        this.setState({loading: true});
        let odoo = OdooDatabase.getInstance();
        try{
            let products = await odoo.execute_kw('fieldservice.workorder', 'get_products_in_tech_truck', [[false]]);
            products = products.filter((product: any) => {return product.available_on_hand > 0});
            this.setState({loading: false, error: false, products: products});
        }catch(err: any){
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    handleInputChange(event: any){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
          });
    }

    validateProduct(){
        this.setState({product_error: false});
        if(this.state.selected_product == null){
            this.setState({product_error: true});
            return false;
        }
        return true;
    }

    validateQty(){
        this.setState({quantity_error: false, quantity_error_msg: ''});
        let qty = parseFloat(this.state.quantity);
        if(isNaN(qty)){
            this.setState({quantity_error: true, quantity_error_msg: 'Quantity must be a valid number'});
            return false;
        }else if(qty <= 0.0001){
            this.setState({quantity_error: true, quantity_error_msg: 'Quantity must be greater than zero'});
            return false;
        }
        else{
            if(this.state.selected_product){
                for(let i = 0; i < this.state.products.length; i++){
                    if(this.state.products[i].id == this.state.selected_product.id){
                        if(this.state.products[i].available < qty){
                            this.setState({quantity_error: true, quantity_error_msg: 'Quantity must be lesser than or equal to available'});
                            return false;
                        }else{
                            return true;
                        }
                    }
                }
            }
        }
        return false;
    }

    async onOK(){
        const validProduct = this.validateProduct()
        const validQty = this.validateQty()
        const valid = validProduct && validQty
        if(valid){
            this.props.onOK(this.state.selected_product.id, parseFloat(this.state.quantity))
        }
    }

    onCancel(){
        this.setState({selected_product: null, quantity: 0, product_error: false, quantity_error: false});
        this.props.onClose();
    }

    render(){
        let items = [];
        if(!this.state.error && !this.state.loading){
            items = this.state.products.map((product: any) => {
                let desc = product.description
                if(desc.length > 25){
                    desc = desc.substring(0, 25) + "..."
                }
                let ref = product.reference
                if(ref){
                    ref = `[${ref}]`
                }
                const item = `${ref} ${product.name} - ${desc} (${product.available_on_hand} ${product.uom})`
                return {label: item, id: product.id, qty_text: `${product.available_on_hand} ${product.uom}`}
            })
        }
        return (
        <TechAppDialog 
            open={this.props.open}
            title="Add Part"
            onClose={this.props.onClose}
            buttons={[{text: 'OK', onClick: this.onOK, primary: true}, {text: 'Cancel', onClick: this.onCancel, primary: true}]}
        >
            {
                this.state.error ? (<span>Error loading parts</span>) : 
                (
                this.state.loading ?
                (<Loading fullWidth={false}/>):
                (
                    <form className="dialog-form">
                        <FormGroup row className="boxed_group">
                        <FormControl className="full_width dialog_form_control" error={this.state.product_error}>
                            <Autocomplete
                                id="add_part"
                                options={items}
                                value={this.state.selected_product}
                                onChange={(event: any, newValue: any | null) => {
                                    this.setState({
                                        selected_product: newValue
                                    })
                                  }}
                                renderInput={(params: any) => <TextField {...params} label="Part" />}
                                />
                            {this.state.product_error ? (<FormHelperText>Product must be selected</FormHelperText>):(<></>)}
                        </FormControl>
                        <FormControl className="full_width dialog_form_control">
                            <TextField 
                                name="quantity"
                                label={`Quantity (Max: ${this.state.selected_product ? this.state.selected_product.qty_text : ''})`}
                                value={this.state.quantity}
                                onChange={this.handleInputChange}
                                error={this.state.quantity_error}
                                helperText={this.state.quantity_error ? this.state.quantity_error_msg : ''}
                            />
                        </FormControl>
                        </FormGroup>
                    </form>
                )
                )
            }
        </TechAppDialog>)
    }
}