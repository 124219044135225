import React from 'react';
import TechAppDialog from './../TechAppDialog';
import Map from '../MapComponent';
import {useState} from 'react'

type LocationEditDialogProps = {
    title: string
    latitude: number;
    longitude: number;
    onOK(latitude: number, longitude: number): void;
    onClose(): void;
}

export default function LocationEditDialog(props: LocationEditDialogProps) {
    const [latitude, setLatitude] = useState(props.latitude);
    const [longitude, setLongitude] = useState(props.longitude);

    const onOK = () => {
        props.onOK(latitude, longitude);
    }

    const onMapInit = (map: any) => {
        let image = ''
        if (process.env.REACT_APP_BASE_URL)
            image += process.env.REACT_APP_BASE_URL
        image += 'location.png'
        let marker = new google.maps.Marker({
            position: { lat: latitude, lng: longitude },
            map: map,
            icon: {
                url: image,
                labelOrigin: new google.maps.Point(17, -10),
            },
            draggable:false, animation: google.maps.Animation.DROP,
            label: { fontWeight: 'bold', fontSize: '14px', text: `${latitude}, ${longitude}` }
        });

        map.addListener("click", (mapsMouseEvent: any) => {
            marker.setPosition(mapsMouseEvent.latLng);
            const lat = mapsMouseEvent.latLng.lat();
            const lng = mapsMouseEvent.latLng.lng();
            marker.setLabel({ fontWeight: 'bold', fontSize: '14px', text: `${lat}, ${lng}` });
            setLatitude(lat);
            setLongitude(lng);
        });
    }

    return (
        <TechAppDialog
                title={props.title}
                open={true}
                fullWidth={true}
                onClose={props.onClose}
                buttons={[
                    {text: 'OK', onClick: onOK, primary: true},
                    {text: 'Cancel', onClick: props.onClose}]}
            >
                <>
                    <div>Coordinates: {latitude}, {longitude}</div>
                    <Map
                        centerLatitude={latitude}
                        centerLongitude={longitude}
                        onMapInit={onMapInit}
                        showCurrentLocation={true}
                        containerClass='edit_location_map'
                    />

                </>
            </TechAppDialog>
    )
}

