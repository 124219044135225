import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import * as MdIcons from "react-icons/md"
import OdooDatabase from '../data/odoo'
import Loading from './LoadingComponent'
import TechAppDialog from './TechAppDialog'
import * as FcIcons from 'react-icons/fc'
import sanitizeHtml from 'sanitize-html'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import TaskNote from './TaskNoteComponent'
import UploadDialog from './wosubcomponents/UploadDlg'
import { UploadedFile } from './wosubcomponents/UploadDlg'
import './css/tasks.css'

type TasksParams = {
    procedure_id: string;
}

const taskRequiresNotes = (task: any) => {
    const trimmed_desc = task.description ? task.description.trim() : ''
    return (task.caution || task.failure) && !trimmed_desc
}

const isTaskComplete = (task: any): boolean => {
    const has_something_marked = task.actiontaken || task.failure || task.caution
    const requires_reading = task.hastaskreading && (!task.reading || task.reading.trim() === '')
    const requires_attachment = task.requires_attachment && !task.attachmentName;
    return (task.n_a || (has_something_marked && !requires_reading && !requires_attachment)) && !taskRequiresNotes(task)
}

type TaskProperties = {
    task: any;
    onDescriptionClick(task_id: number, task_description: string): void;
    onTaskChanged(task: any): void;
    onOpenTaskNotes(task: any): void;
    onOpenUploadDialog(task: any): void;
}

function Task(props: TaskProperties){
    const [actionPass, setActionPass] = useState(props.task.actiontaken)
    const [failure, setFailure] = useState(props.task.failure)
    const [caution, setCaution] = useState(props.task.caution)
    const [n_a, setNA] = useState(props.task.n_a)
    const [reading, setReading] = useState(props.task.reading)


    const openTaskNotes = (event: any) => {
        event.preventDefault()
        props.onOpenTaskNotes(props.task)
    }

    const openUploadDialog = (event: any) => {
        event.preventDefault()
        props.onOpenUploadDialog(props.task)
    }

    const onSetPass = (event: any) => {
        const checked = event.target.checked
        if(checked){
            setActionPass(true)
            setFailure(false)
            setCaution(false)
            setNA(false)
        }else{
            setActionPass(false)
        }
        props.task.actiontaken = checked
        props.task.failure = false
        props.task.caution = false
        props.task.n_a = false
        props.onTaskChanged(props.task)
    }

    const onSetFailure = (event: any) => {
        const checked = event.target.checked
        if(checked){
            setFailure(true)
            setActionPass(false)
            setCaution(false)
            setNA(false)
        }else{
            setFailure(false)
        }
        props.task.actiontaken = false
        props.task.failure = checked
        props.task.caution = false
        props.task.n_a = false
        props.onTaskChanged(props.task)
    }

    const onSetCaution = (event: any) => {
        const checked = event.target.checked
        if(checked){
            setFailure(false)
            setActionPass(false)
            setCaution(true)
            setNA(false)
        }else{
            setCaution(false)
        }
        props.task.actiontaken = false
        props.task.failure = false
        props.task.caution = checked
        props.task.n_a = false
        props.onTaskChanged(props.task)
    }

    const onReadingChanged = (event: any) => {
        const reading = event.target.value
        setReading(reading)
        props.task.reading = reading
        props.onTaskChanged(props.task)
    }

    const onSetNA = (event: any) => {
        const checked = event.target.checked
        if(checked){
            setFailure(false)
            setActionPass(false)
            setCaution(false)
            setNA(true)
        }else{
            setNA(false)
        }
        props.task.actiontaken = false
        props.task.failure = false
        props.task.caution = false
        props.task.n_a = checked
        props.onTaskChanged(props.task)
    }

    const taskLabelClass = isTaskComplete(props.task) ? "task_complete" : props.task.highlighted ? "task_highlighted" : ""

    if (props.task.istaskheader){
        return (<div className="task_box task_box_header" id={`task_box_${props.task.id}`}>
            <span className='task_header'>{props.task.wotask_id[1]}</span>
        </div>);
    }

    return (<div className="task_box" id={`task_box_${props.task.id}`}>
        <div className={'task_label ' + taskLabelClass} id={`task_label_${props.task.id}`}><div>{props.task.wotask_id[1]}</div>
        {
            props.task.master_description?(<IconButton onClick={() => {props.onDescriptionClick(props.task.wotask_id[0], props.task.master_description)}}><FcIcons.FcInfo/></IconButton>):(<></>)
        }
       </div>
        <div className="task_checkboxes">
            <FormControlLabel
                    control={<Checkbox name="pass"
                    color="primary"
                    onChange={onSetPass}
                    checked={actionPass}
                    />}
                    className={actionPass?'task_pass':''}
                    label="Pass"
                />
            <FormControlLabel
                    control={<Checkbox name="caution"
                    color="primary"
                    onChange={onSetCaution}
                    checked={caution}
                    />}
                    className={caution?'task_caution':''}
                    label="Caution"
                />
            <FormControlLabel
                    control={<Checkbox name="failure"
                    color="primary"
                    onChange={onSetFailure}
                    checked={failure}
                    />}
                    className={failure?'task_failure':''}
                    label="Failure"
                />
            <FormControlLabel
                    control={<Checkbox name="n_a"
                    color="primary"
                    onChange={onSetNA}
                    checked={n_a}
                    />}
                    className={n_a?'task_na':''}
                    label="N/A"
                />
        </div>
        {
        props.task.hastaskreading?(<div className="task_reading">
            <TextField type={props.task.reading_type === 'd' ? 'date' : 'text' }
                value={reading ? reading : ''}
                onChange={onReadingChanged}
                placeholder={props.task.reading_type === 'n' ? 'Enter Numeric Value (0.00)' : props.task.reading_type === 'c' ? 'Enter Value (Letters, Numeric or both)' : ''}
                fullWidth
            />
        </div>):(<></>)
        }
        <div className="task_view_buttons">
            <div>
                {props.task.requires_attachment ?
                    (
                        <div className="task_attachment">
                            <span className="tech_app_link" onClick={openUploadDialog}>Upload</span>
                            {props.task.attachmentName ? (<span>{props.task.attachmentName}</span>): (<></>)}
                        </div>
                    ):(<></>)
                }
            </div>
            <div className="tasks_notes">
                {taskRequiresNotes(props.task) ? (<div className="requires_tech_note_indicator">
                    <div className="requires_tech_note_indicator_text">Enter Notes</div>
                    <div className="arrow_pointing_right">
                        <svg height="16" width="32">
                            <polygon points="2,4,16,4,16,1,30,8,16,15,16,12,2,12"/>
                        </svg>
                    </div>
                </div>) : (<></>)}
                <span className="tech_app_link" onClick={openTaskNotes}>View / Edit</span>
            </div>
        </div>
    </div>)
}

type TasksProperties = {
    onMount(workorder_id: number): void;
    onTechStateChanged(workorder_id: number, workorder: any): void;
    onError(msg: string): void;
    labor_open: boolean;
    onRedirectToWO(workorder_id: number): void;
}

export default function Tasks(props: TasksProperties){
    let params = useParams<TasksParams>();
    const [procedure, setProcedure] = useState<any>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [tasks, setTasks] = useState<any[]>([])
    const mountedRef = useRef(true)
    const [descriptionDialogOpen, setDescriptionDialogOpen] = useState(false)
    const [taskDescriptionDlgText, setTaskDescriptionDlgText] = useState('')
    const [showOnlyIncompleteTasks, setShowOnlyIncompleteTasks] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [taskNoteDlgOpen, setTaskNoteDlgOpen] = useState(false)
    const [activeTask, setActiveTask] = useState<any>(null)
    const [uploadDlgOpen, setUploadDlgOpen] = useState(false)
    const [tasksContainerKey, setTasksContainerKey] = useState(0)

    const openTaskNotes = (task: any) => {
        setActiveTask(task)
        setTaskNoteDlgOpen(true)
    }

    const openUploadDialog = (task: any) => {
        setActiveTask(task)
        setUploadDlgOpen(true);
    }

    const closeTaskNotes = () => {
        setActiveTask(null)
        setTaskNoteDlgOpen(false)
    }

    const closeUploadDialog = () => {
        setActiveTask(null);
        setUploadDlgOpen(false);
    }

    const openTaskDescriptionLink = (task_id: number, task_description: string) => {
        setDescriptionDialogOpen(true)
        setTaskDescriptionDlgText(task_description)
    }

    const closeTaskDescriptionDialog = () => {
        setDescriptionDialogOpen(false)
    }

    const processTasksData = async (tasks: any[]) => {
        let task_ids:any[] = []
        for(let task of tasks){
            if(task.attachment_id){
                task.attachmentName = task.attachment_id[1];
            }
            if(task.wotask_id && task_ids.indexOf(task.wotask_id[0])){
                task_ids.push(task.wotask_id[0])
            }
        }
        if(task_ids.length > 0){
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.read('fieldservice.task', task_ids, ['description'])
            for(let task of tasks){
                for(let datum of data){
                    if(task.wotask_id[0] === datum.id && datum.description){
                        task.master_description = datum.description
                    }
                }
            }
        }
    }

    const hightLightIncompleteTasks = (tasks: any[]) => {
        let highlight = false
        let requires_update = false
        for(let i = tasks.length- 1; i >= 0; i--){
            const task = tasks[i]
            const taskComplete = isTaskComplete(task)
            if(!requires_update)
                requires_update = tasks[i].highlighted !== (highlight && !taskComplete)
            tasks[i].highlighted = highlight && !taskComplete
            if(!highlight)
                highlight = taskComplete
        }
        return requires_update
    }

    const onFilesOpen = async (files: UploadedFile[]) => {
        setUploadDlgOpen(false);
        for(let i = 0; i < files.length; i++) {
            const dataUri = files[i].data;
            const name = files[i].name;
            activeTask.attachmentName = name;
            activeTask.attachmentData = dataUri;
            onTaskChanged(activeTask);
        }
        setActiveTask(null);
        setTasksContainerKey(1 - tasksContainerKey);

    }

    const loadTasks = async() => {
        const odoo = OdooDatabase.getInstance()
        setError(false)
        try{
            const data = await odoo.searchRead('fieldservice.workorder.group.task', [['procedure_id', '=', parseInt(params.procedure_id)]],
                ['wotask_id', 'actiontaken', 'failure', 'caution', 'n_a', 'istaskheader',
                 'hastaskreading', 'reading_type', 'reading', 'description', 'failure_desc',
                 'requires_attachment', 'attachment_id'
                ],
                {'order': 'sequence'})
            if (!mountedRef.current) return
            console.log('Tasks', data);
            await processTasksData(data)
            for(let task of data){task.failure_id = task.failure_desc ? task.failure_desc[0] : false}
            hightLightIncompleteTasks(data)
            setTasks(data)
        }
        catch(err: any){
            console.log('Error', err)
            setError(true)
        }
    }

    const loadProcedure = async(): Promise<number> => {
        const odoo = OdooDatabase.getInstance()

        try{
            const data = await odoo.read('fieldservice.workorder.proc.rel', [parseInt(params.procedure_id)], ['name', 'workorder_id'])
            if (!mountedRef.current) return 0
            setProcedure(data[0])
            return data[0].workorder_id[0]
        }
        catch(err: any){
            console.log('Error', err)
        }
        return 0
    }

    const loadData = async () => {
        setLoading(true)
        const workorder_id = await loadProcedure()
        await loadTasks()
        setLoading(false)
        if(workorder_id)
            props.onMount(workorder_id)
    }

    const refresh = async () => {
        if(procedure && procedure.workorder_id)
            props.onTechStateChanged(procedure.workorder_id[0], null)
        setLoading(true)
        loadTasks()
        setLoading(false)
    }

    useEffect(() => {
        loadData()
        return () => {
            props.onMount(0)
            mountedRef.current = false
        }
    }, [])

    const hideShowIncompleteTasks = (event: any) => {
        const checked = event.target.checked
        setShowOnlyIncompleteTasks(checked)
        for(let task of tasks){
            let element = document.getElementById(`task_box_${task.id}`)
            if(!element) continue
            if(checked){
                element.style.display = isTaskComplete(task) ? 'none' : 'block'
            }else{
                element.style.display = 'block'
            }
        }
    }

    const onTaskChanged = (task: any) => {
        task.modified = true
        if(hightLightIncompleteTasks(tasks)){
            for(let task of tasks){
                let element = document.getElementById(`task_label_${task.id}`)
                if(element)
                    if(task.highlighted)
                        element.classList.add('task_highlighted')
                    else
                        element?.classList.remove('task_highlighted')
            }
        }
    }

    const saveAttachment = async(task: any) => {
        const data = task.attachmentData.split(',')[1]
        const odoo = OdooDatabase.getInstance()
        if(task.attachment_id){
            try{
                await odoo.delete('ir.attachment', task.attachment_id[0])
            }
            catch(err: any){
                //Ignore delete error
                console.log('Error', err);
            }
        }
        const attachment_id = await odoo.create('ir.attachment', {
            name: task.attachmentName,
            datas: data,
            mimetype: 'application/pdf',
            file_type: 'application/pdf',
            res_model: 'fieldservice.workorder.group.task',
            res_id: task.id,
        })
        return attachment_id;
    }

    const saveTasks = async () => {
        setUpdating(true)
        const odoo = OdooDatabase.getInstance()
        try{
            for(let task of tasks){
                if(task.istaskheader)
                    continue
                if(!task.modified)
                    continue
                let attachmentId = false;
                if(task.attachmentData){
                    attachmentId = await saveAttachment(task);
                }
                let taskData = {
                    'actiontaken': task.actiontaken,
                    'failure': task.failure,
                    'caution': task.caution,
                    'n_a': task.n_a,
                    'reading': task.reading,
                    'description': task.description,
                    'failure_desc': task.failure_id,
                    'attachment_id': attachmentId,
                }
                if(!task.failure_id){
                    task.failure_id = await odoo.create('fieldservice.wo.task.failure', {'name': ''})
                    taskData.failure_desc = task.failure_id
                }

                await odoo.update('fieldservice.workorder.group.task', [task.id], taskData)
                if(Array.isArray(task.problems)){
                    let data = {
                        'problems': [[6, 0, task.problems]],
                        'wo_solution_tasks': [[5]]
                    }
                    await odoo.update('fieldservice.wo.task.failure', task.failure_id, data)
                }
                if(Array.isArray(task.solutions)){
                    let sols = []
                    for(let sol of task.solutions){
                        sols.push([0, 0, {'name': sol.name[0], 'tech_est_time': sol.tech_est_time}])
                    }
                    console.log('Sols', sols)
                    await odoo.update('fieldservice.wo.task.failure', task.failure_id, {'wo_solution_tasks': sols})
                }
                task.modified = false
                task.dataLoaded = false
            }
            setUpdating(false)
            if(procedure && procedure.workorder_id)
                props.onRedirectToWO(procedure.workorder_id[0]);
        }
        catch(err: any){
            console.log('Error', err)
            setUpdating(false)
            props.onError(err.faultString)
        }
    }

    const tasksItems = tasks.map((task: any) => {
        return (<Task key={`task_${task.id}`} task={task}
            onDescriptionClick={openTaskDescriptionLink} onTaskChanged={onTaskChanged}
            onOpenTaskNotes={openTaskNotes}
            onOpenUploadDialog={openUploadDialog}
            />)
    })
    return (
        <div className="main_component_container">
        <UploadDialog open={uploadDlgOpen} title="Upload document" onClose={closeUploadDialog} extensions=".pdf" onFilesOpen={onFilesOpen}/>
        <Loading loading={updating}/>
        {
            taskNoteDlgOpen ? (<TaskNote open={true} task={activeTask} onClose={closeTaskNotes} onError={props.onError}/>) : (<></>)
        }
        {
            descriptionDialogOpen ? (
                <TechAppDialog open={true} onClose={closeTaskDescriptionDialog} title="Task Description">
                    <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(taskDescriptionDlgText) }} />
                </TechAppDialog>) : (<></>)
        }
        <div className="componentheader wo_componentheader">
            {
            procedure?(<Breadcrumbs aria-label="breadcrumb" className="wo_breadcrumb">
            <Link to="/wos" color="inherit" href="/">
                Work Orders
            </Link>
            <Link to={`/wo/${procedure.workorder_id[0]}`} color="inherit">
            {'WO-'+('' + procedure.workorder_id[0]).padStart(5, '0')}
            </Link>
            <Typography color="textPrimary">{procedure.name}</Typography>
            </Breadcrumbs>) : (<></>)
            }
            <IconButton onClick={refresh}><MdIcons.MdRefresh className="refresh_icon"/></IconButton>
        </div>
        <div className="incomplete_checkbox">
            <FormControlLabel
                    control={<Checkbox
                    color="primary"
                    onChange={hideShowIncompleteTasks}
                    checked={showOnlyIncompleteTasks}
                    />}
                    label="Show Only Incomplete Tasks"
                />
        </div>
        <div className="tasks_buttons">
        <Button
            variant="contained"
            color="primary"
            onClick={saveTasks}
            disabled={!props.labor_open}
        >
            Save
        </Button>
        </div>
        {
        loading ? (<Loading loading={true} fullWidth={false}/>) :
        error ? (<span>Error Loading tasks</span>) :
        (<div key={`tasksKey_${tasksContainerKey}`}>{tasksItems}</div>)
        }
        <div className="tasks_buttons">
        <Button
            variant="contained"
            color="primary"
            onClick={saveTasks}
            disabled={!props.labor_open}
        >
            Save
        </Button>
        </div>
        <div className="incomplete_checkbox">
            <FormControlLabel
                    control={<Checkbox
                    color="primary"
                    onChange={hideShowIncompleteTasks}
                    checked={showOnlyIncompleteTasks}
                    />}
                    label="Show Only Incomplete Tasks"
                />
        </div>
        </div>)
}