import React, { Component } from 'react';
import * as AiIcons from "react-icons/ai";
import NativeSelect from '@material-ui/core/NativeSelect';
import OdooDatabase from '../../data/odoo';
import Loading from '../LoadingComponent';

type SelectEditProperties = {
    updateId?: number;
    value: any;
    updateModel?: string;
    updateField: string;
    editable?: boolean;
    disallowNull?: boolean;
    workingFullScreen?: boolean;
    alwaysEditMode?: boolean;
    model: string;
    domain?: any[];
    onError(errorMsg: string): void;
}

export default class SelectEditFromModel extends Component<SelectEditProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            value: this.props.value,
            editMode: this.props.alwaysEditMode,
            updating: false,
            loading: false,
            selectItems: [],
        }
        this.setEditMode = this.setEditMode.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    componentDidMount(){
        this.loadItems()
    }

    async loadItems(){
        this.setState({loading: true});
        let odoo = OdooDatabase.getInstance();
        try{
            let data = await odoo.searchRead(this.props.model, this.props.domain ? this.props.domain : [], ['name']);
            this.setState({loading: false, error: false, selectItems: data});
        }catch(err: any){
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    setEditMode(){
        this.setState({editMode: true});
    }

    async onChange(event: any){
        const new_value = event.target.value;
        if(this.props.updateModel && this.props.updateField && this.props.updateId){
            this.setState({updating: true});
            let odoo = OdooDatabase.getInstance();
            try{
                await odoo.update(this.props.updateModel, [this.props.updateId], {[this.props.updateField]:new_value});
                this.setState({updating: false});
            }catch(err: any){
                console.log('Error', err);
                this.setState({updating: false});
                this.props.onError(err.faultString);
                return
            }
        }
        this.setState({value: new_value, editMode: false})
    }

    render(){
        if(this.state.updating){
            return (<Loading loading={true} fullWidth={this.props.workingFullScreen}/>);
        } else if(this.state.loading){
            return (<Loading loading={true} fullWidth={false}/>);
        }
        let text = '[None]';
        let className = 'editor_empty';
        for(let i = 0; i < this.state.selectItems.length; i++){
            if(this.state.selectItems[i].id == this.state.value){
                text = this.state.selectItems[i].name;
                className = ''
                break;
            }
        }
        if(this.props.editable){
            if(this.state.editMode){
                const options = this.state.selectItems.map((item: any) => {
                    return (<option value={item.id} key={`editoption_${item.id}`}>{item.name}</option>);
                });
                return (<NativeSelect
                    value={this.state.value}
                    onChange={this.onChange}
                  >
                  {this.props.disallowNull ? (<></>) : (<option value="">[None]</option>)}
                  {options}
                  </NativeSelect>);
            }else{
                return (<span className={className} onClick={this.setEditMode}>{text}  <AiIcons.AiFillCaretDown/></span>)
            }
        }else{
            return (<span className={className}>{text}</span>)
        }
    }
}