import { Component } from 'react';
import OdooDatabase from '../data/odoo';
import Loading from './LoadingComponent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import './css/login.css';


type LoginProperties = {
    onLoginSucessFull(userId: number, userName: string, fullname: string, token: string): void;
}
export default class Login extends Component<LoginProperties, any>{
    constructor(props: any){
        super(props);
        this.state = {
            username: '',
            password: '',
            feedbackText: '',
            loading: false,
            showPassword: false,
        }
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleInputChange(event: any){
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.id;
        this.setState({
          [name]: value
        });
    }

    timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    async handleSubmit(event: any){
        event.preventDefault();
        this.setState({loading: true});
        try{
            let odoo = await OdooDatabase.connect(this.state.username, this.state.password);
            if(odoo){
                let today = new Date();
	            let tz_offset = today.getTimezoneOffset()
                const user = await odoo.searchRead('res.users', {login: this.state.username}, ['name', 'partner_id']);
                const userName = user.length > 0 ? user[0].name : '';
                const userId = user.length > 0 ? user[0].id : '';
                const token = await odoo.execute_kw('res.users', 'get_tech_token', [[]])
                OdooDatabase.setToken(token.token);
                this.props.onLoginSucessFull(userId, this.state.username, userName, token.token);
            }else{
                this.setState({loading: false});
                this.setState({feedbackText: 'Invalid username or password'});
            }
        }
        catch(err: any){
            console.log('Error', err);
            this.setState({loading: false});
            this.setState({feedbackText: 'Error connecting to database'});
        }
    }

    handleClickShowPassword(){
        this.setState({showPassword: !this.state.showPassword});
    };
    
    handleMouseDownPassword(event: React.MouseEvent<HTMLButtonElement>){
        event.preventDefault();
    };

    render(){
        
        return (<>
            <Loading loading={this.state.loading}/>
            <div className="login-wrapper">
                <div><img src={ process.env.REACT_APP_BASE_URL +  'logo2.png'} width="150px" alt="Zabatt Logo"/></div>
                <h4>Please Log In To Continue</h4>
                <form noValidate onSubmit={this.handleSubmit}>
                    <div>
                        <TextField id="username" label="User" 
                            onChange={this.handleInputChange} 
                            InputProps={{
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <BiIcons.BiUserCircle />
                                  </InputAdornment>
                                ),
                             }}
                        />
                    </div>
                    <div>
                        <TextField id="password" label="Password" 
                            type={this.state.showPassword ? "text" : "password"}
                            onChange={this.handleInputChange} 
                            helperText={this.state.feedbackText}
                            autoComplete="off"
                            InputProps={{
                                endAdornment:(
                                 <InputAdornment position="start" 
                                    className="password_icon"
                                 >
                                    { this.state.showPassword ? 
                                        <AiIcons.AiOutlineEye onClick={() => this.handleClickShowPassword()} onMouseDown={() => this.handleMouseDownPassword}/> :
                                        <AiIcons.AiOutlineEyeInvisible onClick={() => this.handleClickShowPassword()} onMouseDown={() => this.handleMouseDownPassword}/>}
                                  </InputAdornment>
                                )
                             }}
                        />
                    </div>
                    <div>
                        <Button type="submit" color="primary" variant="contained" className="submit_button">
                            Login
                        </Button>
                    </div>
                </form>
            </div>
            </>
        );
    }
}