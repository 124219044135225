import React, { Component } from 'react';
import OdooDatabase from '../data/odoo';
import Loading from './LoadingComponent';
import Checkbox from '@material-ui/core/Checkbox';
import PaginationComponent from './PaginationComponent';
import { Link } from 'react-router-dom'

type SelectItem = {
    key: string,
    label: string
}

export type TableElement = {
    field: string,
    header: string,
    type?: string,
    select_items?: SelectItem[],
    link?: string,
    onRender?(field: string, data: any): JSX.Element,
    headerClass?: string,
    hidden?: boolean
}

type TableListProperties = {
    model?: string,
    domain?: any[],
    fields?: string[],
    className?: string,
    elements: TableElement[],
    checkBoxes?: boolean,
    pagination?: boolean,
    pageSize?: number,
    loadMethod?: string,
    loadParameters?: any[],
    onDataLoaded?(data: any[]): void,
    loadingBlocked?: boolean,
    order?: string,
    hideHead?: boolean,
    initialData?: any[],
    showInitialData?: boolean,
}

export default class TableList extends Component<TableListProperties, any>{
    mounted: boolean = false
    constructor(props: any){
        super(props);
        this.state = {
            data: [],
            totalData: 0,
            loading: false,
            error: false,
            page: 0
        }
        this.onPageChanged = this.onPageChanged.bind(this)
        this.mounted = true
    }

    componentDidMount(){
        if(this.props.showInitialData && this.props.initialData){
            this.setState({'data': this.props.initialData})
        }
        else
            this.loadData();
    }

    componentWillUnmount(){
        this.mounted = false
    }

    async loadWithMethod(){
        this.setState({loading: true});
        let odoo = OdooDatabase.getInstance();
        try{
            let data = await odoo.execute_kw(this.props.model, this.props.loadMethod, [this.props.loadParameters])
            if(!this.mounted) return
            if(this.props.checkBoxes){
                data.forEach((item: any) => {
                    item._checked = false
                })
            }
            if(this.props.onDataLoaded)
                this.props.onDataLoaded(data)
            this.setState({loading: false, error: false, data: data});
        }catch(err: any){
            if(!this.mounted) return
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    async loadData(){
        if(this.props.loadMethod){
            this.loadWithMethod()
        }
        else
            this.loadWithRead()
    }

    async loadWithRead(){
        let pageSize = (this.props.pagination && this.props.pageSize) ? this.props.pageSize : 10
        let total = 0
        const opts = this.props.pagination ?
            {
                limit: pageSize,
                offset: this.state.page * pageSize,
                order: this.props.order ? this.props.order : ''
            } : {}
        this.setState({loading: true});
        let odoo = OdooDatabase.getInstance();
        try{
            let data = await odoo.searchRead(this.props.model, this.props.domain, this.props.fields,
                opts);
            if(!this.mounted) return
            if(this.props.pagination){
                total = await odoo.execute_kw(this.props.model, 'search_count', [[this.props.domain]])
                if(!this.mounted) return
                this.setState({totalData: total})
            }
            if(this.props.checkBoxes){
                data.forEach((item: any) => {
                    item._checked = false
                })
            }
            if(this.props.onDataLoaded)
                this.props.onDataLoaded(data)
            this.setState({loading: false, error: false, data: data});
        }catch(err: any){
            if(!this.mounted) return
            console.log('Error', err);
            this.setState({loading: false, error: true});
        }
    }

    applyTimeZone(date: Date){
        let offset = date.getTimezoneOffset() * 60 * 1000
        date.setTime(date.getTime() - offset)
        return date
    }

    renderSelect(element: TableElement, datum: any){
        let text = ''
        if(element.select_items){
            for(let item of element.select_items){
                if(item.key == datum[element.field]){
                    text = item.label
                    break
                }
            }
        }
        return (<span>{text}</span>)
    }

    onCheckedChanged(itemId: number){
        this.state.data.forEach((item: any) => {
            if(item.id == itemId){
                item._checked = !item._checked;
            }
        })
        this.setState({data: this.state.data});
    }

    renderLink(element: TableElement, datum: any){
        let link = element.link ? element.link : '__id__'
        if(element.link)
            link = link.replace(/__id__/g, datum.id)
        return (<Link to={link}>{datum[element.field]}</Link>)
    }

    renderItem(element: TableElement, datum: any){
        if(element.onRender)
            return element.onRender(element.field, datum)
        if(element.type == 'many2one')
            return (<span>{datum[element.field] ? datum[element.field][1] : ''}</span>)
        if(element.type == 'date')
            return (<span>{datum[element.field] ? (this.applyTimeZone(new Date(datum[element.field])).toLocaleString('en-US', { hour12: true })) : ''}</span>)
        if(element.type == 'onlydate')
            return (<span>{datum[element.field] ? (this.applyTimeZone(new Date(datum[element.field])).toLocaleDateString('en-US', { hour12: true })) : ''}</span>)
        if(element.type == 'select' && element.select_items)
            return this.renderSelect(element, datum)
        if(element.link)
            return this.renderLink(element, datum)
        return (<span>{datum[element.field]}</span>)
    }

    onPageChanged(pageIndex: number){
        this.setState({page: pageIndex})
        setTimeout(() => {
            this.loadData();
        }, 0);
    }

    render(){
        if(this.state.error){
            return (<span>Error loading</span>)
        }
        else{
            const headers = this.props.elements.filter((element: TableElement) => {return !element.hidden}).map((element: TableElement) => {
                return (<th key={`header_${element.field}`} className={element.headerClass ? element.headerClass : ''}><span>{element.header}</span></th>)
            })
            let self = this
            const rows = this.state.data.map((datum: any) => {
                const cells = this.props.elements.filter((element: TableElement) => {return !element.hidden}).map((element: TableElement) => {
                    const cell = self.renderItem(element, datum)
                    return (<td key={`${element.field}_${datum.id}`}>{cell}</td>)
                })
                return (<tr key={`row_${datum.id}`}>
                    {this.props.checkBoxes ? (<td className="data_table_checkbox"><Checkbox size="small" checked={datum._checked}
                        onChange={() => {this.onCheckedChanged(datum.id)}}/></td>):(<></>)}
                    {cells}
                    </tr>)
            })
            if(!this.props.loadingBlocked && this.state.loading){
                return (<Loading loading={true} fullWidth={false}/>)
            }
            let columnCount = this.props.elements.length
            if(this.props.checkBoxes)
                columnCount += 1
            return (<>
                <Loading loading={this.state.loading}/>
                <table className={`data_table full_width ${this.props.className || ''}`}>
                    {
                    this.props.hideHead?
                    (<></>):
                    (<thead>
                        <tr>
                            {
                            this.props.checkBoxes ?
                            (<th className="checkbox_row"></th>):(<></>)
                            }
                            {headers}
                        </tr>
                    </thead>
                    )
                    }
                    <tbody>{rows}
                    </tbody>
                    {
                    this.props.pagination ? (
                    <tfoot>
                        <tr>
                        <td colSpan={columnCount}>
                            <PaginationComponent
                                pageIndex={this.state.page}
                                itemsCount={this.state.totalData}
                                onPageChanged={this.onPageChanged}
                                pageSize={this.props.pageSize}
                            />
                        </td>
                        </tr>
                    </tfoot>) : (<></>)
                    }
                </table>
                </>)
        }

    }
}