import { useParams } from 'react-router-dom'
import { useState, useEffect, useRef } from 'react';
import Loading from './LoadingComponent'
import OdooDatabase from '../data/odoo'
import './css/image.css'

type WOImageParams = {
    image_id?: string;
    model?: string;
}

export default function WOImage(props: WOImageParams){
    const params = useParams<WOImageParams>();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [imageData, setImageData] = useState('');
    const mountedRef = useRef(true)

    const loadImage = async () => {
        const image_Id = props.image_id ? props.image_id : params.image_id ? params.image_id : false;
        if (!image_Id) {
            setError(true);
            return;
        }
        const modelToLoad = props.model ? props.model : "fieldservice.image"
        setLoading(true);
        try{
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.execute_kw(modelToLoad, 'read', [[[parseInt(image_Id)], ['image']]])
            if (!mountedRef.current) return
            setImageData(data[0].image);
            setLoading(false)
        }
        catch(err: any){
            setLoading(false)
            setError(true);
            console.log('Error', err)
        }
    }

    useEffect(() => {
        loadImage();
        return () => {
            mountedRef.current = false
        }
    }, []);

    return loading ?
        (<Loading loading={true}/>) :
        error ? (<span>Error loading</span>) :
        (<img alt="" src={`data:image/png;base64,${imageData}`} className="wo_image"/>);
}