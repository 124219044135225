import React from 'react'
import { useEffect, useState, useRef } from 'react'
import TableList from './TableListViewComponent';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import './css/inventory.css'
import { InputAdornment } from '@material-ui/core';
import OdooDatabase from '../data/odoo'
import Loading from './LoadingComponent'
import IconButton from '@material-ui/core/IconButton';
import * as MdIcons from "react-icons/md";
import * as AiIcons from "react-icons/ai"

type PartsToReturnProperties = {
    onMount?(): void;
    onUnmount?(): void;
    onPartsReturnsCountChanged?(partsCount: number): void;
}

function PartsToReturn(props: PartsToReturnProperties){
    const [searchText, setSearchText] = useState('')
    const [tableKey, setTableKey] = useState(0)
    const [timeOut, setTimeOut] = useState(0)
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState<any[]>([])
    const [loading, setLoading] = useState(false)
    const mountedRef = useRef(true)

    const filterData = (fulldata: any[]) => {
        const newData = fulldata.filter((obj: any) => {
            return (obj.part && obj.part.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0) ||
                (obj.origin && obj.origin.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0) ||
                (obj.picking && obj.picking.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0) ||
                (obj.destination && obj.destination.toLocaleLowerCase().indexOf(searchText.toLocaleLowerCase()) >= 0)
        })
        return newData
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            let newData = filterData(data)
            setFilteredData(newData)
            setTableKey(1 - tableKey)
            setTimeOut(500)
        }, timeOut)
    
        return () => clearTimeout(delayDebounceFn)
      }, [searchText])

    const onSearchTextChange = (event: any) => {
        setSearchText(event.target.value)
    }

    const processInventory = (data: any[]) => {
        data.forEach((item: any) => {
            let reference = item.reference
            if(reference)
                reference = `[${reference}]`
            if(item.part_desc){
                item.part = `${reference} ${item.part_name} - ${item.part_desc}`
            } else {
                item.part = `${reference} ${item.part_name}`
            }
            item.qty = `${item.qty} ${item.uom}`;
        })
     }

    const loadData = async () => {
        const odoo = OdooDatabase.getInstance()
        try{
            setLoading(true)
            let data = await odoo.execute_kw('fieldservice.workorder', 'get_parts_waiting_to_return', [[]])
            if (!mountedRef.current) return
            console.log(data);
            processInventory(data)
            setData(data)
            const newData = filterData(data)
            setFilteredData(newData)
            setTableKey(1 - tableKey)
            setLoading(false)
            if(props.onPartsReturnsCountChanged){
                props.onPartsReturnsCountChanged(data.length)
            }
        }catch(err: any){
            console.log('Error', err);
            if (!mountedRef.current) return
            setLoading(false)
        }
    }

    useEffect(() => {
        if(props.onMount){
            props.onMount()
        }
        loadData()
        return () => {
            mountedRef.current = false
            if(props.onUnmount)
                props.onUnmount()
        }
    }, [])

    return (
        <Paper elevation={3} className="woitem" >
            <div className="search_box">
                <TextField type="text" variant="filled" autoComplete="off" label="Search"
                    value={searchText}
                    onChange={onSearchTextChange}
                    InputProps={{
                        endAdornment: searchText != '' ? (<InputAdornment position="end"><IconButton onClick={()=>{setSearchText('')}}><AiIcons.AiFillCloseCircle/></IconButton></InputAdornment>) : (<></>)
                    }}
                >
                </TextField>
                <IconButton onClick={()=>{loadData()}}><MdIcons.MdRefresh className="refresh_icon" size={30}/></IconButton>
            </div>
            {loading ?
                (<Loading loading={true} fullWidth={false}/>):
                (<>
                <TableList 
                    key={tableKey}
                    showInitialData={true}
                    initialData={filteredData}
                    elements={[{field:'part', type: 'string', header: 'Part'},
                               {field: 'destination', type: 'string', header: 'Destination'}, 
                               {field: 'qty', type: 'string', header: 'Qty'},
                               {field: 'origin', type: 'string', header: 'Origin'},
                               {field: 'picking', type: 'string', header: 'Picking'}
                            ]}>
                </TableList>
                </>)
            }
        </Paper>)
}

export default PartsToReturn;