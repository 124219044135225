import React from 'react'
import TechAppDialog from '../TechAppDialog'
import TableList from '../TableListViewComponent'
import {TableElement} from '../TableListViewComponent'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import {useState} from 'react'
import '../css/selectdlg.css'

type SelectItemsDialogProperties = {
    model: string;
    open: boolean;
    onOK(items: number[]): void;
    onClose(): void;
    itemName: string;
    fields: string[];
    selectedIds: number[];
    elements: TableElement[];
    domain?: any[];
}

export default function SelectItemsDialog(props: SelectItemsDialogProperties){
    const [searchText, setSearchText] = useState<string>('')
    const [data, setData] = useState<any[]>([])
    const [tableKey, setTableKey] = useState(0)
    const doSearch = () => {
        setTableKey(1 - tableKey)
    }

    const onDataLoaded = (data: any) => {
        setData(data)
    }

    const onOK = () => {
        let selectedIds = []
        for(let item of data){
            if(item._checked)
                selectedIds.push(item.id)
        }
        if(selectedIds.length > 0){
            props.onOK(selectedIds)
            props.onClose()
        }
    }
    let domain = [['id','not in', props.selectedIds], ['name', 'ilike', searchText]]
    if(props.domain)
        domain = domain.concat(props.domain)
    return (
        <TechAppDialog open={props.open} onClose={props.onClose} title={`Add ${props.itemName}`}
            buttons={[
                {text: 'Add', onClick: onOK, primary: true},
                {text: 'Close', onClick:props.onClose}]}
        >
        <div className="align_center">
            <TextField fullWidth label="Search" value={searchText} onChange={(event: any) => {setSearchText(event.target.value)}}/>
            <Button autoFocus onClick={doSearch} variant="contained" color={'primary'}>Search</Button>
            <TableList key={`table_${tableKey}`}
                className="select_dlg_table"
                model={props.model}
                fields={props.fields}
                elements={props.elements}
                checkBoxes={true}
                pagination={true}
                pageSize={4}
                domain={domain}
                onDataLoaded={onDataLoaded}
            />
        </div>
        </TechAppDialog>
    )
}