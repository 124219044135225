import React, { useState, useEffect, useRef } from 'react';
import Loading from '../LoadingComponent'
import OdooDatabase from '../../data/odoo'
import Button from '@material-ui/core/Button'
import TableList from '../TableListViewComponent'
import ConfirmRequestPO from './ConfirmRequestPODialog';
import Notification from '../NotificationComponent';
import UploadDialog from './UploadDlg'
import TechAppDialog from '../TechAppDialog'
import WOImage from '../WOImageComponent'
import Images from './ImagesComponent';

type RequestedPOProperties = {
    workorder_id: number;
    onError(msg: string): void;
}

export type UploadedFile = {
    name: string;
    data: string;
}

export default function RequestedPO(props: RequestedPOProperties){
    const [loading, setLoading] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [allowRequestPO, setAllowRequestPO] = useState(false)
    const [requestPOReasonDlgOpen, setRequestPOReasonDlgOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [attachmentsKey, setAttachmentsKey] = useState(0)
    const [currentPO, setCurrentPO] = useState(0);
    const mountedRef = useRef(true)
    const [currentImageId, setCurrentImageId] = useState('');
    const [imageWindowOpen, setImageWindowOpen] = useState(false);
    const [imagesDialogOpen, setImagesDialogOpen] = useState(false);

    const loadData = async () => {
        setLoading(true)
        try{
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.read('fieldservice.workorder', [props.workorder_id], ['allow_tech_request_po'])
            if(data.length > 0)
                setAllowRequestPO(data[0].allow_tech_request_po)
            setLoading(false)
        }
        catch(err: any){
            console.log('Error', err)
            setLoading(false)
        }
    }

    const openImage = (image_id: string) => {
        setCurrentImageId(image_id);
        setImageWindowOpen(true);
    }

    useEffect(() => {
        loadData()
        return () => {
            mountedRef.current = false
        }
    }, [])

    const requestPO = async (reason: string) => {
        setUpdating(true)
        try{
            const odoo = OdooDatabase.getInstance()
            await odoo.execute_kw('fieldservice.workorder', 'create_request_po', [[[props.workorder_id], reason]])
            setUpdating(false)
            loadData()
        }
        catch(err: any){
            console.log('Error', err)
            setUpdating(false);
            setErrorMessage(err.faultString);
        }
    }

    const onRequestPOConfirmed = async (reason: string) => {
        setRequestPOReasonDlgOpen(false);
        await requestPO(reason);
    };

    const openRequestPOReasonDialog = async () =>{
        setUpdating(true);
        try{
            const odoo = OdooDatabase.getInstance()
            const data = await odoo.read('fieldservice.workorder', [props.workorder_id], ['my_tech_state']);
            if(data.length > 0 && data[0]['my_tech_state'] == 'working_in_wo'){
                setRequestPOReasonDlgOpen(true);
            }else{
                setErrorMessage("Tech must open labor on WO to create a Tech Requested PO");
            }
            setUpdating(false)
        }
        catch(err: any){
            console.log('Error', err)
            setUpdating(false);
            setErrorMessage(err.faultString);
        }
    }

    const isJPGOrPNG = (data: string) => {
        let byteString = atob(data.split(',')[1])
	    return (byteString.length > 2 && byteString.charCodeAt(0) === 0xFF && byteString.charCodeAt(1) === 0xD8) ||
            (byteString.length > 8 &&
             byteString.charCodeAt(0) === 0x89 && byteString.charCodeAt(1) === 0x50 &&
             byteString.charCodeAt(2) === 0x4E && byteString.charCodeAt(3) === 0x47 &&
             byteString.charCodeAt(4) === 0x0D && byteString.charCodeAt(5) === 0x0A &&
             byteString.charCodeAt(6) === 0x1A && byteString.charCodeAt(7) === 0x0A
            )
    }

    const onFileSave = async(data: any) => {
        const odoo = OdooDatabase.getInstance()
        await odoo.execute_kw('purchase.order', 'add_image', [[currentPO, data]])
    }

    const renderImage = (field: string, datum: any) => {
        return (
            <>
            <div><button onClick={() => {setCurrentPO(datum.id); setImagesDialogOpen(true)}}>Attachments ({datum.images_count})</button></div>
            </>
        );
    }

    return (

        <>
        {loading ? (<Loading loading={true} fullWidth={false}/>):
        (<>
        {updating ? (<Loading loading={true}/>) : (<></>)}
        {errorMessage ? (<Notification msg={errorMessage} error={true} permanent={true} onClose={()=>{setErrorMessage("")}} useDialog={true}/>):(<></>)}
        {
            imageWindowOpen ? (<TechAppDialog open={true} title="" onClose={() => {setImageWindowOpen(false)}}><WOImage image_id={currentImageId}></WOImage></TechAppDialog>) : (<></>)
        }
        {
            imagesDialogOpen ? (
                <TechAppDialog open={true} title="" onClose={() => {setImagesDialogOpen(false)}}>
                    <Images po_id={currentPO} onChange={()=>{setAttachmentsKey(1 - attachmentsKey)}} onFileSave={onFileSave} onError={props.onError} hideDescription={true}/>
                </TechAppDialog>
            ) : (<></>)
        }
        <ConfirmRequestPO open={requestPOReasonDlgOpen} onClose={()=>{setRequestPOReasonDlgOpen(false)}} onOK={onRequestPOConfirmed}/>
        <TableList key={`attachments_list_${attachmentsKey}`}
            model="fieldservice.workorder"
            loadMethod="get_request_pos"
            loadParameters={[[props.workorder_id]]}
            elements={[{field:'name', type: 'string', header: 'Name'},
                    {field: 'supplier', type: 'string', header: 'Supplier'},
                    {field: 'notes', type: 'string', header: 'Reason'},
                    {field: 'state', type: 'string', header: 'State'},
                    {field: 'image_small', header: 'Image', onRender: renderImage},
                    ]}
        />
        <div className="wo_app_buttons wo_app_centered_buttons">
            {
                allowRequestPO ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {openRequestPOReasonDialog()}}
                    >Request PO</Button>
                ):
                (<></>)
            }
        </div>
        </>)}
        </>
    )
}