import { useState } from 'react'
import TableList from '../TableListViewComponent'
import {TableElement} from '../TableListViewComponent'
import Button from '@material-ui/core/Button'
import SelectItemsDialog from './SelectItemsDialog'
import Loading from '../LoadingComponent'
import OdooDatabase from '../../data/odoo'

type One2ManyListProperties = {
    model: string;
    parentField: string;
    parentId: number;
    fields?: string[];
    elements?: TableElement[];
    onDataLoaded?(data: any[]): void;
    onChanged?(data: any[]): void;
    initialData?: any[],
    selectModel?: string;
    selectField?: string;
    selectDomain?: any[];
    itemName?: string;
    pluralItemName?: string;
}

export default function One2ManyList(props: One2ManyListProperties){
    const getSelectedItems = (data: any[]): number[] => {
        if(!props.selectField)
            return []
        let items: number[] = []
        for(let datum of data){
            if(datum[props.selectField])
                items.push(datum[props.selectField][0])
        }
        return items
    }

    const [loading, setLoading] = useState(false)
    const [data, setData] = useState(props.initialData ? props.initialData : [])
    const [useLoadedData, setUseLoadedData] = useState<boolean>(props.initialData ? true : false)
    const [tableKey, setTableKey] = useState(0)
    const [addItemDlgOpen, setAddItemDlgOpen] = useState(false)
    const [selectedItems, setSelectedItems] = useState<number[]>(props.initialData ? getSelectedItems(props.initialData) : [])
    const elements = props.elements ? props.elements : [{'field': 'name', 'header': 'Name'}]
    const fields = props.fields ? props.fields : ['name']

    const updateSelectedItems = (data: any[]) => {
        const items = getSelectedItems(data)
        setSelectedItems(items)
    }

    const onDataLoaded = (data: any[]): void => {
        setData(data)
        updateSelectedItems(data)
        if(props.onDataLoaded)
            props.onDataLoaded(data)
    }

    const removeElements = () => {
        if(!data) return
        let newItems = []
        for(let item of data){
            if(!item._checked)
                newItems.push(item)
        }
        setData(newItems)
        updateSelectedItems(newItems)
        setUseLoadedData(true)
        if(props.onChanged)
            props.onChanged(newItems)
        setTableKey(1 - tableKey)
    }

    const onAddItems = async (items: number[]) => {
        if(!data) return
        if(items.length == 0) return
        if(!props.selectField || !props.selectModel || !props.fields) return
        const odoo = OdooDatabase.getInstance()
        setLoading(true)
        try{
            let newItems = data
            let maxKey = 0
            for(let item of data)
                if(item.id > maxKey)
                    maxKey = item.id
            const dataItems = await odoo.read(props.selectModel, items, ['name'])
            for(let item of dataItems){
                let newItem: any
                newItem = {
                    [props.selectField]: [item.id, item.name],
                }
                for(let field of props.fields){
                    if(field !== props.selectField)
                        newItem[field] = false
                }
                maxKey += 1
                newItem.id = maxKey
                newItem._checked = false
                newItems.push(newItem)
            }
            setData(newItems)
            updateSelectedItems(newItems)
            setUseLoadedData(true)
            if(props.onChanged)
                props.onChanged(newItems)
            setTableKey(1 - tableKey)
            setLoading(false)
        }
        catch(err: any){
            setLoading(false)
        }
    }

    let addText = 'Add '
    if(props.itemName)
        addText += props.itemName
    let removeText = 'Remove '
    if(props.pluralItemName)
        removeText += props.pluralItemName
    return (<>
        <Loading loading={loading}/>
        {
        props.selectModel?(
            <SelectItemsDialog
                open={addItemDlgOpen}
                model={props.selectModel}
                itemName={props.itemName ? props.itemName : ''}
                onOK={onAddItems}
                onClose={() => {setAddItemDlgOpen(false)}}
                fields={['name']}
                elements={[{'field': 'name', 'header': 'Name'}]}
                selectedIds={selectedItems}
                domain={props.selectDomain}
            />
        ):(<></>)          
        }
        <TableList key={`one2manytable_${tableKey}`}
           model={props.model}
           domain={[[props.parentField, '=', props.parentId]]}
           fields={fields}
           elements={elements}
           checkBoxes={true}
           onDataLoaded={onDataLoaded}
           initialData={data}
           showInitialData={useLoadedData}
        />
        <div className="wo_app_buttons wo_app_centered_buttons">
            <Button
                variant="contained"
                color="primary"
                onClick={()=>{setAddItemDlgOpen(true)}}
            >{addText}</Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={removeElements}
            >{removeText}</Button>
        </div>
        </>
    )
} 