import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import * as FaIcons from 'react-icons/fa';
import * as MdIcons from "react-icons/md";
import * as BiIcons from 'react-icons/bi';
import * as HiIcons from 'react-icons/hi';
import * as TiIcons from 'react-icons/ti';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import { Link, BrowserRouter } from 'react-router-dom';


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      [theme.breakpoints.up('md')]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(1),
    },
  }),
);

type NavbarProperties = {
    children?: React.ReactNode;
    onLogout(): void;
    onSearch(): void;
    onWOsClick(): void;
    currentUser: string;
    currentUserId: number;
    token: string;
    showSearchIcon: boolean;
    partsReturnsCount: number;
}

export default function Navbar(props: NavbarProperties) {
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const closeDrawer = () => {
    setMobileOpen(false);
  }

  const onWOsClick = () => {
    closeDrawer();
    props.onWOsClick();
  }

  let imgUrl = '';
  if(process.env.REACT_APP_ODOO_PORT != '80' && process.env.REACT_APP_ODOO_PORT != '443')
    imgUrl = `${process.env.REACT_APP_ODOO_SERVER}:${process.env.REACT_APP_ODOO_PORT}/techuser/img/?uid=${props.currentUserId}&token=${props.token}`;
  else
    imgUrl = `${process.env.REACT_APP_ODOO_SERVER}/techuser/img/?uid=${props.currentUserId}&token=${props.token}`;

  const drawer = (
    <div>
      <div className="navbar-logo">
        <img width="100%" className="background-logo" src={ process.env.REACT_APP_BASE_URL +  'background.png'} alt="Zabatt Logo"/>
        <img className="small-logo" src={ imgUrl } alt="User"/>
      </div>
      <div className="current_user_name">
          <span>{props.currentUser}</span>
      </div>
      <List>
        <ListItem button component={Link} to={"/wos_list"} onClick={onWOsClick}>
            <ListItemIcon><FaIcons.FaCalendarCheck size={25}/></ListItemIcon>
              <ListItemText primary="Work Orders" />
        </ListItem>
        <ListItem button component={Link} to={{ pathname: "https://docs.google.com/document/d/1QperTElq0XTpT8KYT5js_6c30YTA8c3dASxpJ3I0Q9Q/edit" }} target="_blank" >
          <ListItemIcon><HiIcons.HiDocumentText size={25}/></ListItemIcon>
                <ListItemText primary="Extra Docs" />
        </ListItem>
        <ListItem button component={Link} to={{ pathname: "https://docs.google.com/document/d/1QFx47hjHLqBV0yvdbXu8tcRTLTzNp0TAlvM1Nnxr3gU/edit?pli=1#heading=h.ov1607ldaib2" }} target="_blank" >
          <ListItemIcon><FaIcons.FaPooStorm size={25}/></ListItemIcon>
                <ListItemText primary="Storm Info" />
        </ListItem>
        <ListItem button component={Link} to={"/map"} onClick={closeDrawer}>
            <ListItemIcon><FaIcons.FaMapMarkerAlt size={25}/></ListItemIcon>
              <ListItemText primary="Map" />
        </ListItem>
        <ListItem button component={Link} to={"/truck"} onClick={closeDrawer}>
            <ListItemIcon><FaIcons.FaTruck size={25}/></ListItemIcon>
              <ListItemText primary="Truck Inventory" />
        </ListItem>
        <ListItem button component={Link} to={"/return_parts"} onClick={closeDrawer}>
            <ListItemIcon><TiIcons.TiArrowBackOutline size={25}/></ListItemIcon>
              {
                props.partsReturnsCount > 0 ? (
                  (<ListItemText primary={<Typography color="secondary">{`Part Returns (${props.partsReturnsCount})`}</Typography>} />)
                ) : (<ListItemText primary="Part Returns" />)
              }
        </ListItem>
        <ListItem button component={Link} to={{ pathname: "https://docs.google.com/document/d/1JGbzSE9dF9kaWQIHx3LQvqWVOUTm49z8tX7uOXdVOaQ" }} target="_blank">
            <ListItemIcon><MdIcons.MdHelp size={25}/></ListItemIcon>
              <ListItemText primary="Help" />
        </ListItem>
        <ListItem button component={Link} to={"#"} onClick={() => {props.onLogout()}}>
            <ListItemIcon><MdIcons.MdExitToApp size={25}/></ListItemIcon>
              <ListItemText primary="Log out" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <BrowserRouter>
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <FaIcons.FaBars/>
          </IconButton>
          <Typography variant="h6" noWrap>
             TECH APP
          </Typography>
          {
            props.showSearchIcon ?
             (<IconButton
              color="inherit"
              aria-label="search WOs"
              className='nav-search'
              onClick={() => {props.onSearch()}}
            >
              <BiIcons.BiSearchAlt2/>
            </IconButton>) : (<></>)
          }
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden mdUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className="contentRoot">
          <div className={classes.toolbar} />
          {props.children}
        </div>
      </main>
    </div>
    </BrowserRouter>
  );
}
