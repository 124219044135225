import { useEffect, useRef } from 'react'
import OdooDatabase from '../data/odoo';
import {getCookie} from '../data/utils';
import Loading from './LoadingComponent'

type LoginProperties = {
    onLoginSucessFull(userId: number, userName: string, fullname: string, token: string): void;
    onLoginFail(): void;
}

export default function LoginFromCookies(props: LoginProperties){
    const mountedRef = useRef(true);

    const login = async () => {
        try{
            let strUserInfo = getCookie('userInfo');
            if(strUserInfo){
                const userInfo = JSON.parse(strUserInfo);
                await OdooDatabase.connect(userInfo.userName, userInfo.token);
                props.onLoginSucessFull(
                    userInfo.userId,
                    userInfo.userName,
                    userInfo.userFullName,
                    userInfo.token
                )
            }
            else{
                props.onLoginFail();
            }
            
        }catch(err: any){
            console.log('Error reconnecting', err);
            props.onLoginFail();
        }
    }

    useEffect(() => {
        login()
        return () => {
            mountedRef.current = false
        }
    }, [])

    return (<Loading />)
}