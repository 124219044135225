import React from 'react';
import './editors.css';
import {useState} from 'react'
import * as FiIcons from "react-icons/fi";
import LocationEditDialog from './LocationEditDialog'
import Loading from '../LoadingComponent';

type LocationEditorProps = {
    latitude: number;
    longitude: number;
    editable: boolean;
    recordId?: number;
    context?: any;
    recordUpdate?(id: number, latitude: number, longitude: number, context: any): Promise<boolean>,
};

export default function LocationEditor(props: LocationEditorProps) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [latitude, setLatitude] = useState(props.latitude);
    const [longitude, setLongitude] = useState(props.longitude);
    const [updating, setUpdating] = useState(false);

    const openEditor = () => {
        setDialogOpen(true);
    }

    const onOK = async (latitude: number, longitude: number) => {
        let result = true;
        setDialogOpen(false);
        if (props.recordUpdate && props.recordId) {
            setUpdating(true);
            result = await props.recordUpdate(props.recordId, latitude, longitude, props.context);
            setUpdating(false);
        }
        if (result) {
            setLatitude(latitude);
            setLongitude(longitude);
        }
    }

    const onClose = () => {
        setDialogOpen(false);
    }

    const dialog = dialogOpen ?
      (<LocationEditDialog title={'Select Location'} latitude={latitude} longitude={longitude} onOK={onOK} onClose={onClose}/>) : (<></>)

    const location = `${latitude}, ${longitude}`;
    const location_link = `https://maps.google.com/?q=${latitude},${longitude}`;

    if (updating) {
        return (<Loading loading={true} fullWidth={false}/>);
    }

    if (props.editable) {
        return (
            <>{dialog}<div className="text_editable_with_icon">
                <a href={location_link} rel="noreferrer" target="_blank">{location}</a>
                <FiIcons.FiEdit className="edit-icon" onClick={openEditor}/>
            </div></>);
    } else {
        return (<>{dialog}<a href={location_link} rel="noreferrer" target="_blank">{location}</a></>);
    }
}