import React from 'react';
import {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import OdooDatabase from '../../data/odoo';
import Loading from '../LoadingComponent';

type TextEditProperties = {
    value: string;
    type?: string;
    updateId?: number;
    updateModel?: string;
    updateField?: string;
    onError?(errorMsg: string): void;
    editable?: boolean;
    onChange?(newValue: string): void;
    updateOnChange?: boolean;
}

export default function TextEdit(props: TextEditProperties){
    const [editMode, setEditMode] = useState(false)
    const [value, setValue] = useState(props.value)
    const [loading, setLoading] = useState(false)

    const formatData = (value: string) => {
        let printValue = value
        if(props.type == 'date' && value){
            let date = new Date(value)
            let offset = date.getTimezoneOffset() * 60 * 1000
            date.setTime(date.getTime() - offset)
            if(editMode){
                let month = '' + (date.getMonth() + 1)
                let day = '' + (date.getDate())
                if(month.length < 2)
                    month = '0' + month
                if(day.length < 2)
                    day = '0' + day
                printValue = `${date.getFullYear()}-${month}-${day}`
            }
            else
                printValue = date.toLocaleString()
        }
        return printValue
    }

    const formatDate = (date: Date) => {
        let month = '' + (date.getMonth() + 1)
        let day = '' + (date.getDate())
        if(month.length < 2)
            month = '0' + month
        if(day.length < 2)
            day = '0' + day
        let hour = '' + date.getHours()
        if(hour.length < 2)
            hour = '0' + hour
        let mins = '' + date.getMinutes()
        if(mins.length < 2)
            mins = '0' + mins
        let secs = '' + date.getSeconds()
        if(secs.length < 2)
            secs = '0' + secs
        return `${date.getFullYear()}-${month}-${day} ${hour}:${mins}:${secs}`
    }

    const getNewValue = (newValue: string) => {
        if(props.type == 'date'){
            let date = new Date()
            if(value){
                date = new Date(value)
            }
            let offset = date.getTimezoneOffset() * 60 * 1000
            date.setTime(date.getTime() - offset)
            const newDateValues = newValue.split('-')
            let newDate = new Date(
                parseInt(newDateValues[0]),
                parseInt(newDateValues[1]) - 1,
                parseInt(newDateValues[2]),
                date.getHours(), date.getMinutes(),
                date.getSeconds(), 0
            )
            offset = newDate.getTimezoneOffset() * 60 * 1000
            newDate.setTime(newDate.getTime() + offset)
            return formatDate(newDate)
        }
        else
            return newValue
    }

    const update = async (value: string) => {
        if(props.updateModel && props.updateId && props.updateField){
            setLoading(true)
            let odoo = OdooDatabase.getInstance();
            try{
                await odoo.update(props.updateModel, [props.updateId], {[props.updateField]:value});
                setLoading(false)
                setEditMode(false)
                if(props.updateOnChange){
                    setValue(value)
                }
            }catch(err: any){
                console.log('Error', err)
                setLoading(false)
                setEditMode(false)
                setValue(props.value)
                if(props.onError)
                    props.onError(err.faultString)
            }
        }
    }

    const onChange = async (event: any) => {
        console.log('OnChange', event)
        const value = event.target.value
        const newValue = getNewValue(value)
        if(props.updateOnChange){
            await update(newValue);
        }
        else
            setValue(newValue)
        if(props.onChange)
            props.onChange(newValue)
    }

    const onBlur = () => {
        if(!props.updateOnChange)
            update(value)
    }

    if(loading){
        return (<Loading loading={true}/>)
    }
    else if(editMode){
        return (<TextField
            type={props.type ? props.type : 'text'}
            value={value ? formatData(value) : ''}
            InputLabelProps={{
                shrink: true,
            }}
            onChange={onChange}
            onBlur={onBlur}
        />)
    }
    else{
        let text = formatData(value)
        let className = 'text_editable'
        if(!text){
            text = 'Edit'
            className += ' editor_empty'
        }
        return (<span onClick={() => {if(props.editable) setEditMode(true)}} className={className}>{text}</span>)
    }
}