export function mergeDict(target: any, source: any): any{
    let result: any;
    result = {};
    for(const key in target){
        result[key] = target[key];
    }
    for(const key in source){
        result[key] = source[key];
    }
    return result;
}

export function copyDict(source: any){
    let result: any;
    result = {};
    for(const key in source){
        result[key] = source[key];
    }
    return result;
}

export function updateDict(target: any, source: any){
    for(const key in source){
        target[key] = source[key];
    }
}

export function formatDateToLocale(datetime: string): string {
    let date = new Date(datetime);
    let offset = date.getTimezoneOffset() * 60 * 1000
    date.setTime(date.getTime() - offset)
    return  date.toLocaleString('en-US', { hour12: true });
}

export function formatDateTime(date: Date): string{
    let _date = new Date()
    const offset = date.getTimezoneOffset() * 60 * 1000
    _date.setTime(date.getTime() - offset)
    const sday = ("0" + _date.getDate()).slice(-2)
    const smonth = ("0" + (_date.getMonth()+1)).slice(-2)
    let hours = _date.getHours()
    const am_pm = hours >= 12 ? "PM" : "AM"
    if(hours > 12) hours -= 12
    if(hours == 0) hours = 12
    const shours = ("0" + hours).slice(-2)
    const sminutes = ("0" + (_date.getMinutes())).slice(-2)
    const sseconds = ("0" + (_date.getSeconds())).slice(-2)
    return `${smonth}-${sday}-${_date.getFullYear()} ${shours}:${sminutes}:${sseconds} ${am_pm}`
}

export function formatSplitDateTime(date: Date): string[]{
    let _date = new Date()
    const offset = date.getTimezoneOffset() * 60 * 1000
    _date.setTime(date.getTime() - offset)
    const sday = ("0" + _date.getDate()).slice(-2)
    const smonth = ("0" + (_date.getMonth()+1)).slice(-2)
    let hours = _date.getHours()
    const am_pm = hours >= 12 ? "PM" : "AM"
    if(hours > 12) hours -= 12
    if(hours == 0) hours = 12
    const shours = ("0" + hours).slice(-2)
    const sminutes = ("0" + (_date.getMinutes())).slice(-2)
    const sseconds = ("0" + (_date.getSeconds())).slice(-2)
    return [`${smonth}-${sday}-${_date.getFullYear()}`, `${shours}:${sminutes}:${sseconds} ${am_pm}`]
}

export function adjustDateTime(date: Date): string{
    let _date = new Date()
    const offset = date.getTimezoneOffset() * 60 * 1000
    _date.setTime(date.getTime() + offset)
    const sday = ("0" + _date.getDate()).slice(-2)
    const smonth = ("0" + (_date.getMonth()+1)).slice(-2)
    const shours = ("0" + (_date.getHours())).slice(-2)
    const sminutes = ("0" + (_date.getMinutes())).slice(-2)
    const sseconds = ("0" + (_date.getSeconds())).slice(-2)
    return `${_date.getFullYear()}-${smonth}-${sday} ${shours}:${sminutes}:${sseconds}`
}

export function parseStandardDate(date: string): Date{
    const date_parts = date.split('-')
    try{
        const year = parseInt(date_parts[0])
        const month = parseInt(date_parts[1]) - 1
        const day = parseInt(date_parts[2])
        return new Date(year, month, day)
    }
    catch(err: any){
        console.log('Error parsing date', date)
        return new Date()
    }
}

export function day_start(date: string){
    const _date = parseStandardDate(date)
    return new Date(
        _date.getFullYear(),
        _date.getMonth(),
        _date.getDate(),
        0, 0, 0, 0
    )
}
export function day_end(date: string){
    const _date = parseStandardDate(date)
    return new Date(
        _date.getFullYear(),
        _date.getMonth(),
        _date.getDate(),
        23, 59, 59, 0
    )
}

export function formatStandardDate(date: Date): string{
    var sday = ("0" + date.getDate()).slice(-2);
    var smonth = ("0" + (date.getMonth()+1)).slice(-2);
    return date.getFullYear() + "-" + (smonth) + "-" + (sday);
}

export function findById(id: number, list: any){
    for(let i = 0; i < list.length; i++)
        if(list[i].id === id)
            return list[i].name;
    return '';
}

export function findInArray(key: string, list: any){
    for(let i = 0; i < list.length; i++)
        if(list[i][0] === key)
            return list[i][1];
    return '';
}

export function formatHTML(content: string): string{
    if(content){
        content = content.replace(/\n/g, '[n]');
        content = content.replace(/\t/g, '[t]');
        content = content.replace(/</g, '&lt;');
        content = content.replace(/>/g, '&gt;');
        content = content.replace(/\[n\]/g, '<br/>');
        content = content.replace(/\[t\]/g, '&nbsp;&nbsp;&nbsp;');
        return content;
    }else{
        return '';
    }
}

export function formatDate(dateText: string){
	var dateRegexp = /^(\d{4})-(\d{1,2})-(\d{1,2})$/;
	if(!dateText)
		dateText = '';
	var match = dateText.match(dateRegexp);
	if(match)
	{
		var date = new Date(parseInt(match[1]),
			parseInt(match[2]) - 1,
			parseInt(match[3]), 0, 0, 0, 0);
		dateText = '' +
			('0' + (date.getMonth() + 1)).slice(-2) + '-' +
			('0' + (date.getDate())).slice(-2) + '-' +
			date.getFullYear();
	}
	return dateText;
}

export function addUrlApi(url: string){
    const scripts = document.getElementsByTagName('script')
    for(let i = 0; i < scripts.length; i++){
        if(scripts[i].src.indexOf(url) == 0){
            return false
        }
    }
    const newScript = document.createElement('script')
    newScript.src = url
    newScript.async = true
    newScript.defer = true
    window.document.body.appendChild(newScript)
    return newScript
}

export function haversine(lat1: number, long1: number, lat2: number, long2: number) {
    lat1 = lat1 * (Math.PI / 180);
    long1 = long1 * (Math.PI / 180);
    lat2 = lat2 * (Math.PI / 180);
    long2 = long2 * (Math.PI / 180);
    const dlon = long2 - long1;
    const dlat = lat2 - lat1;
    const a = Math.sin(dlat/2)**2 + Math.cos(lat1) * Math.cos(lat2) * Math.sin(dlon/2)**2;
    const c = 2 * Math.asin(Math.sqrt(a));
    const earth_radius = 3963.19;
    return c * earth_radius;
}

/*
 * General utils for managing cookies in Typescript.
 */
export function setCookie(name: string, val: string) {
    const date = new Date();
    const value = val;

    // Set it expire in 7 days
    date.setTime(date.getTime() + (12 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"="+value+";secure;expires="+date.toUTCString()+"; path=/";
}

export function getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts?.length === 2) {
        const last = parts.pop();
        if (last)
            return last.split(";").shift();
    }
}

export function deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
}