import { Component } from 'react';
import TechAppDialog from './TechAppDialog';
import DialogContentText from '@material-ui/core/DialogContentText';
import {formatHTML} from '../data/utils';


type SpecialInstructionsProperties = {
    open: boolean;
    workorder?: any;
    onClose(): void;
}

function SpecialInstructionsDialog(props: SpecialInstructionsProperties){
    let has_description = (props.open && props.workorder) ? props.workorder.has_description : false;
    let parent_descriptions = (<></>);
    if(has_description){
        parent_descriptions = props.workorder.parent_special_instructions.map((s: any) => {
            return (<div key={`spec_${s.id}`}>
                {s.special_instructions ? <>
                <div className="hierarchy_instruction_label">{s.name + ' ' + (s.asset_type === 'E' ? '(Equipment)':'(Site)')}</div>
                <div dangerouslySetInnerHTML={{ __html: formatHTML(s.special_instructions) }} className="hierarchy_instruction"/>
            </> : <></>}
            </div>)
        });
    }
    let tech_name = '';
    if (props.workorder.user_id && props.workorder.user_id.length >= 2)
        tech_name = props.workorder.user_id[1];
    return (<TechAppDialog open={props.open}
        title="Special Instructions"
        closeOnClickOutside={true}
        onClose={props.onClose}
    >
            <DialogContentText>
                <div className='special_instructions_dialog'>
                    {parent_descriptions}
                    {props.workorder.tech_notes ? <>
                        <div key="spec_tech_notes">
                            <div className="hierarchy_instruction_label">{props.workorder.name}</div>
                            <div dangerouslySetInnerHTML={{ __html: formatHTML(props.workorder.tech_notes) }} className="hierarchy_instruction"/>
                        </div>
                    </> : <></>}
                    {props.workorder.description ? <>
                        <div key="spec_assignment">
                            <div className="hierarchy_instruction_label">{tech_name} (Assignment)</div>
                            <div dangerouslySetInnerHTML={{ __html: formatHTML(props.workorder.description) }} className="hierarchy_instruction"/>
                        </div>
                    </> : <></>}
                </div>
            </DialogContentText>
        </TechAppDialog>
    );
}

export default SpecialInstructionsDialog;