import TechAppDialog from '../TechAppDialog';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';
import FormHelperText from '@material-ui/core/FormHelperText';


type ConfirmRequestPODialogProperties = {
    open: boolean;
    onClose(): void;
    onOK(reason: string): void;
}

export default function ConfirmRequestPO(props: ConfirmRequestPODialogProperties){
    const [reason, setReason] = useState('');

    const onChange = (event: any) => {
        setReason(event.target.value)
    };

    const onOK = () => {
        if(reason.trim() !== ""){
            props.onOK(reason);
            setReason('');
        }
    };

    const onClose = () => {
        setReason('');
        props.onClose();
    };

    return (
        <TechAppDialog title="Request PO Reason" open={props.open} onClose={onClose}
            buttons={[
                {text: 'Request PO', onClick:onOK, primary: true},
                {text: 'Close', onClick: onClose}
            ]}
        >
            <>
                <TextField
                    id="standard-multiline-flexible"
                    multiline
                    fullWidth
                    rows={20}
                    value={reason}
                    onChange={onChange}
                    />
                {(reason.trim() === "")?(<FormHelperText>A reason for the Request PO must be specified</FormHelperText>):(<></>)}
            </>
        </TechAppDialog>
    );

}
