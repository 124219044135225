import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TechAppDialog from './TechAppDialog';

type CustomerModalProperties = {
    open: false;
    title: string;
    onClose(): void;
    customer: any;
}

export default function CustomerModalDialog(props: CustomerModalProperties){
    const customer = props.customer;
    return (<TechAppDialog open={props.open}
        title={props.title}
        onClose={props.onClose}
    >
        <Table className="data_table">
            <TableBody> 
                <TableRow>
                    <TableCell>Address</TableCell>
                    <TableCell>{customer.address?customer.address:''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Phone</TableCell>
                    <TableCell>{customer.phone?customer.phone:''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Mobile</TableCell>
                    <TableCell>{customer.mobile?customer.mobile:''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Email</TableCell>
                    <TableCell>{customer.email?customer.email:''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Website</TableCell>
                    <TableCell>{customer.website?customer.website:''}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    </TechAppDialog>);
}