import { useEffect, useState } from 'react';
import Loading from '../../components/LoadingComponent';
import OdooDatabase from '../../data/odoo';
import TableList from '../TableListViewComponent';
import TextField from '@material-ui/core/TextField'
import { InputAdornment } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import * as AiIcons from "react-icons/ai"

type Descriptions = {
    key: string;
    label: string;
}

type SiteHistoryProperties = {
    asset_id: number;
}

export default function SiteHistory(props: SiteHistoryProperties){
    const [siteAssetIds, setSiteAssetIds] = useState<number[]>([])
    const [stateDescriptions, setStateDescriptions] = useState<Descriptions[]>([])
    const [searchText, setSearchText] = useState('');
    const [timeOut, setTimeOut] = useState(0)
    const [tableKey, setTableKey] = useState(0)

    let odoo = OdooDatabase.getInstance();

    async function get_states_descriptions() {

        try {
          let list:Descriptions[] = []
          let odoo = OdooDatabase.getInstance();
          let result = await odoo.execute_kw('fieldservice.workorder','get_wo_state_names',[[]]);
          result.forEach( (element: any) => {
              list.push({key:element[0],label:element[1]})
          });

          setStateDescriptions(list);

        } catch (err: any) {
          console.log('Error', err);
        }

  }

    const getAssetTopParent = async (asset_id: number) => {
        let data = await odoo.read('fieldservice.asset', asset_id, ['parents']);
        if(data[0]['parents'].length > 0){
            return data[0]['parents'][data[0]['parents'].length - 1]
        }
        return 0;
    }

    const getAssetChildren = async (asset_id: number) => {
        let data = await odoo.read('fieldservice.asset', asset_id, ['children']);
        return data[0]['children']
    }

    const getSiteAssets = async () => {
        try {
            const parent_id = await getAssetTopParent(props.asset_id);
            const children = await getAssetChildren(parent_id);
            setSiteAssetIds(children);
          } catch (err: any) {
            console.log('Error', err);
          }
    }

    const onSearchTextChange = (event: any) => {
        setSearchText(event.target.value);
    }

    useEffect(() => {
        getSiteAssets();
        get_states_descriptions();
     }, [])

     useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setTableKey(1 - tableKey)
            setTimeOut(500)
        }, timeOut)

        return () => clearTimeout(delayDebounceFn)
      }, [searchText])

     return (

        <>
            {
                siteAssetIds.length === 0 || stateDescriptions.length === 0 ? (
                    <Loading loading={true} fullWidth={false}/>
                ) : (
                    <>
                        <div className="search_box">
                            <TextField value={searchText} onChange={onSearchTextChange}
                                variant="filled" autoComplete="off" label="Search by Asset"
                                InputProps={{
                                    endAdornment: searchText != '' ? (<InputAdornment position="end"><IconButton onClick={()=>{setSearchText('')}}><AiIcons.AiFillCloseCircle/></IconButton></InputAdornment>) : (<></>)
                                }}
                            />
                        </div>
                        <TableList
                            key={tableKey}
                            model="fieldservice.workorder"
                            fields={['name', 'asset_id', 'type_as_char', 'date_completed', 'description', 'due_date','state']}
                            domain={[['asset_id', 'in', siteAssetIds], ['asset_id.name', 'ilike', searchText],
                                        '|',
                                        '&', ['quote_wo_type', '=', 'wo'],'!',['state','in',['draft','cancel']],
                                        ['quote_wo_type', '=', 'quote']
                                    ]
                                }
                            elements={
                                [
                                {field: 'name', header: 'WO', link: '/wo/__id__'},
                                {field: 'asset_id', header: 'Asset', type: 'many2one'},
                                {field: 'type_as_char', header: 'Type'},
                                {field: 'due_date', header: 'Target Date', type: 'onlydate'},
                                {field: 'date_completed', header: 'Done', type: 'onlydate'},
                                {field: 'state', type: 'select', 'header': 'Status', 'select_items': stateDescriptions}
                                ]
                            }
                            pagination={true}
                            pageSize={5}
                            order="asset_id, quote_wo_type, date_completed desc, id desc"
                        />
                    </>
                )
            }
        </>

    )
}