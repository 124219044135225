import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';    
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import * as AiIcons from "react-icons/ai";
import * as BiIcons from "react-icons/bi";
import ZoomTransition from './DialogTransition';

type DialogButton = {
    text: string;
    primary?: boolean;
    onClick(): void;
}

type TechAppDialogProperties = {
    open: boolean;
    closeOnClickOutside?: boolean;
    onClose(): void;
    title: string;
    children?: JSX.Element;
    buttons?: DialogButton[];
    icon?: string;
    fullWidth?: boolean;
}


export default function TechAppDialog (props: TechAppDialogProperties){
    let i = 0;
    const buttons = props.buttons ? (props.buttons.map((button) => {
        const color = button.primary ? 'primary' : 'inherit';
        return (
            <Button key={`button_${i++}`} autoFocus onClick={button.onClick} variant="contained" color={color}>{button.text}</Button>
        );
    })) : false;
    return (<Dialog open={props.open} className="app_modal_dialog"
        aria-labelledby={props.title}
        aria-describedby={props.title}
        onBackdropClick={() => {if(props.closeOnClickOutside) props.onClose()}}
        TransitionComponent={ZoomTransition}
        fullWidth={props.fullWidth}
    >
        <DialogTitle id="form-dialog-title">
            <Typography>
                {props.icon==='search'?(<BiIcons.BiSearchAlt2/>):(<></>)}
                {props.title}</Typography>
            <IconButton aria-label="close" onClick={() => props.onClose()}>
                <AiIcons.AiOutlineCloseCircle />
            </IconButton>
        </DialogTitle>
        <DialogContent>
            {props.children}
        </DialogContent>
        {
            props.buttons ? (
                <DialogActions>
                    <div className="wo_app_buttons">
                    {buttons}
                    </div>
                </DialogActions>
            ) : (<></>)
        }
    </Dialog>);
}
