import React from 'react';
import TableList from '../TableListViewComponent'

type SiteAssetProperties = {
    workorder_id: number;
}

export default function SiteAsset(props: SiteAssetProperties){

    return (
        <>
            <TableList
                model="fieldservice.workorder"
                loadMethod="get_site_assets"
                loadParameters={[[props.workorder_id]]}
                elements={[
                    {field:'sn', type: 'string', header: 'Serial Number'},
                    {field:'complete_name', type: 'string', header: 'Name'},
                    {field:'category', type: 'string', header: 'Category'}
                ]}
            />
        </>
    )
}