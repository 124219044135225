import React from 'react'
import {useState, useEffect} from 'react'
import Button from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import ActionRequiredDialog from './ActionRequiredDialog'
import ReassignDialog from './ReassignDialog'
import Loading from '../LoadingComponent'
import OdooDatabase from '../../data/odoo';

type ActionsRequiredProperties = {
    workorder_id: number;
    wo_state: string;
    asset_id: number;
    category_id: number;
    onError?(errorMsg: string): void;
} 

//completed_onsite_wo 
export default function ActionsRequired(props: ActionsRequiredProperties){
    const [actionDialogOpen, setActionDialogOpen] = useState(false)
    const [reassignDialogOpen, setReassignDialogOpen] = useState(false)
    const [actionRequiredDlgKey, setActionRequiredDlgKey] = useState(0)
    const [followupWoId, setFollowupWoId] = useState(0)
    const [followupWoName, setFollowupWoName] = useState('')
    const [parentWO, setParentWO] = useState(0)
    const [parentWOName, setParentWOName] = useState('')
    const [completedOnSiteWoId, setCompletedOnSiteWoId] = useState(0)
    const [completedOnSiteWoName, setCompletedOnSiteWoName] = useState('')
    const [blockFollowUps, setBlockFollowUps] = useState(false)
    const [loading, setLoading] = useState(false)
    const [reassigned, setReassigned] = useState(false)
    const [assets, setAssets] = useState([])
    
    const loadData = async () => {
        setLoading(true)
        let odoo = OdooDatabase.getInstance();
        try{
            setFollowupWoId(0)
            setCompletedOnSiteWoId(0)
            setFollowupWoName('')
            setCompletedOnSiteWoName('')
            const data = await odoo.read('fieldservice.workorder', props.workorder_id, ['parent_wo', 'follow_up_wo', 'completed_onsite_wo', 'block_follow_up']);
            if(data.length > 0){
                if(data[0].follow_up_wo){
                    setFollowupWoId(data[0].follow_up_wo[0])
                    setFollowupWoName(data[0].follow_up_wo[1])
                }
                if(data[0].completed_onsite_wo){
                    setCompletedOnSiteWoId(data[0].completed_onsite_wo[0])
                    setCompletedOnSiteWoName(data[0].completed_onsite_wo[1])
                }
                if(data[0].parent_wo){
                    setParentWO(data[0].parent_wo[0])
                    setParentWOName(data[0].parent_wo[1])
                }
                setBlockFollowUps(data[0].block_follow_up)
            }
            const site_assets = await odoo.execute_kw('fieldservice.workorder', 'get_site_assets', [[props.workorder_id]])
            console.log('Assets loaded', data)
            setAssets(site_assets)
            setLoading(false)
        }catch(err: any){
            console.log('Error', err);
            setLoading(false)
            if(props.onError)
                props.onError(err.faultString);
            return
        }
    }

    useEffect(() => {
        loadData()
    }, [])

    const onOK = () => {
        setActionDialogOpen(false)
        loadData()
    }

    const onReassignOK = () => {
        setReassigned(true)
        setReassignDialogOpen(false)
    }

    if(loading){
        return <Loading fullWidth={false}/>
    }

    return (<>
        <ActionRequiredDialog key={`action_required_dlg_${actionRequiredDlgKey}`}
            open={actionDialogOpen} 
            asset_id={props.asset_id}
            assets={assets}
            category_id={props.category_id}
            workorder_id={props.workorder_id} 
            onClose={() => {setActionDialogOpen(false)}} 
            onOK={onOK}
            onError={props.onError}/>
        <ReassignDialog 
            open={reassignDialogOpen} 
            workorder_id={props.workorder_id} 
            onClose={() => {setReassignDialogOpen(false)}} 
            onOK={onReassignOK}
            onError={props.onError}/>
        <div className="wo_follow_ups align_left">
        {
            (parentWO)?
            (<div><span><b>Parent WO: </b></span><Link to={`/wo/${parentWO}`}>{parentWOName}</Link></div>):
            (<></>)   
            }
            {
            (followupWoId)?
            (<div><span><b>Quote Request: </b></span><Link to={`/wo/${followupWoId}`}>{followupWoName}</Link></div>):
            (<></>)   
            }
            {
            (completedOnSiteWoId)?
            (<div><span><b>Completed On-site WO: </b></span><Link to={`/wo/${completedOnSiteWoId}`}>{completedOnSiteWoName}</Link></div>):
            (<></>)   
            }
        </div>
        <div className="wo_app_buttons">
        {blockFollowUps?(<span className="warning_text">Follow-ups blocked on this WO</span>):(<></>)}
        {((!completedOnSiteWoId || !followupWoId) && props.wo_state != 'cancel' && !blockFollowUps)?(<Button
                variant="contained"
                color="primary"
                onClick={() => {setActionRequiredDlgKey(1 - actionRequiredDlgKey); setActionDialogOpen(true)}}
        >Select Action Required</Button>):(<></>)}
        {
        (props.wo_state == 'assigned' && !reassigned) ? 
        (<Button
                variant="contained"
                color="primary"
                onClick={() => {setReassignDialogOpen(true)}}
        >Reassign Current WO</Button>) : (<></>)
        }
    </div></>)
}