import Zoom from '@material-ui/core/Zoom';
import { TransitionProps } from '@material-ui/core/transitions';
import React from 'react';

const ZoomTransition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) {
    return <Zoom in={true} ref={ref} {...props} />;
  });

  export default ZoomTransition;