import { useEffect, useState, useRef } from 'react'
import TechApp from './TechAppDialog'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import Loading from './LoadingComponent'
import OdooDatabase from '../data/odoo'
import SelectItems from './editors/SelectItemsComponent'
import One2ManyList from './editors/One2ManyListComponent'
import TextEdit from './editors/TextEditComponent'


type TaskNoteProperties = {
    task: any;
    open: boolean;
    onClose(): void;
    onError(msg: string): void;
}

export default function TaskNote(props: TaskNoteProperties){
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [notes, setNotes] = useState(props.task.description ? props.task.description : '')
    const [problems, setProblems] = useState<number[]>(props.task.problems ? props.task.problems : [])
    const [problemsPool, setProblemsPool] = useState<number[]>([])
    const [solutions, setSolutions] = useState<any[]>(props.task.solutions ? props.task.solutions : [])
    const [solutionsPool, setSolutionsPool] = useState<any[]>([])
    const [loadingSolutionsDomain, setLoadingSolutionsDomain] = useState(false)
    const mountedRef = useRef(true)

    const loadData = async () => {
        if(props.task.failure){
            if(props.task.dataLoaded){
                setNotes(props.task.description)
                setProblems(props.task.problems)
                setSolutions(props.task.solutions)
                setProblemsPool(props.task.problemsPool)
                setSolutionsPool(props.task.solutionsPool)
                return
            }
            const odoo = OdooDatabase.getInstance()
            setLoading(true)
            setError(false)
            try{
                const data = await odoo.read(
                    'fieldservice.wo.task.failure',
                    [props.task.failure_desc[0]],
                    ['description', 'problems', 'wo_solution_tasks', 'task_problems_pool', 'task_solutions_pool'])
                if (!mountedRef.current) return
                if(!props.task.problems)
                    setProblems(data[0].problems)
                setProblemsPool(data[0].task_problems_pool)
                setSolutionsPool(data[0].task_solutions_pool)
                setLoading(false)
            }catch(err: any){
                console.log('Error', err)
                setLoading(false)
                setError(true)
            }
        }
    }

    const onSave = () => {
        props.task.description = notes
        props.task.problems = problems
        props.task.solutions = solutions
        props.task.problemsPool = problemsPool
        props.task.solutionsPool = solutionsPool
        props.task.dataLoaded = true
        props.task.modified = true
        props.onClose()
    }

    useEffect(() => {
        loadData()
        return () => {
            mountedRef.current = false
        }
    }, [])

    const onNotesChanged = (event: any) => {
        const text = event.target.value
        setNotes(text)
        
    }

    const onProblemsChange = async (newIds: number[]) => {
        setProblems(newIds)
        const odoo = OdooDatabase.getInstance()
        try{
            setLoadingSolutionsDomain(true)
            let newSols: number[] = []
            const problems = await odoo.read('fieldservice.problem', newIds, ['all_tree_solutions'])
            for(let problem of problems){
                newSols = newSols.concat(problem.all_tree_solutions)
            }
            setSolutionsPool(newSols)
            setLoadingSolutionsDomain(false)
        }
        catch(err: any){
            console.log('Error', err)
            setLoadingSolutionsDomain(false)
        }
    }

    const onRenderSolEstTime = (field: string, data: any): JSX.Element => {
        return (<TextEdit value={data[field]} editable={true} onChange={(newValue: string) => {data[field] = newValue}}/>)
    }

    return (<TechApp 
        open={props.open}
        title=""
        onClose={props.onClose}
        buttons={[{text: 'OK', primary: true, onClick: onSave}, {text: 'Close', onClick: props.onClose}]}
    >
        <DialogContentText className="scroll-content">
        <div className="task_notes_title">{props.task.wotask_id[1]}</div>
        {
            error ? (<span>Error loading</span>) :
            loading ? (<Loading loading={true} fullWidth={false}/>):
            (<><TextField
                label=""
                multiline
                fullWidth
                rows={4}
                variant="outlined"
                value={notes}
                onChange={onNotesChanged}
              />
              {
              props.task.failure?
              (
              <div className="task_problems_and_solutions">
                  <div className="task_ps_box">
                  <div className="task_ps_title">Problems</div>
                  <SelectItems
                      model="fieldservice.problem"
                      fields={['name']}
                      itemName="Problem"
                      pluralItemName="Problems"
                      selectedItems={problems}
                      onChange={onProblemsChange}
                      elements={[{field: 'name', type: 'text', header: 'Procedure'}]}
                      hideTableHead={true}
                      domain={[['id', 'in', problemsPool]]}
                  />
                  </div>
                  <div className="task_ps_box">
                  <div className="task_ps_title">Solutions</div>
                  {
                  loadingSolutionsDomain?
                  (<Loading loading={true} fullWidth={false}/>):
                  (<One2ManyList
                    model="fieldservice.wo.task.solution"
                    parentField="wo_task_failure_id"
                    parentId={props.task.failure_desc[0]}
                    fields={['name', 'tech_est_time']}
                    elements={[{'field': 'name', header:'Name', 'type': 'many2one'}, {'field': 'tech_est_time', 'header': 'Estimated Time', onRender: onRenderSolEstTime}]}
                    onDataLoaded={(data: any[]) => {setSolutions(data)}}
                    onChanged={(data: any[]) => {setSolutions(data)}}
                    initialData={props.task.solutions ? props.task.solutions : false}
                    itemName="Solution"
                    pluralItemName="Solutions"
                    selectModel="fieldservice.task"
                    selectField="name"
                    selectDomain={[['id', 'in', solutionsPool]]}
                  />)
                  }
                  </div>
              </div>
              ):
              (<></>)
                }
              </>
              
            )
        }
        </DialogContentText>
    </TechApp>)
}