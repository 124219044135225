import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import * as AiIcons from "react-icons/ai";
import IconButton from '@material-ui/core/IconButton';
import TechAppDialog from './TechAppDialog';
import { IconContext } from "react-icons";

type NotificationProperties = {
    msg: string;
    error: boolean;
    useDialog?: boolean;
    duration?: number;
    onClose(): void;
    onTop?: boolean;
    permanent?: boolean;
    hideClose?: boolean;
    extraButton?: boolean;
    extraButtonText?: string;
}

export default function Notification(props: NotificationProperties){
    let className = props.error ? "snackbar_error" : "";
    if(props.useDialog){
        return (
        <TechAppDialog open={true}
            title={props.error ? "Error" : "Notification"}
            onClose={props.onClose}
            buttons={[{text: 'Close', onClick: props.onClose, primary: false}]}
        >
            <div>{props.msg}</div>
        </TechAppDialog>
        )
    }
    else{
        return (
        <Snackbar
                className={className}
                anchorOrigin={props.onTop?
                    {
                        vertical: 'top',
                        horizontal: 'center',
                    }:
                    {
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                open={props.msg !== ''}
                autoHideDuration={props.permanent ? null : (props.duration || 600)}
                message={props.msg}
                onClose={(event?: React.SyntheticEvent, reason?: string) => {
                    if(reason != 'clickaway')
                        props.onClose()
                }}
                action={
                    <React.Fragment>
                        <IconContext.Provider
                            value={{ color: 'white' }}
                            >
                        <IconButton aria-label="close" onClick={props.onClose}>
                            <AiIcons.AiOutlineCloseCircle />
                        </IconButton>
                        </IconContext.Provider>
                    </React.Fragment>
                }
            />
        )
    }
}