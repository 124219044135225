import TechAppDialog from '../TechAppDialog'
import {useState} from 'react'

type UploadDialogProperties = {
    open: boolean;
    onClose(): void;
    title?: string;
    extensions: string;
    onFilesOpen?(files: UploadedFile[]): void;
    onUploadClick?(): void;
    multiple?: boolean;
}

export type UploadedFile = {
    name: string;
    data: string;
}

export default function UploadDialog(props: UploadDialogProperties){
    let uploadLimit = 50  * 1024 * 1024;
    if(process.env.REACT_APP_MAX_UPLOAD){
        uploadLimit = parseInt(process.env.REACT_APP_MAX_UPLOAD) * 1024 * 1024;
    }


    const [selectedFiles, setSelectedFiles] = useState<any>(null)

    const uploadClick = () => {

        if (selectedFiles === null) {
            alert('You must add at least one image')
                return
        }

        let promises = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            if(selectedFiles[i].size > uploadLimit){
                alert(`File must be smaller. Max size is ${process.env.REACT_APP_MAX_UPLOAD} MB`)
                return
            }
            const promise = new Promise((resolve, reject) => {
                let reader = new FileReader()
                reader.onload = (event: any) => {
                    let data = event.target.result
                    const file = {name: selectedFiles[i].name, data: data} as UploadedFile;
                    resolve(file);
                }
                reader.onerror = () => {
                    reject();
                }
                reader.onabort = () => {
                    reject();
                }
                reader.readAsDataURL(selectedFiles[i])
            })
            promises.push(promise);
        }
        Promise.all(promises).then((files) => {
            if (props.onFilesOpen) {
                props.onFilesOpen(files as UploadedFile[]);
            }
        });

    }

    return (
        <TechAppDialog
            open={props.open}
            title={props.title ? props.title : 'Upload File'}
            onClose={props.onClose}
            buttons={[{text: 'Upload', onClick: uploadClick, primary: true}]}
        >
            <input type="file" id="tech_app_file_upload" onChange={(event: any) => {setSelectedFiles(event.target.files)}} accept={props.extensions} multiple={props.multiple}/>
        </TechAppDialog>
    )
}