import { useState } from 'react'
import TableList from '../TableListViewComponent'
import { saveAs } from 'file-saver'
import OdooDatabase from '../../data/odoo'
import Button from '@material-ui/core/Button'
import UploadDialog from './UploadDlg'
import Loading from '../LoadingComponent'
import TextEdit from '../editors/TextEditComponent'
import { UploadedFile } from './UploadDlg'
import TechAppDialog from '../TechAppDialog'
import WOImage from '../WOImageComponent'

type ImagesProperties = {
    workorder_id?: number;
    po_id?: number;
    onError?(msg: string): void;
    onChange?(): void;
    hideUpload?: boolean;
    onFileSave?(data: any): void;
    hideDescription?: boolean;
}

export default function Images(props: ImagesProperties){
    const [uploadDlgOpen, setUploadDlgOpen] = useState(false)
    const [updating, setUpdating] = useState(false)
    const [attachmentsKey, setAttachmentsKey] = useState(0)
    const [data, setData] = useState<any[]>([])
    const [imageWindowOpen, setImageWindowOpen] = useState(false);
    const [currentImageId, setCurrentImageId] = useState('');

    const openImage = (image_id: string) => {
        setCurrentImageId(image_id);
        setImageWindowOpen(true);
    }

    const renderImage = (field: string, datum: any) => {
        if(datum[field]){
            return (<span className="tech_app_link" onClick={(event: any) => {openImage(datum.id); event.preventDefault()}}><img alt="" src={`data:image/png;base64,${datum[field]}`}/></span>)
        }else{
            return (<></>)
        }
    }

    const isJPGOrPNG = (data: string) => {
        let byteString = atob(data.split(',')[1])
	    return (byteString.length > 2 && byteString.charCodeAt(0) ==0xFF && byteString.charCodeAt(1) == 0xD8) ||
            (byteString.length > 8 &&
             byteString.charCodeAt(0) == 0x89 && byteString.charCodeAt(1) == 0x50 &&
             byteString.charCodeAt(2) == 0x4E && byteString.charCodeAt(3) == 0x47 &&
             byteString.charCodeAt(4) == 0x0D && byteString.charCodeAt(5) == 0x0A &&
             byteString.charCodeAt(6) == 0x1A && byteString.charCodeAt(7) == 0x0A
            )
    }


    const onDataLoaded = (data: any) => {
        setData(data)
    }

    const deleteFile = async () => {
        let selectedItems: number[] = []
        for(let item of data){
            if(item._checked)
                selectedItems.push(item.id)
        }
        if(selectedItems.length > 0 && window.confirm('Are you sure you want to delete the selected images?')){
            try{
                setUpdating(true)
                const odoo = OdooDatabase.getInstance()
                await odoo.execute_kw('fieldservice.image', 'unlink', [[selectedItems]])
                setUpdating(false)
                setAttachmentsKey(1 - attachmentsKey)
                if(props.onChange)
                    props.onChange();
            }
            catch(err: any){
                console.log('Error', err)
                if(props.onError)
                    props.onError(err.faultString)
            }
        }
    }

    const onFilesOpen = async (files: UploadedFile[]) => {
        setUploadDlgOpen(false);
        setUpdating(true)
        for(let i = 0; i < files.length; i++) {
            const dataUri = files[i].data;
            if(isJPGOrPNG(dataUri)){

                try{
                    const data = dataUri.split(',')[1]
                    if(props.onFileSave){
                        await props.onFileSave(data);
                    } else {
                        const odoo = OdooDatabase.getInstance()
                        await odoo.create('fieldservice.image', {
                            image: data,
                            wo_id: props.workorder_id,
                        })
                    }
                    if(props.onChange)
                        props.onChange();
                }
                catch(err: any){
                    console.log('Error', err.faultString)
                    if(props.onError)
                        props.onError(err.faultString)
                }
            }
            else if(props.onError)
                props.onError('Only jpg or png files are allowed')
        }
        setAttachmentsKey(1 - attachmentsKey)
        setUpdating(false)
        console.log('updated to db');
    }

    const renderDescription = (field: string, datum: any) => {
        return (
            <TextEdit value={datum[field]} updateId={datum.id}
                updateField="description" updateModel="fieldservice.image"
                editable={true}
                onError={props.onError}
            />
        )
    }

    const domain = props.workorder_id ? ['wo_id','=', props.workorder_id] : props.po_id ? ['po_id','=', props.po_id] : []



    return (
        <>
        {
            uploadDlgOpen ? (<UploadDialog open={true} title="Upload image" onClose={()=>{setUploadDlgOpen(false)}} extensions=".jpg,.png" onFilesOpen={onFilesOpen} multiple={true}/>) : (<></>)
        }
        {
            imageWindowOpen ? (<TechAppDialog open={true} title="" onClose={() => {setImageWindowOpen(false)}}><WOImage image_id={currentImageId}></WOImage></TechAppDialog>) : (<></>)
        }
        {updating ? (<Loading fullWidth={false} loading={true}/>) :
        (<TableList key={`attachments_list_${attachmentsKey}`}
            model="fieldservice.image"
            fields={['image_small', 'description', 'create_date']}
            elements={
                [
                    {field: 'image_small', header: 'Image', onRender: renderImage},
                    {field: 'description', header: 'Description', onRender: renderDescription, hidden: props.hideDescription},
                    {field: 'create_date', header:'Date', type: 'date'}
                ]
            }
            domain={[domain]}
            pagination={true}
            pageSize={5}
            checkBoxes={true}
            onDataLoaded={onDataLoaded}
            order="create_date desc"
        />)}
        <div className="wo_app_buttons wo_app_centered_buttons">
            {props.hideUpload ? (<></>):(<Button
                    variant="contained"
                    color="primary"
                    onClick={()=>{setUploadDlgOpen(true)}}
            >Upload</Button>)}
            <Button
                variant="contained"
                onClick={deleteFile}
            >Delete
            </Button>
        </div>
        </>
    )
}