import {formatStandardDate} from './utils';

type WOFilter = {
    myAssignments: boolean;
    date_filter: string;
    date_start: string;
    date_end: string;
    wo_type: string;
    wo_state: string;
    all_parts_ready: boolean;
    search_by: string;
    search_name: string;
    my_open_labor: boolean;
    my_open_travel: boolean;
    only_wos: boolean;
};

export default WOFilter;

export function initWOFilter() : WOFilter{
    let prevMonth = new Date();
    let nextMonth = new Date();
    prevMonth.setMonth(prevMonth.getMonth() - 1);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    return {
        myAssignments: true,
        date_filter: 'custom',
        date_start: formatStandardDate(prevMonth),
        date_end: formatStandardDate(nextMonth),
        wo_type: '0',
        wo_state: 'assigned',
        all_parts_ready: false,
        search_by: 'asset',
        search_name: '',
        my_open_labor: false,
        my_open_travel: false,
        only_wos: false,
    };
}